import React from 'react'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { showSignature } from '../shared/SharedFunctions'
export default class TopSheet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    errorClass2(value) {
        return (value === "" || value === undefined ? '' : 'has-error')
    }

    render() {
        return (
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-lg-10">
                        <h3 className='pgHeader'>Top Sheet</h3>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-lg-12 text-center">
                        ***Some information may be excluded based on glass***
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="offset-lg-9 col-lg-3">
                        <label>Upload to GP</label>
                        <div className="input-group">
                            <div className="form-control gpField" disabled={this.props.disabledFields.gpField}>
                                {showSignature(this.props.topSheet.gpSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.gpSignatureError)}`} disabled={this.props.disabledFields.preprodField} onClick={() => this.props.sign('topSheet', 'gpSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-lg-4">
                        <label>Verify Batch Paperwork</label>
                        <div className="input-group">
                            <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                {showSignature(this.props.topSheet.verifyBatchPaperworkSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.verifyBatchPaperworkSignatureError)}`} disabled={this.props.disabledFields.preprodField} onClick={() => this.props.sign('topSheet', 'verifyBatchPaperworkSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`form-group col-lg-4 ${this.errorClass2(this.props.topSheet.lotNumberError)}`}>
                        <label htmlFor="lotNumber">Lot #</label>
                        <input type="text" className="form-control productionField" name="lotNumber" id="lotNumber" value={this.props.lotNumber} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, null, null, null, null)} />
                        <div className="text-danger">{this.props.topSheet.lotNumberDuplicate}</div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className={`col-lg-3 ${this.errorClass2(this.props.topSheet.thermalTestingAcceptError)}`}>
                        Thermal Expansion Testing
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="thermalTestingAccept" id="thermalTestingAccept" value="N/A" checked={this.props.topSheet.thermalTestingAccept === "N/A" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                N/A</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="thermalTestingAccept" id="thermalTestingAccept" value="Accepted" checked={this.props.topSheet.thermalTestingAccept === "Accepted" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                Accepted</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="thermalTestingAccept" id="thermalTestingAccept" value="Rejected" checked={this.props.topSheet.thermalTestingAccept === "Rejected" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                Rejected</label>
                        </div>

                    </div>
                    <div className={`col-lg-3`}>
                        <div className="input-group">
                            <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                {showSignature(this.props.topSheet.thermalTestingSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.thermalTestingSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign('topSheet', 'thermalTestingSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className={`col-lg-3 ${this.errorClass2(this.props.topSheet.indexRefractionAcceptError)}`}>
                        Index of Refraction
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="indexRefractionAccept" id="indexRefractionAccept" value="N/A" checked={this.props.topSheet.indexRefractionAccept === "N/A" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                N/A</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="indexRefractionAccept" id="indexRefractionAccept" value="Accepted" checked={this.props.topSheet.indexRefractionAccept === "Accepted" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                Accepted</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="indexRefractionAccept" id="indexRefractionAccept" value="Rejected" checked={this.props.topSheet.indexRefractionAccept === "Rejected" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                Rejected</label>
                        </div>
                    </div>
                    <div className={`col-lg-3`}>
                        <div className="input-group">
                            <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                {showSignature(this.props.topSheet.indexRefractionSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.indexRefractionSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign('topSheet', 'indexRefractionSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                        <div className='text-danger'>{this.props.topSheet.indexRefractionSignatureErr}</div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className={`col-lg-3 ${this.errorClass2(this.props.topSheet.fiberSofteningAcceptError)}`}>
                        Fiber Softening Point
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="fiberSofteningAccept" id="fiberSofteningAccept" value="N/A" checked={this.props.topSheet.fiberSofteningAccept === "N/A" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                N/A</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="fiberSofteningAccept" id="fiberSofteningAccept" value="Accepted" checked={this.props.topSheet.fiberSofteningAccept === "Accepted" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                Accepted</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-check">
                            <label className="form-check-label ml-2">
                                <input type="radio" className="form-check-input" name="fiberSofteningAccept" id="fiberSofteningAccept" value="Rejected" checked={this.props.topSheet.fiberSofteningAccept === "Rejected" ? true : false} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                Rejected</label>
                        </div>
                    </div>
                    <div className={`col-lg-3`}>
                        <div className="input-group">
                            <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                {showSignature(this.props.topSheet.fiberSofteningSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.fiberSofteningSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign('topSheet', 'fiberSofteningSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                        <div className='text-danger'>{this.props.topSheet.fiberSofteningSignatureErr}</div>
                    </div>
                </div>
                <div className="row">
                    <div className={`col-lg-9 ${this.errorClass2(this.props.topSheet.fiberSofteningNotesError)}`}>
                        <label htmlFor="fiberSofteningNotes">Notes</label>
                        <textarea className="form-control qaField" name="fiberSofteningNotes" id="fiberSofteningNotes" value={this.props.topSheet.fiberSofteningNotes} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')}></textarea>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">Retention Sample Taken</div>
                    <div className={`col-3`}>
                        <div className="input-group">
                            <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                {showSignature(this.props.topSheet.retentionSampleTakenSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.retentionSampleTakenSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign('topSheet', 'retentionSampleTakenSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                        <div className='text-danger'>{this.props.topSheet.fiberSofteningAcceptErr}</div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col  ${this.errorClass2(this.props.topSheet.visualCheckError)}`}>
                        <label htmlFor="visualCheck">Visual Check</label>
                        <input type="text" className="form-control sortingField" name="visualCheck" id="visualCheck" value={this.props.topSheet.visualCheck} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                    </div>
                    <div className={`col-lg-3 bottomAlign`}>
                        <div className="input-group">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.visualCheckSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.visualCheckSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'visualCheckSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col">
                        Frit Weight Before Sorting (when applicable)
                    </div>
                    <div className={`form-group col-lg-4 ${this.errorClass2(this.props.topSheet.fritWeightError)}`}>
                        <input type="text" className="form-control sortingField" name="fritWeight" id="fritWeight" value={this.props.topSheet.fritWeight} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                    </div>

                    <div className={`col-3`}>
                        <div className="input-group">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.fritWeightBeforeSortingSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.fritWeightBeforeSortingSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'fritWeightBeforeSortingSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                        <div className='text-danger'>{this.props.topSheet.fritWeightBeforeSortingSignatureErr}</div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        Sorting (WIPR-029)
                    </div>
                    <div className={`col-3`}>
                        <div className="input-group">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.sortingWipr029Signature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.sortingWipr029SignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'sortingWipr029Signature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                        <div className='text-danger'>{this.props.topSheet.sortingWipr029SignatureErr}</div>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col">
                        {
                            this.props.topSheet.fritWeights.map((row, index) => {
                                return (
                                    <div key={index} className="row mt-2">
                                        <div className={`col-lg-3 ${this.errorClass2(row.fritWeightError)}`}>
                                            {
                                                index === 0 &&
                                                <label htmlFor="fritWeight">Frit Weight</label>
                                            }
                                            <input type="number" className="form-control sortingField" name="fritWeight" id="fritWeight" value={row.fritWeight} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'fritWeights', index)} />
                                        </div>
                                        <div className={`col-lg-3 bottomAlign`}>
                                            <div className="input-group">
                                                <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                                    {showSignature(row.fritWeightSignature)}
                                                </div>
                                                <div className="input-group-append">
                                                    <button className={`input-group-text ${this.errorClass2(row.fritWeightSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={(e) => this.props.signArray(e, 'topSheet', 'fritWeights', 'fritWeightSignature', index)}>
                                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-3 ${this.errorClass2(row.verifyWeightError)}`}>
                                            {
                                                index === 0 &&
                                                <label htmlFor="verifyWeight">Verify Weight</label>
                                            }
                                            <input type="number" className="form-control sortingField" name="verifyWeight" id="verifyWeight" value={row.verifyWeight} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'fritWeights', index)} />
                                        </div>
                                        <div className={`col-3 bottomAlign`}>
                                            <div className="input-group">
                                                <div className="form-control sortingField" disabled={this.props.disabledFields.qaField}>
                                                    {showSignature(row.verifyWeightSignature)}
                                                </div>
                                                <div className="input-group-append">
                                                    <button className={`input-group-text ${this.errorClass2(row.verifyWeightSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={(e) => this.props.signArray(e, 'topSheet', 'fritWeights', 'verifyWeightSignature', index)}>
                                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 bottomAlign">
                                            {
                                                index > 0 &&
                                                <button className="btn btn-sm float-right text-secondary" onClick={() => this.props.deleteRow('topSheet', 'fritWeights', index)}>{AccessibleIcon("fa-solid fa-trash-can", "Delete Frit Weight Item")}</button>
                                            }
                                        </div>
                                    </div>

                                )
                            })
                        }
                        {
                            !this.props.disabledFields.sortingField &&
                            <button className="btn btn-sm btn-clear mt-2" onClick={() => this.props.addRow('topSheet', 'fritWeights')}>
                                <i className="fas fa-plus-circle"></i> Add Row
                            </button>
                        }

                    </div>
                </div>



                {/* <div className="row mt-4">
                    <div className={`col-3 ${this.errorClass2(this.props.topSheet.fritWeight1Error)}`}>
                        <label htmlFor="fritWeight1">Frit Weight</label>
                        <input type="number" className="form-control sortingField" name="fritWeight1" id="fritWeight1" value={this.props.topSheet.fritWeight1} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                        <div className="text-danger">{this.props.topSheet.fritWeight1Err}</div>
                    </div>
                    <div className={`col-lg-3 d-flex`}>
                        <div className="input-group bottomAlign">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.fritWeight1Signature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.fritWeight1SignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'fritWeight1Signature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                            <div className="text-danger">{this.props.topSheet.fritWeight1SignatureErr}</div>
                        </div>
                    </div>
                    <div className={`col-3 ${this.errorClass2(this.props.topSheet.verifyWeight1Error)}`}>
                        <label htmlFor="verifyWeight1">Verify Weight</label>
                        <input type="number" className="form-control sortingField" name="verifyWeight1" id="verifyWeight1" value={this.props.topSheet.verifyWeight1} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                    </div>
                    <div className={`col-lg-3 d-flex`}>
                        <div className="input-group bottomAlign">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.verifyWeight1Signature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.verifyWeight1SignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'verifyWeight1Signature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className={`col-lg-3 ${this.errorClass2(this.props.topSheet.fritWeight2Error)}`}>
                        <label htmlFor="fritWeight2">Frit Weight</label>
                        <input type="number" className="form-control sortingField" name="fritWeight2" id="fritWeight2" value={this.props.topSheet.fritWeight2} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                    </div>
                    <div className={`col-lg-3 d-flex`}>
                        <div className="input-group bottomAlign">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.fritWeight2Signature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.fritWeight2SignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'fritWeight2Signature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-lg-3 ${this.errorClass2(this.props.topSheet.verifyWeight2Error)}`}>
                        <label htmlFor="verifyWeight2">Verify Weight</label>
                        <input type="number" className="form-control sortingField" name="verifyWeight2" id="verifyWeight2" value={this.props.topSheet.verifyWeight2} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                    </div>
                    <div className={`col-lg-3 d-flex`}>
                        <div className="input-group bottomAlign">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.verifyWeight2Signature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.verifyWeight2SignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'verifyWeight2Signature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="row mt-2">
                    <div className={`col-3`}>
                        <label htmlFor="fritWeightTotal">Total Weight</label>
                        <input type="number" className="form-control sortingField" name="fritWeightTotal" id="fritWeightTotal" value={this.props.sumArray(this.props.topSheet.fritWeights, "fritWeight")} disabled />
                    </div>
                    <div className={`col-lg-3 d-flex`}>
                        <div className="input-group bottomAlign">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.fritWeightTotalSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.fritWeightTotalSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'fritWeightTotalSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-3 ${this.errorClass2(this.props.topSheet.verifyWeightTotalError)}`}>
                        <label htmlFor="verifyWeightTotal">Total Verified Weight</label>
                        <input type="number" className="form-control sortingField" name="verifyWeightTotal" id="verifyWeightTotal" value={this.props.sumArray(this.props.topSheet.fritWeights, "verifyWeight")} disabled />
                    </div>
                    <div className={`col-lg-3 d-flex`}>
                        <div className="input-group bottomAlign">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.verifyWeightTotalSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.verifyWeightTotalSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'verifyWeightTotalSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col">
                        Frit Screening (WIPR-026 or 028)
                        <label className="form-check-label ml-5">
                            <input type="checkbox" className="form-check-input" name="fritScreeningNA" id="fritScreeningNA" checked={this.props.topSheet.fritScreeningNA} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                            Not Applicable</label>
                    </div>
                    <div className={`col-3`}>
                        <div className="input-group">
                            <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                {showSignature(this.props.topSheet.fritScreeningWipr026Signature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.fritScreeningWipr026SignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'fritScreeningWipr026Signature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                        <div className='text-danger'>{this.props.topSheet.fritScreeningWipr026SignatureErr}</div>
                    </div>
                </div>

                {/* <div className="row mt-4">
                    <div className="col">
                        {
                            this.props.topSheet.weights.map((row, index) => {
                                return (
                                    <div key={index} className="row mt-2">
                                        <div className={`col-lg-3 ${this.errorClass2(row.weightError)}`}>
                                            <label htmlFor="weight">Weight</label>
                                            <input type="number" className="form-control sortingField" name="weight" id="weight" value={row.weight} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'weights', index)} />
                                        </div>
                                        <div className={`col-lg-3 bottomAlign`}>
                                            <div className="input-group">
                                                <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                                    {showSignature(row.weightSignature)}
                                                </div>
                                                <div className="input-group-append">
                                                    <button className={`input-group-text ${this.errorClass2(row.weightSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={(e) => this.props.signArray(e, 'topSheet', 'weights', 'weightSignature', index)}>
                                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-danger">{row.weightSignatureErr}</div>
                                        </div>
                                        <div className={`col-lg-3 ${this.errorClass2(row.sampleInspectionError)}`}>
                                            <label htmlFor="weight1">Sample Inspection</label>
                                            <input type="text" className="form-control qaField" name="sampleInspection" id="sampleInspection" value={row.sampleInspection} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'weights', index)} />
                                            <div className="text-danger">{row.sampleInspectionErr}</div>
                                        </div>
                                        <div className={`col-lg-3 bottomAlign`}>
                                            <div className="input-group">
                                                <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                                    {showSignature(row.sampleInspectionSignature)}
                                                </div>
                                                <div className="input-group-append">
                                                    <button className={`input-group-text ${this.errorClass2(row.sampleInspectionSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={(e) => this.props.signArray(e, 'topSheet', 'weights', 'sampleInspectionSignature', index)}>
                                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                                    </button>
                                                </div>
                                                <div className="text-danger">{row.sampleInspectionSignatureErr}</div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                        {
                            !this.props.disabledFields.sortingField &&
                            <button className="btn btn-sm btn-clear mt-2" onClick={() => this.props.addRow('topSheet', 'weights')}>
                                <i className="fas fa-plus-circle"></i> Add Row
                            </button>
                        }

                    </div>
                </div> */}

                <div className="row mt-4">
                    <div className={`col  ${this.errorClass2(this.props.topSheet.sampleInspectionError)}`}>
                        <label htmlFor="sampleInspection">Sample Inspection</label>
                        <input type="text" className="form-control qaField" name="sampleInspection" id="sampleInspection" value={this.props.topSheet.sampleInspection} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                    </div>
                    <div className={`col-lg-3 bottomAlign`}>
                        <div className="input-group">
                            <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                {showSignature(this.props.topSheet.sampleInspectionSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.topSheet.sampleInspectionSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'sampleInspectionSignature')}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <fieldset className="border bg-light mt-4">
                    <legend className='h6'>Pail Weight Sheet (1468-14)</legend>

                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-check-label ml-4">
                                <input type="checkbox" className="form-check-input" name="noPailWeights" id="noPailWeights" checked={this.props.topSheet.noPailWeights} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                No Pail Weights</label>
                        </div>
                    </div>

                    {
                        !this.props.topSheet.noPailWeights &&
                        <>
                            <div className="row">
                                <div className={`col  ${this.errorClass2(this.props.topSheet.pailweightOperatorError)}`}>
                                    <label htmlFor="pailweightOperator">Operator</label>
                                    <input type="text" className="form-control sortingField" name="pailweightOperator" id="pailweightOperator" value={this.props.topSheet.pailweightOperator} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                </div>
                                <div className={`col ${this.errorClass2(this.props.topSheet.pailweightVerifierError)}`}>
                                    <label htmlFor="pailweightVerifier">Verifying Operator</label>
                                    <input type="text" className="form-control sortingField" name="pailweightVerifier" id="pailweightVerifier" value={this.props.topSheet.pailweightVerifier} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                </div>

                            </div>

                            <div className="row mt-4">
                                <div className="col">
                                    <table className="table table-sm table-striped">
                                        <thead>
                                            <tr>
                                                <td>Pail #</td>
                                                <td>Tank #</td>
                                                <td>Weight</td>
                                                <td>Adjustment</td>
                                                <td>Verify Weight</td>
                                                <td></td>
                                                <td>Checked</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.topSheet.pailWeights.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className={`${this.errorClass2(row.qcError)} ${this.errorClass2(row.tankError)} ${this.errorClass2(row.weightError)} ${this.errorClass2(row.adjustmentError)} ${this.errorClass2(row.verifyWeightError)}`}>{index + 1}
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control form-control-sm sortingField" name="tank" value={row.tank} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'pailWeights', index)} />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="form-control form-control-sm sortingField" name="weight" value={row.weight} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'pailWeights', index)} />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="form-control form-control-sm grindingField" name="adjustment" value={row.adjustment} disabled={this.props.disabledFields.grindingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'pailWeights', index)} />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="form-control form-control-sm sortingField" name="verifyWeight" value={row.verifyWeight} disabled={this.props.disabledFields.sortingField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'pailWeights', index)} />
                                                            </td>
                                                            <td>
                                                                {
                                                                    index > 0 &&
                                                                    <button className="btn btn-sm float-right text-secondary" onClick={() => this.props.deleteRow('topSheet', 'pailWeights', index)}>{AccessibleIcon("fa-solid fa-trash-can", "Delete Pail Weight Item")}</button>
                                                                }
                                                            </td>
                                                            <td className={`text-center ${this.errorClass2(row.pailCheckError)}`}>
                                                                <input type="checkbox" className={`${row.pailCheckError !== "" ? "ml-1" : ""}`} name="pailCheck" id="pailCheck" checked={row.pailCheck} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'pailWeights', index)} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>

                                    {
                                        !this.props.disabledFields.sortingField && this.props.topSheet.pailWeights.length < 28 &&
                                        <button className="btn btn-sm btn-clear" onClick={() => this.props.addRow('topSheet', 'pailWeights')}>
                                            <i className="fas fa-plus-circle"></i> Add Row
                                        </button>
                                    }
                                </div>
                            </div>
                        </>
                    }


                </fieldset>

                {
                    !this.props.topSheet.noPailWeights &&
                    <div className="row mt-4">
                        <div className={`col-lg-3`}>
                            <label htmlFor="totalPailWeight">Total Pail Weight (From Above)</label>
                            <div className="form-control sortingField nohighlight">{this.props.sumArray(this.props.topSheet.pailWeights, "weight")}</div>
                        </div>
                        <div className={`col-lg-3 d-flex`}>
                            <div className="input-group bottomAlign">
                                <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                    {showSignature(this.props.topSheet.totalPailWeightSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.topSheet.totalPailWeightSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'totalPailWeightSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                                <div className="text-danger">{this.props.topSheet.totalPailWeightSignatureErr}</div>
                            </div>
                        </div>
                        <div className={`col-lg-3`}>
                            <label htmlFor="totalPailWeight">Verified Weight (From Above)</label>
                            <div className="form-control sortingField nohighlight">{this.props.sumArray(this.props.topSheet.pailWeights, "verifyWeight")}</div>
                        </div>
                        <div className={`col-lg-3 d-flex`}>
                            <div className="input-group bottomAlign">
                                <div className="form-control sortingField" disabled={this.props.disabledFields.sortingField}>
                                    {showSignature(this.props.topSheet.totalVerifiedWeightSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.topSheet.totalVerifiedWeightSignatureError)}`} disabled={this.props.disabledFields.sortingField} onClick={() => this.props.sign('topSheet', 'totalVerifiedWeightSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                                <div className="text-danger">{this.props.topSheet.totalVerifiedWeightSignatureErr}</div>
                            </div>
                        </div>
                    </div>
                }

                <div className="fullBleed pb-3 mt-5 bordered qaField">
                    <div className="row">
                        <div className={`col-lg-5`}>
                            <h5 className={`${this.errorClass2(this.props.topSheet.inspectionAcceptError)}`}>Inspection</h5>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-check">
                                <label className="form-check-label ml-2">
                                    <input type="radio" className="form-check-input" name="inspectionAccept" id="inspectionAccept" value="Accepted" checked={this.props.topSheet.inspectionAccept === "Accepted" ? true : false} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                    Accepted</label>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-check">
                                <label className="form-check-label ml-2">
                                    <input type="radio" className="form-check-input" name="inspectionAccept" id="inspectionAccept" value="Rejected" checked={this.props.topSheet.inspectionAccept === "Rejected" ? true : false} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleChange(e, 'topSheet')} />
                                    Rejected</label>
                            </div>
                        </div>
                        <div className={`col-lg-3 d-flex`}>
                            <div className="input-group mb-3">
                                <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                    {showSignature(this.props.topSheet.inspectionSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.topSheet.inspectionSignatureError)}`} disabled={this.props.disabledFields.inspectionField} onClick={() => this.props.sign('topSheet', 'inspectionSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row mt-5">
                    <div className="col text-center">
                        <h6>Distribution History (if applicable)</h6>
                    </div>
                </div>
                {
                    this.props.topSheet.distributionHistory.map((row, index) => {
                        return (
                            <div key={index} className="row mt-2">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Pounds</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="pounds" id="pounds" value={row.pounds} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'distributionHistory', index)} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Lot</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="lot" id="lot" value={row.lot} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'distributionHistory', index)} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Control #</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="control" id="control" value={row.control} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'distributionHistory', index)} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Grind Date</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="grindDate" id="grindDate" value={row.grindDate} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'distributionHistory', index)} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Mill</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="mill" id="mill" value={row.mill} disabled={this.props.disabledFields.inspectionField} onChange={(e) => this.props.handleArrayChange(e, 'topSheet', 'distributionHistory', index)} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group bottom-align">
                                        {
                                            index > 0 &&
                                            <button className="btn btn-sm btn-clear text-secondary" onClick={() => this.props.deleteRow("topSheet", "distributionHistory", index)}>{AccessibleIcon("fa-solid fa-trash-can", "")}</button>
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    !this.props.disabledFields.inspectionField &&
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-sm btn-clear p-0 mt-2 mb-5" onClick={() => this.props.addRow('topSheet', 'distributionHistory')}>
                                <i className="fas fa-plus-circle me-1"></i> Add Row
                            </button>

                        </div>
                    </div>
                }

                <div className="fullBleed bg-light border mt-5">
                    <div className="btn border mr-3 batchField">Batch Room</div>
                    <div className="btn border mr-3 productionField">Production</div>
                    <div className="btn border mr-3 qaField">QA</div>
                    <div className="btn border mr-3 sortingField">Sorting</div>
                    <div className="btn border mr-3 inspectionField">Inspection</div>
                    <div className="btn border mr-3 gpField">GP</div>
                    <div className="btn border mr-3 grindingField">Grind Room</div>
                </div>

            </div>
        )
    }
}
