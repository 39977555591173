import React from 'react'
import axios from 'axios'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { SERVER } from '../shared/Environment'
import UserForm from './UserForm'
import { Modal } from 'react-bootstrap'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: 0,
            token: "",
            userIninitals: "",

            usersList: [],
            staffId: 0,

            showModal: false,

        }
    }

    componentDidMount() {
        this.getUserList()
    }

    getUserList = () => {
        axios.get(SERVER + '/api/users/', {
            headers: {
                userId: cookies.get("userId"),
                token: cookies.get("token")
            }
        })
            .then(response => {
                console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                if (response.data) {
                    this.setState({
                        usersList: response.data
                    })
                }

            })
            .catch(err => {
                console.log("BatchHistory -> handleSubmit -> err.response.status", err)
            })
    }

    showUser = (e, staffId) => {
        console.log("Users -> showUser -> staffId", staffId)
        this.setState({
            staffId: staffId,
            showModal: !this.state.showModal
        })
        e.stopPropagation()
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        }, () => this.getUserList())
    }

    deleteUser = (staffId) => {
        console.log("Users -> deleteUser -> staffId", staffId)
        axios.delete(SERVER + '/api/users/' + staffId, {
            headers: {
                userId: cookies.get("userId"),
                token: cookies.get("token")
            }
        })
            .then(response => {
                console.log("UserForm -> getUser -> response.data", response.data)
                if (response.data.success) {
                    this.getUserList()
                }

            })
            .catch(err => {
                console.log("UserForm -> getUser -> err", err)
            })
    }

    render() {
        return (
            <main>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col">
                            <h3 className='pgHeader'>Users</h3>
                        </div>
                        <div className="col text-right">
                            <button className="btn btn-sm btn-success" onClick={(e) => this.showUser(e, 0)}>
                                {AccessibleIcon('fa-solid fa-plus', 'New')} Add User
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Initials</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>User Name</th>
                                            <th>Access</th>
                                            <th>Department</th>
                                            <th><span className="sr-only">Action</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.usersList.map((row, index) => {
                                                return (
                                                    <tr key={index} className='cursor-pointer' onClick={(e) => this.showUser(e, row.id)}>
                                                        <td>{row.id}</td>
                                                        <td>{row.firstName}</td>
                                                        <td>{row.lastName}</td>
                                                        <td>{row.initials}</td>
                                                        <td>{row.email}</td>
                                                        <td>{row.phone}</td>
                                                        <td>{row.username}</td>
                                                        <td>{row.access}</td>
                                                        <td>{row.deptCode}</td>
                                                        <td><button className="btn btn-sm btn-clear" onClick={() => this.deleteUser(row.id)}>
                                                            {AccessibleIcon('far fa-trash-alt', 'Delete User')}</button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>

                <Modal show={this.state.showModal} onHide={this.toggleModal}>
                    <Modal.Header className='bg-secondary text-white'>
                        <Modal.Title>{this.state.staffId !== 0 ? "Update User" : "New User"}</Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={this.toggleModal}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <UserForm
                            staffId={this.state.staffId}
                            toggleModal={this.toggleModal}
                        />
                    </Modal.Body>
                </Modal>

            </main>
        )
    }

}

