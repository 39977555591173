import React from 'react'
import axios from 'axios'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { SERVER } from '../shared/Environment'
import { BATCHSHEET, PROCESSSHEET, QASHEET, TOPSHEET } from '../shared/FormStructures'
import TopSheet from './TopSheet'
import BatchSheet from './BatchSheet'
import ProcessSheet from './ProocessSheet'
import QASheet from './QASheet'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default class BatchHistoryForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: 0,
            token: "",
            userIninitals: "",
            batchContent: "topSheet",
            batchSheetIndex: 0,
            validForm: null,

            batchHistoryId: "0",
            formType: "BH",
            status: "",
            glassCode: "",
            formNumber: "",
            productCode: "",
            controlNumber: "",
            lotNumber: "",
            lotNumber2: "",

            disabledFields: {
                qaField: true,
                batchField: true,
                productionField: true,
                sortingField: true,
                inspectionField: true,
                gpField: true,
            },

            topSheet: JSON.parse(JSON.stringify(TOPSHEET)),
            batchSheets: [
                JSON.parse(JSON.stringify(BATCHSHEET))
            ],
            processSheet: JSON.parse(JSON.stringify(PROCESSSHEET)),
            qaSheet: JSON.parse(JSON.stringify(QASHEET)),
        }
    }

    componentDidMount() {
        this.getToken()
        if (this.props.batchHistoryId !== "0") {
            console.log("BatchHistoryForm -> componentDidMount -> this.props.batchHistoryId", this.props.batchHistoryId)
            this.setState({
                batchHistoryId: this.props.batchHistoryId
            }, () => this.getFormData())

        }
    }

    getToken = () => {
        this.setState({
            userId: this.getCookie("userId"),
            initials: this.getCookie("initials"),
            token: this.getCookie("token")
        })
    }

    getCookie = (cookieName) => {
        return cookies.get(cookieName)
    }

    fieldLocks = (formStatus, statusValue, deptCode) => {
        return (
            (formStatus !== statusValue || (this.getCookie("deptCode")).indexOf(deptCode) < 0)
            && this.getCookie("access") !== "Administrator"
            && this.getCookie("access") !== "Super User"
        )
    }

    getFormData = () => {
        if (cookies.get("token")) {
            axios.get(SERVER + '/api/batchHistories/' + this.state.batchHistoryId, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                    if (response.data) {
                        this.setState({
                            disabledFields: {
                                preprodField: this.fieldLocks(response.data.status, 'Pre-Production', 'QA'),
                                batchField: this.fieldLocks(response.data.status, 'BatchRoom', 'BatchRoom'),
                                productionField: this.fieldLocks(response.data.status, 'Production', 'Production'),
                                sortingField: this.fieldLocks(response.data.status, 'Sorting', 'Sorting'),
                                qaField: this.fieldLocks(response.data.status, 'QA', 'QA'),
                                inspectionField: this.fieldLocks(response.data.status, 'Inspection', 'QA'),
                                gpField: this.fieldLocks(response.data.status, 'GP', 'GP'),
                                grindingField: this.fieldLocks(response.data.status, 'GrindRoom', 'QA'),
                            },
                            formType: response.data.formType,
                            status: response.data.status,
                            glassCode: response.data.glassCode,
                            formNumber: response.data.formNumber,
                            productCode: response.data.productCode,
                            controlNumber: response.data.controlNumber,
                            lotNumber: response.data.lotNumber,
                            topSheet: response.data.topSheet,
                            batchSheets: response.data.batchSheets,
                            processSheet: response.data.processSheet,
                            qaSheet: response.data.qaSheet,
                        }, () => {
                            this.validateForm()
                        })
                    }

                })
                .catch(err => {
                    console.log("BatchHistory -> handleSubmit -> err.response.status", err)
                })
        } else {
            window.location.reload()
        }
    }

    handleChange = (event, objName, subObjName, fieldName, fieldValue) => {
        // console.log("BatchHistoryForm -> handleChange -> objName, subObjName, fieldName, fieldValue", objName, subObjName, fieldName, fieldValue)
        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }

        if (subObjName) {
            let newObj = this.state[objName]
            let newSubObj = {
                ...newObj[subObjName],
                [name]: value
            }
            console.log("BatchHistoryForm -> handleChange -> value", value)
            console.log("BatchHistoryForm -> handleChange -> name", name)
            console.log("BatchHistoryForm -> handleChange -> newObj", newObj)
            console.log("BatchHistoryForm -> handleChange -> newSubObj", newSubObj)

            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [subObjName]: newSubObj
                }
            }), () => {
                if (event) {
                    this.validateForm()
                }
            })

        } else if (objName) {
            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [name]: value
                }
            }), () => {
                if (event) {
                    this.validateForm()
                }
            })
        } else {
            this.setState({
                [name]: value
            }, () => {
                if (event) {
                    this.validateForm()
                }
            })

        }
    }

    handleArrayChange = (event, objName, arrayName, index, subObjName, fieldName, fieldValue) => {
        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }

        if (subObjName) {
            console.log("BatchHistory -> handleArrayChange -> objName, subObjName", objName, subObjName)

            let newObj = this.state[objName]
            let newSubObj = newObj[subObjName]
            let newArray = newSubObj[arrayName]

            // let newArray = [...this.state[objName][subObjName][arrayName]]
            newArray[index] = { [name]: value }
            console.log("BatchHistory -> handleArrayChange -> newArray", newArray)
            console.log("BatchHistoryForm -> handleArrayChange -> newSubObj", newSubObj)
            console.log("BatchHistoryForm -> handleArrayChange -> newObj", newObj)

            this.setState(prevState => ({
                [objName]: newObj
            }), () => {
                // console.log(this.state)
                if (event) {
                    this.validateForm()
                }
            })
        } else if (objName) {
            console.log("BatchHistory -> handleArrayChange -> objName", objName)
            console.log("BatchHistoryForm -> handleArrayChange -> arrayName", arrayName)

            let newArray = [...this.state[objName][arrayName]]
            newArray[index] = { ...newArray[index], [name]: value }
            console.log("BatchHistory -> handleArrayChange -> newArray", newArray)

            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [arrayName]: newArray
                }
            }), () => {
                if (event) {
                    this.validateForm()
                }
            })
        } else {
            let newArray = [...this.state[arrayName]]
            newArray[index] = { ...newArray[index], [name]: value }

            this.setState({
                [arrayName]: newArray
            }, () => {
                if (event) {
                    this.validateForm()
                }
            })

        }
    }

    splitRawMaterial = (arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex) => {
        console.log("BatchHistoryForm -> splitRawMaterial -> arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex", arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex)

        let arrayOfObject = this.state[arrayOfObjectName][arrayOfObjectIndex]
        let newArray = arrayOfObject[arrayName]
        newArray.splice(arrayIndex + 1, 0, {
            material: newArray[arrayIndex].material,
            lbs: "Split",
            actualLbs: 0,
            RN: "",
            signature: []
        })
        console.log("BatchHistoryForm -> splitRawMaterial -> newArray", newArray)

        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [arrayName]: newArray
                }) : obj),
            )
        }), () => {
            this.validateForm()
        })
    }

    deleteRawMaterial = (arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex) => {
        console.log("BatchHistoryForm -> deleteRawMaterial -> arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex", arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex)

        let arrayOfObject = this.state[arrayOfObjectName][arrayOfObjectIndex]
        let newArray = arrayOfObject[arrayName]
        newArray.splice(arrayIndex, 1)
        console.log("BatchHistoryForm -> deleteRawMaterial -> newArray", newArray)

        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [arrayName]: newArray
                }) : obj),
            )
        }), () => {
            this.validateForm()
        })
    }

    updateArrayInArrayOfObjects = (event, arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex, fieldName, fieldValue) => {
        // console.log("BatchHistoryForm -> updateArrayInArrayOfObjects -> fieldName", fieldName)
        // console.log("BatchHistoryForm -> updateArrayInArrayOfObjects -> fieldValue", fieldValue)
        // Object Format:
        // arrayOfObjectName: {
        //     fieldName1: "",
        //     fieldName2: "",
        //     arrayName: [
        //         fieldName3: "",
        //         fieldName4: "",
        //     ]
        // }

        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }

        let arrayOfObject = this.state[arrayOfObjectName][arrayOfObjectIndex]
        let newArray = arrayOfObject[arrayName]
        newArray[arrayIndex][name] = value

        if (arrayOfObjectName === "batchSheets" && arrayName === "hopperMaterial" && name === "actualLbs") {
            let totalWt = 0
            for (let i = 0; i < arrayOfObject[arrayName].length; i++) {
                totalWt += parseFloat(newArray[i].actualLbs)
            }
            arrayOfObject.hopperWeightTotal = totalWt.toFixed(2)
        }

        if (arrayOfObjectName === "batchSheets" && arrayName === "containerMaterial" && name === "actualLbs") {
            let totalWt = 0
            for (let i = 0; i < arrayOfObject[arrayName].length; i++) {
                totalWt += parseFloat(newArray[i].actualLbs)
            }
            // console.log("BatchHistoryForm -> updateArrayInArrayOfObjects -> totalWt", totalWt)
            arrayOfObject.containerWeightTotal = totalWt.toFixed(2)
        }

        if (arrayOfObjectName === "batchSheets" && arrayName === "smallMeltMaterial" && name === "actualLbs") {
            let totalWt = 0
            for (let i = 0; i < arrayOfObject[arrayName].length; i++) {
                totalWt += parseFloat(newArray[i].actualLbs)
            }
            // console.log("BatchHistoryForm -> updateArrayInArrayOfObjects -> totalWt", totalWt)
            arrayOfObject.smallMeltWeightTotal = totalWt.toFixed(2)
        }


        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [arrayName]: newArray
                }) : obj),
            )
        }), () => {
            if (event) {
                this.validateForm()
            }
        })
    }

    updateFieldinArrayOfObjects = (event, arrayOfObjectName, arrayOfObjectIndex, fieldName, fieldValue) => {
        // Object Format:
        // arrayOfObjectName: {
        //     fieldName1: "",
        //     fieldName2: "",
        //     arrayName: [
        //         fieldName3: "",
        //         fieldName4: "",
        //     ]
        // }

        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }

        this.setState(prevState => ({
            [arrayOfObjectName]: prevState[arrayOfObjectName].map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [name]: value
                }) : obj)
            )
        }), () => {
            // console.log("BatchHistoryForm -> updateFieldinArrayOfObjects -> this.state[arrayOfObjectName]", this.state[arrayOfObjectName])
            if (event) {
                this.validateForm()
            }
        })

    }
    signArrayInArrayOfObjects = (event, arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex, fieldName) => {
        // Object Format:
        // arrayOfObjectName: {
        //     fieldName1: "",
        //     fieldName2: "",
        //     arrayName: [
        //         fieldName3: "",
        //         fieldName4: "",
        //     ]
        // }

        let signList = this.state[arrayOfObjectName][arrayOfObjectIndex][arrayName][arrayIndex][fieldName]
        signList.unshift({
            initial: this.state.initials,
            signDate: new Date()
        })

        let arrayOfObject = this.state[arrayOfObjectName][arrayOfObjectIndex]
        let newArray = arrayOfObject[arrayName]
        newArray[arrayIndex][fieldName] = signList
        // console.log("BatchHistoryForm -> signArrayInArrayOfObjects -> newArray", newArray)
        // console.log("BatchHistoryForm -> signArrayInArrayOfObjects -> newArray[arrayIndex][fieldName]", newArray[arrayIndex][fieldName])

        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [arrayName]: newArray
                }) : obj)
            )
        }), () => {
            this.validateForm()
        })
    }

    signFieldinArrayOfObjects = (event, arrayOfObjectName, arrayOfObjectIndex, hardName, hardValue, fieldName) => {
        console.log("BatchHistoryForm -> signFieldinArrayOfObjects -> arrayOfObjectName", arrayOfObjectName)
        console.log("BatchHistoryForm -> signFieldinArrayOfObjects -> arrayOfObjectIndex", arrayOfObjectIndex)
        console.log("BatchHistoryForm -> signFieldinArrayOfObjects -> fieldName", fieldName)

        // Object Format:
        // arrayOfObjectName: {
        //     fieldName1: "",
        //     fieldName2: "",
        //     arrayName: [
        //         fieldName3: "",
        //         fieldName4: "",
        //     ]
        // }

        let signList = this.state[arrayOfObjectName][arrayOfObjectIndex][fieldName]
        signList.unshift({
            initial: this.state.initials,
            signDate: new Date()
        })
        console.log("BatchHistoryForm -> signFieldinArrayOfObjects -> signList", signList)

        // let arrayOfObject = this.state[arrayOfObjectName][arrayOfObjectIndex]
        this.setState(prevState => ({
            [arrayOfObjectName]: prevState[arrayOfObjectName].map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [fieldName]: signList
                }) : obj)
            )
        }), () => {
            this.validateForm()
        })
    }

    signArray = (event, objName, arrayName, fieldName, index) => {
        // console.log("BatchHistoryForm -> signArray -> objName", objName)
        // console.log("BatchHistoryForm -> signArray -> arrayName", arrayName)
        // console.log("BatchHistoryForm -> signArray -> fieldName", fieldName)
        // console.log("BatchHistoryForm -> signArray -> index", index)
        // // const target = event.target
        // const name = target.name

        if (objName) {
            let signList = this.state[objName][arrayName][index][fieldName]
            signList.unshift({
                initial: this.state.initials,
                signDate: new Date()
            })

            let newArray = [...this.state[objName][arrayName]]
            console.log("BatchHistoryForm -> signArray -> newArray", newArray)
            newArray[index] = { ...newArray[index], [fieldName]: signList }

            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [arrayName]: newArray
                }
            }), () => {
                this.validateForm()
            })
        } else {
            let signList = this.state[arrayName][fieldName]
            signList.unshift({
                initial: this.state.initials,
                signDate: new Date()
            })

            let newArray = [...this.state[arrayName]]
            newArray[index] = { ...newArray[index], [fieldName]: signList }

            this.setState({
                [arrayName]: newArray
            }, () => {
                this.validateForm()
            })

        }
    }

    sign = (objName, fieldName, subObjName) => {
        // console.log("BatchHistoryForm -> sign -> objName", objName)
        // console.log("BatchHistoryForm -> sign -> fieldName", fieldName)
        // console.log("BatchHistoryForm -> sign -> subObjName", subObjName)
        if (subObjName) {
            let signList = this.state[objName][subObjName][fieldName]
            signList.unshift({
                initial: this.state.initials,
                signDate: new Date()
            })
            console.log("BatchHistoryForm -> sign -> subObjName", subObjName)
            let newObj = this.state[objName]
            let newSubObj = {
                ...newObj[subObjName],
                [fieldName]: signList
            }

            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [subObjName]: newSubObj
                }
            }), () => {
                this.validateForm()
            })

        } else if (objName) {
            let signList = this.state[objName][fieldName]
            signList.unshift({
                initial: this.state.initials,
                signDate: new Date()
            })
            console.log("BatchHistoryForm -> sign -> signList", signList)
            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [fieldName]: signList
                }
            }), () => {
                this.validateForm()
            })
        } else {
            let signList = this.state[fieldName]
            signList.unshift({
                initial: this.state.initials,
                signDate: new Date()
            })
            this.setState({
                [fieldName]: signList
            }, () => {
                this.validateForm()
            })

        }
    }

    showContent = (sheet, batchSheetIndex) => {
        console.log("BatchHistoryForm -> showContent -> batchSheetIndex", batchSheetIndex)
        this.setState({
            batchContent: sheet,
            batchSheetIndex: batchSheetIndex
        })
    }

    sumArray = (arrayName, fieldName) => {
        let sumValue = 0
        for (let i = 0; i < arrayName.length; i++) {
            sumValue += parseFloat(arrayName[i][fieldName])
        }
        return sumValue.toFixed(2)
    }

    deleteRow = (objName, arrayName, index) => {
        let newArray = this.state[objName][arrayName]
        newArray.splice(index, 1)
        this.setState(prevState => ({
            [objName]: {
                ...prevState[objName],
                [arrayName]: newArray
            }
        }), () => {
            this.validateForm()
        })
    }

    addRow = (objName, arrayName) => {
        let newArray = this.state[objName][arrayName]
        if (arrayName === 'fritWeights') {
            newArray.push(
                {
                    fritWeight: 0,
                    fritWeightSignature: [],
                    verifyWeight: 0,
                    verifyWeightSignature: [],
                }
            )
        } else if (arrayName === 'distributionHistory') {
            newArray.push(
                {
                    pounds: "",
                    lot: "",
                    control: "",
                    grindDate: "",
                    mill: ""
                }
            )
        } else if (arrayName === 'hopperMaterial') {
            newArray.push(
                {
                    material: '',
                    lbs: 0,
                    actualLbs: 0,
                    RN: '',
                    hopperMaterialSignature: []
                }
            )
        } else if (arrayName === 'containerMaterial') {
            newArray.push(
                {
                    material: '',
                    lbs: 0,
                    actualLbs: 0,
                    RN: '',
                    containerMaterialSignature: []
                }
            )``
        } else if (arrayName === 'batchingGrid') {
            newArray.push(
                {
                    batchingTemp: "",
                    batchingTime: "",
                    batchingScooped: "",
                    batchingSignature: [],
                }
            )
        } else if (arrayName === 'pailWeights') {
            newArray.push(
                {
                    tank: "",
                    weight: 0,
                    adjustment: 0,
                    verifyWeight: 0,
                    pailCheck: false
                }
            )
        }

        this.setState(prevState => ({
            [objName]: {
                ...prevState[objName],
                [arrayName]: newArray
            }
        }), () => {
            this.validateForm()
        })
    }

    validField = (objName, fieldName, validateType) => {
        console.log("UserForm -> validField -> fieldName", fieldName)
        let errMsg = ""
        if (objName !== "") {
            if ((this.state[objName][fieldName].trim()).length === 0) {
                errMsg = "Required field"
            }
            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [fieldName + "Error"]: errMsg
                }
            }))

        } else {
            if ((this.state[fieldName].trim()).length === 0) {
                errMsg = "Required field"
            }
            this.setState({
                [fieldName + "Error"]: errMsg
            })
        }

        return errMsg !== "" ? false : true
    }


    validValue = (value, valueType) => {

        if (valueType === "string") {
            if (value.length === 0) {
                return false
            } else {
                return true
            }
        }
    }

    validateForm = async () => {
        // let validFields = true
        // let requiredFields = []

        let returnVal = ""
        let topSheetError = false
        let batchSheetsError = []
        let processSheetError = false
        let qaSheetError = false

        if (this.state.status === "Pre-Production") {

            // --------------------------------------------------------------------------------------------- //
            // ----------------------------------- PRE-PRODUCTION VALIDATION ------------------------------- //
            // --------------------------------------------------------------------------------------------- //

            topSheetError = topSheetError || this.state.topSheet.verifyBatchPaperworkSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "verifyBatchPaperworkSignatureError", this.state.topSheet.verifyBatchPaperworkSignature.length === 0 ? "Required Field" : "")

            this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)

        } else if (this.state.status === "BatchRoom") {

            // --------------------------------------------------------------------------------------------- //
            // ----------------------------------- BATCH ROOM VALIDATION ----------------------------------- //
            // --------------------------------------------------------------------------------------------- //

            for (let batchSheetIndex = 0; batchSheetIndex < this.state.batchSheets.length; batchSheetIndex++) {

                let batchSheetError = false

                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "hopperWeightError", parseFloat(this.state.batchSheets[batchSheetIndex].hopperWeight) === 0 ? "Required Field" : "")
                batchSheetError = batchSheetError || parseFloat(this.state.batchSheets[batchSheetIndex].hopperWeight) === ""

                for (let arrayIndex = 0; arrayIndex < this.state.batchSheets[batchSheetIndex].hopperMaterial.length; arrayIndex++) {

                    batchSheetError = batchSheetError || parseFloat(this.state.batchSheets[batchSheetIndex].hopperMaterial[arrayIndex].actualLbs) === 0
                    this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "hopperMaterial", arrayIndex, "actualLbsError", parseFloat(this.state.batchSheets[batchSheetIndex].hopperMaterial[arrayIndex].actualLbs) === 0 ? "Required Field" : "")

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].hopperMaterial[arrayIndex].RN === ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "hopperMaterial", arrayIndex, "RNError", this.state.batchSheets[batchSheetIndex].hopperMaterial[arrayIndex].RN === "" ? "Required Field" : "")

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].hopperMaterial[arrayIndex].signature.length === 0
                    this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "hopperMaterial", arrayIndex, "signatureError", this.state.batchSheets[batchSheetIndex].hopperMaterial[arrayIndex].signature.length === 0 ? "Required Field" : "")
                }
                console.log("BatchHistoryForm -> validateForm -> first loop -> batchSheetError", batchSheetError)

                if (!this.state.batchSheets[batchSheetIndex].noContainer) {
                    batchSheetError = batchSheetError || parseFloat(this.state.batchSheets[batchSheetIndex].containerWeight) === 0
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerWeightError", parseFloat(this.state.batchSheets[batchSheetIndex].containerWeight) === 0 ? "Required Field" : "")

                    for (let arrayIndex = 0; arrayIndex < this.state.batchSheets[batchSheetIndex].containerMaterial.length; arrayIndex++) {

                        batchSheetError = batchSheetError || parseFloat(this.state.batchSheets[batchSheetIndex].containerMaterial[arrayIndex].actualLbs) === 0
                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerMaterial", arrayIndex, "actualLbsError", parseFloat(this.state.batchSheets[batchSheetIndex].containerMaterial[arrayIndex].actualLbs) === 0 ? "Required Field" : "")

                        batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].containerMaterial[arrayIndex].RN === ""
                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerMaterial", arrayIndex, "RNError", this.state.batchSheets[batchSheetIndex].containerMaterial[arrayIndex].RN === "" ? "Required Field" : "")

                        batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].containerMaterial[arrayIndex].signature.length === 0
                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerMaterial", arrayIndex, "signatureError", this.state.batchSheets[batchSheetIndex].containerMaterial[arrayIndex].signature.length === 0 ? "Required Field" : "")

                    }
                    console.log("BatchHistoryForm -> validateForm -> second loop -> batchSheetError", batchSheetError)
                } else {
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerWeightError", "")
                    for (let arrayIndex = 0; arrayIndex < this.state.batchSheets[batchSheetIndex].containerMaterial.length; arrayIndex++) {

                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerMaterial", arrayIndex, "actualLbsError", "")

                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerMaterial", arrayIndex, "RNError", "")

                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerMaterial", arrayIndex, "signatureError", "")

                    }
                }

                if (!this.state.batchSheets[batchSheetIndex].noSmallMelt) {
                    batchSheetError = batchSheetError || parseFloat(this.state.batchSheets[batchSheetIndex].smallMeltWeight) === 0
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltWeightError", parseFloat(this.state.batchSheets[batchSheetIndex].smallMeltWeight) === 0 ? "Required Field" : "")

                    for (let arrayIndex = 0; arrayIndex < this.state.batchSheets[batchSheetIndex].smallMeltMaterial.length; arrayIndex++) {

                        batchSheetError = batchSheetError || parseFloat(this.state.batchSheets[batchSheetIndex].smallMeltMaterial[arrayIndex].actualLbs) === 0
                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltMaterial", arrayIndex, "actualLbsError", parseFloat(this.state.batchSheets[batchSheetIndex].smallMeltMaterial[arrayIndex].actualLbs) === 0 ? "Required Field" : "")

                        batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].smallMeltMaterial[arrayIndex].RN === ""
                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltMaterial", arrayIndex, "RNError", this.state.batchSheets[batchSheetIndex].smallMeltMaterial[arrayIndex].RN === "" ? "Required Field" : "")

                        batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].smallMeltMaterial[arrayIndex].signature.length === 0
                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltMaterial", arrayIndex, "signatureError", this.state.batchSheets[batchSheetIndex].smallMeltMaterial[arrayIndex].signature.length === 0 ? "Required Field" : "")

                    }

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].smallMeltWeightSignature.length === 0
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltWeightSignatureError", this.state.batchSheets[batchSheetIndex].smallMeltWeightSignature.length === 0 ? "Required Field" : "")

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].blendTimeStart === ""
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "blendTimeStartError", this.state.batchSheets[batchSheetIndex].blendTimeStart === "" ? "Required Field" : "")

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].blendTimeEnd === ""
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "blendTimeEndError", this.state.batchSheets[batchSheetIndex].blendTimeEnd === "" ? "Required Field" : "")

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].blendTimeSignature.length === 0
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "blendTimeSignatureError", this.state.batchSheets[batchSheetIndex].blendTimeSignature.length === 0 ? "Required Field" : "")

                } else {
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltWeightError", "")
                    for (let arrayIndex = 0; arrayIndex < this.state.batchSheets[batchSheetIndex].smallMeltMaterial.length; arrayIndex++) {

                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltMaterial", arrayIndex, "actualLbsError", "")

                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltMaterial", arrayIndex, "RNError", "")

                        this.updateArrayInArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltMaterial", arrayIndex, "signatureError", "")
                    }

                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "smallMeltWeightSignatureError", "")

                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "blendTimeStartError", "")

                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "blendTimeEndError", "")

                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "")
                }

                // batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].hopperWeightTotal === ""
                // this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "hopperWeightTotalError", this.state.batchSheets[batchSheetIndex].hopperWeightTotal === "" ? "Required Field" : "")

                batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].hopperWeightSignature.length === 0
                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "hopperWeightSignatureError", this.state.batchSheets[batchSheetIndex].hopperWeightSignature.length === 0 ? "Required Field" : "")

                if (!this.state.batchSheets[batchSheetIndex].noContainer) {
                    // batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].containerWeightTotal === ""
                    // this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerWeightTotalError", this.state.batchSheets[batchSheetIndex].containerWeightTotal === "" ? "Required Field" : "")

                    batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].containerWeightSignature.length === 0
                    this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "containerWeightSignatureError", this.state.batchSheets[batchSheetIndex].containerWeightSignature.length === 0 ? "Required Field" : "")
                }

                batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].verifyTotalBatchWeightSignature.length === 0
                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "verifyTotalBatchWeightSignatureError", this.state.batchSheets[batchSheetIndex].verifyTotalBatchWeightSignature.length === 0 ? "Required Field" : "")

                batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].vBlenderSignature.length === 0
                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "vBlenderSignatureError", this.state.batchSheets[batchSheetIndex].vBlenderSignature.length === 0 ? "Required Field" : "")

                batchSheetError = batchSheetError || this.state.batchSheets[batchSheetIndex].batchBucketSignature.length === 0
                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "batchBucketSignatureError", this.state.batchSheets[batchSheetIndex].batchBucketSignature.length === 0 ? "Required Field" : "")


                console.log("BatchHistoryForm -> validateForm -> last loop -> post-batchBucketSignatureError batchSheetError", batchSheetError)
                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "batchSheetError", batchSheetError)
                batchSheetsError[batchSheetIndex] = batchSheetError
            }

        } else if (this.state.status === "Production") {

            // --------------------------------------------------------------------------------------------- //
            // ----------------------------------- PRODUCTION VALIDATION ----------------------------------- //
            // --------------------------------------------------------------------------------------------- //

            topSheetError = false

            // topSheetError = topSheetError || this.state.lotNumber === ""
            // returnVal = await this.handleChange(null, "topSheet", "", "lotNumberError", this.state.lotNumber === "" ? "Required Field" : "")

            if (this.state.lotNumber === "") {
                topSheetError = topSheetError || this.state.lotNumber === ""
                returnVal = await this.handleChange(null, "topSheet", "", "lotNumberError", this.state.lotNumber === "" ? "Required Field" : "")
                returnVal = await this.handleChange(null, "topSheet", "", "lotNumberDuplicate", "")
                this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)
            } else {
                axios.post(SERVER + '/api/uniqueLotNumber/', {
                    batchHistoryId: this.state.batchHistoryId,
                    glassCode: this.state.glassCode,
                    lotNumber: this.state.lotNumber
                }, {
                    headers: {
                        userId: cookies.get("userId"),
                        token: cookies.get("token")
                    }
                })
                    .then(response => {
                        console.log("CBatchHistoryForm -> validateLotNumber -> response.data", response.data)
                        if (!response.data) {
                            topSheetError = topSheetError || !response.data
                            this.handleChange(null, "topSheet", "", "lotNumberError", !response.data ? "Duplicate Lot Number" : "")
                            this.handleChange(null, "topSheet", "", "lotNumberDuplicate", !response.data ? "Glass Code and Lot Number already in use" : "")
                        } else {
                            this.handleChange(null, "topSheet", "", "lotNumberError", "")
                            this.handleChange(null, "topSheet", "", "lotNumberDuplicate", "")
                        }
                        this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)
                    })
                    .catch(err => {
                        console.log("CBatchHistoryForm -> validateLotNumber -> err", err)
                    })
            }

            processSheetError = false

            if (this.state.processSheet.inspectionBeforeBatching.visible) {
                processSheetError = processSheetError || this.state.processSheet.inspectionBeforeBatching.welderAmperage === ""
                returnVal = await this.handleChange(null, "processSheet", "inspectionBeforeBatching", "welderAmperageError", this.state.processSheet.inspectionBeforeBatching.welderAmperage === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.inspectionBeforeBatching.readingTime === ""
                returnVal = await this.handleChange(null, "processSheet", "inspectionBeforeBatching", "readingTimeError", this.state.processSheet.inspectionBeforeBatching.readingTime === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.inspectionBeforeBatching.readingTimeSignature.length === 0
                returnVal = await this.handleChange(null, "processSheet", "inspectionBeforeBatching", "readingTimeSignatureError", this.state.processSheet.inspectionBeforeBatching.readingTimeSignature.length === 0 ? "Required Field" : "")

                let enteredBurner = this.state.processSheet.inspectionBeforeBatching.burners.filter(row => {
                    return row.value !== ""
                })
                processSheetError = processSheetError || enteredBurner.length === 0
                returnVal = await this.handleChange(null, "processSheet", "inspectionBeforeBatching", "burnersError", enteredBurner.length === 0 ? "Required Field" : "")
            }


            for (let arrayIndex = 0; arrayIndex < this.state.processSheet.batchingGrid.length; arrayIndex++) {

                processSheetError = processSheetError || this.state.processSheet.batchingGrid[arrayIndex].batchingTemp === ""
                returnVal = await this.handleArrayChange(null, "processSheet", "batchingGrid", arrayIndex, "", "batchingTempError", this.state.processSheet.batchingGrid[arrayIndex].batchingTemp === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.batchingGrid[arrayIndex].batchingTime === ""
                returnVal = await this.handleArrayChange(null, "processSheet", "batchingGrid", arrayIndex, "", "batchingTimeError", this.state.processSheet.batchingGrid[arrayIndex].batchingTime === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.batchingGrid[arrayIndex].batchingScooped === ""
                returnVal = await this.handleArrayChange(null, "processSheet", "batchingGrid", arrayIndex, "", "batchingScoopedError", this.state.processSheet.batchingGrid[arrayIndex].batchingScooped === "" ? "Required Field" : "")

                if (arrayIndex === 0) {
                    processSheetError = processSheetError || this.state.processSheet.batchingGrid[arrayIndex].batchingSignature.length === 0
                    returnVal = await this.handleArrayChange(null, "processSheet", "batchingGrid", arrayIndex, "", "batchingSignatureError", this.state.processSheet.batchingGrid[arrayIndex].batchingSignature.length === 0 ? "Required Field" : "")
                }

            }

            processSheetError = processSheetError || this.state.processSheet.batchingLeft === ""
            returnVal = await this.handleChange(null, "processSheet", "", "batchingLeftError", this.state.processSheet.batchingLeft === "" ? "Required Field" : "")

            if (this.state.processSheet.inspectionAfterBatching.visible) {
                processSheetError = processSheetError || this.state.processSheet.inspectionAfterBatching.welderAmperage === ""
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterBatching", "welderAmperageError", this.state.processSheet.inspectionAfterBatching.welderAmperage === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.inspectionAfterBatching.readingTime === ""
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterBatching", "readingTimeError", this.state.processSheet.inspectionAfterBatching.readingTime === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.inspectionAfterBatching.readingTimeSignature.length === 0
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterBatching", "readingTimeSignatureError", this.state.processSheet.inspectionAfterBatching.readingTimeSignature.length === 0 ? "Required Field" : "")

                let enteredBurner = this.state.processSheet.inspectionAfterBatching.burners.filter(row => {
                    return row.value !== ""
                })
                processSheetError = processSheetError || enteredBurner.length === 0
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterBatching", "burnersError", enteredBurner.length === 0 ? "Required Field" : "")
            }


            processSheetError = processSheetError || this.state.processSheet.meltingCycle === ""
            returnVal = await this.handleChange(null, "processSheet", "", "meltingCycleError", this.state.processSheet.meltingCycle === "" ? "Required Field" : "")

            processSheetError = processSheetError || this.state.processSheet.meltingCycleSignature.length === 0
            returnVal = await this.handleChange(null, "processSheet", "", "meltingCycleSignatureError", this.state.processSheet.meltingCycleSignature.length === 0 ? "Required Field" : "")

            processSheetError = processSheetError || this.state.processSheet.meltingCycleConfirmSignature.length === 0
            returnVal = await this.handleChange(null, "processSheet", "", "meltingCycleConfirmSignatureError", this.state.processSheet.meltingCycleConfirmSignature.length === 0 ? "Required Field" : "")

            if (this.state.processSheet.inspectionAfterMelting.visible) {
                processSheetError = processSheetError || this.state.processSheet.inspectionAfterMelting.welderAmperage === ""
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterMelting", "welderAmperageError", this.state.processSheet.inspectionAfterMelting.welderAmperage === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.inspectionAfterMelting.readingTime === ""
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterMelting", "readingTimeError", this.state.processSheet.inspectionAfterMelting.readingTime === "" ? "Required Field" : "")

                processSheetError = processSheetError || this.state.processSheet.inspectionAfterMelting.readingTimeSignature.length === 0
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterMelting", "readingTimeSignatureError", this.state.processSheet.inspectionAfterMelting.readingTimeSignature.length === 0 ? "Required Field" : "")

                let enteredBurner = this.state.processSheet.inspectionAfterMelting.burners.filter(row => {
                    return row.value !== ""
                })
                processSheetError = processSheetError || enteredBurner.length === 0
                returnVal = await this.handleChange(null, "processSheet", "inspectionAfterMelting", "burnersError", enteredBurner.length === 0 ? "Required Field" : "")
            }

            processSheetError = processSheetError || this.state.processSheet.drainingTemp === ""
            returnVal = await this.handleChange(null, "processSheet", "", "drainingTempError", this.state.processSheet.drainingTemp === "" ? "Required Field" : "")

            processSheetError = processSheetError || this.state.processSheet.drainingTime === ""
            returnVal = await this.handleChange(null, "processSheet", "", "drainingTimeError", this.state.processSheet.drainingTime === "" ? "Required Field" : "")

            processSheetError = processSheetError || this.state.processSheet.drainingSignature.length === 0
            returnVal = await this.handleChange(null, "processSheet", "", "drainingSignatureError", this.state.processSheet.drainingSignature.length === 0 ? "Required Field" : "")

            processSheetError = processSheetError || this.state.processSheet.drainingFrit === ""
            returnVal = await this.handleChange(null, "processSheet", "", "drainingFritError", this.state.processSheet.drainingFrit === "" ? "Required Field" : "")

            processSheetError = processSheetError || this.state.processSheet.drainingFritSignature.length === 0
            returnVal = await this.handleChange(null, "processSheet", "", "drainingFritSignatureError", this.state.processSheet.drainingFritSignature.length === 0 ? "Required Field" : "")

            this.handleChange(null, "processSheet", "", "processSheetError", processSheetError)


        } else if (this.state.status === "QA") {

            // --------------------------------------------------------------------------------------------- //
            // --------------------------------------- QA VALIDATION --------------------------------------- //
            // --------------------------------------------------------------------------------------------- //

            topSheetError = false

            topSheetError = topSheetError || this.state.topSheet.thermalTestingAccept === ""
            returnVal = await this.handleChange(null, "topSheet", "", "thermalTestingAcceptError", this.state.topSheet.thermalTestingAccept === "" ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.indexRefractionAccept === ""
            returnVal = await this.handleChange(null, "topSheet", "", "indexRefractionAcceptError", this.state.topSheet.indexRefractionAccept === "" ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.fiberSofteningAccept === ""
            returnVal = await this.handleChange(null, "topSheet", "", "fiberSofteningAcceptError", this.state.topSheet.fiberSofteningAccept === "" ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.thermalTestingSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "thermalTestingSignatureError", this.state.topSheet.thermalTestingSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.indexRefractionSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "indexRefractionSignatureError", this.state.topSheet.indexRefractionSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.fiberSofteningSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "fiberSofteningSignatureError", this.state.topSheet.fiberSofteningSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.retentionSampleTakenSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "retentionSampleTakenSignatureError", this.state.topSheet.retentionSampleTakenSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.sampleInspection === ""
            returnVal = await this.handleChange(null, "topSheet", "", "sampleInspectionError", this.state.topSheet.sampleInspection === "" ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.sampleInspectionSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "sampleInspectionSignatureError", this.state.topSheet.sampleInspectionSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.retentionSampleTakenSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "retentionSampleTakenSignatureError", this.state.topSheet.retentionSampleTakenSignature.length === 0 ? "Required Field" : "")

            this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)

            qaSheetError = false

            if (this.state.topSheet.thermalTestingAccept !== "N/A") {
                qaSheetError = qaSheetError || this.state.qaSheet.thermalExpansion1 === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansion1Error", this.state.qaSheet.thermalExpansion1 === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.thermalExpansion2 === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansion2Error", this.state.qaSheet.thermalExpansion2 === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.thermalExpansion3 === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansion3Error", this.state.qaSheet.thermalExpansion3 === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.thermalExpansionAvg === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansionAvgError", this.state.qaSheet.thermalExpansionAvg === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.thermalExpansionSignature.length === 0
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansionSignatureError", this.state.qaSheet.thermalExpansionSignature.length === 0 ? "Required Field" : "")
            } else {
                qaSheetError = qaSheetError || false
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansion1Error", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansion2Error", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansion3Error", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansionAvgError", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "thermalExpansionSignatureError", "")
            }

            if (this.state.topSheet.indexRefractionAccept !== "N/A") {
                qaSheetError = qaSheetError || this.state.qaSheet.indexRefraction1 === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "indexRefraction1Error", this.state.qaSheet.indexRefraction1 === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.indexRefraction2 === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "indexRefraction2Error", this.state.qaSheet.indexRefraction2 === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.indexRefractionSignature.length === 0
                returnVal = await this.handleChange(null, "qaSheet", "", "indexRefractionSignatureError", this.state.qaSheet.indexRefractionSignature.length === 0 ? "Required Field" : "")
            } else {
                qaSheetError = qaSheetError || false
                returnVal = await this.handleChange(null, "qaSheet", "", "indexRefraction1Error", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "indexRefraction2Error", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "indexRefractionSignatureError", "")
            }

            if (this.state.topSheet.fiberSofteningAccept !== "N/A") {
                qaSheetError = qaSheetError || this.state.qaSheet.fiberSoftening1 === ""
                returnVal = await this.handleChange(null, "qaSheet", "", "fiberSoftening1Error", this.state.qaSheet.fiberSoftening1 === "" ? "Required Field" : "")

                qaSheetError = qaSheetError || this.state.qaSheet.fiberSofteningSignature.length === 0
                returnVal = await this.handleChange(null, "qaSheet", "", "fiberSofteningSignatureError", this.state.qaSheet.fiberSofteningSignature.length === 0 ? "Required Field" : "")
            } else {
                qaSheetError = qaSheetError || false
                returnVal = await this.handleChange(null, "qaSheet", "", "fiberSoftening1Error", "")
                returnVal = await this.handleChange(null, "qaSheet", "", "fiberSofteningSignatureError", "")
            }

            this.handleChange(null, "qaSheet", "", "qaSheetError", qaSheetError)

        } else if (this.state.status === "Sorting") {
            console.log("BatchHistoryForm -> validateForm -> Sorting validation -> this.state.status", this.state.status)

            // --------------------------------------------------------------------------------------------- //
            // ------------------------------------ SORTING VALIDATION ------------------------------------- //
            // --------------------------------------------------------------------------------------------- //

            topSheetError = false

            topSheetError = topSheetError || this.state.topSheet.fritWeightBeforeSortingSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "fritWeightBeforeSortingSignatureError", this.state.topSheet.fritWeightBeforeSortingSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.sortingWipr029Signature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "sortingWipr029SignatureError", this.state.topSheet.sortingWipr029Signature.length === 0 ? "Required Field" : "")


            topSheetError = topSheetError || this.state.topSheet.fritWeightTotalSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "fritWeightTotalSignatureError", this.state.topSheet.fritWeightTotalSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.verifyWeightTotalSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "verifyWeightTotalSignatureError", this.state.topSheet.verifyWeightTotalSignature.length === 0 ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.fritScreeningWipr026Signature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "fritScreeningWipr026SignatureError", this.state.topSheet.fritScreeningWipr026Signature.length === 0 ? "Required Field" : "")

            for (let arrayIndex = 0; arrayIndex < this.state.topSheet.fritWeights.length; arrayIndex++) {

                topSheetError = topSheetError || this.state.topSheet.fritWeights[arrayIndex].fritWeight === ""
                returnVal = await this.handleArrayChange(null, "topSheet", "fritWeights", arrayIndex, "", "fritWeightSignatureError", this.state.topSheet.fritWeights[arrayIndex].fritWeight === "" ? "Required Field" : "")

                topSheetError = topSheetError || this.state.topSheet.fritWeights[arrayIndex].fritWeightSignature.length === 0
                returnVal = await this.handleArrayChange(null, "topSheet", "fritWeights", arrayIndex, "", "fritWeightSignatureError", this.state.topSheet.fritWeights[arrayIndex].fritWeightSignature.length === 0 ? "Required Field" : "")

                topSheetError = topSheetError || this.state.topSheet.fritWeights[arrayIndex].verifyWeight === ""
                returnVal = await this.handleArrayChange(null, "topSheet", "fritWeights", arrayIndex, "", "verifyWeightSignatureError", this.state.topSheet.fritWeights[arrayIndex].verifyWeight === "" ? "Required Field" : "")

                topSheetError = topSheetError || this.state.topSheet.fritWeights[arrayIndex].verifyWeightSignature.length === 0
                returnVal = await this.handleArrayChange(null, "topSheet", "fritWeights", arrayIndex, "", "verifyWeightSignatureError", this.state.topSheet.fritWeights[arrayIndex].verifyWeightSignature.length === 0 ? "Required Field" : "")
            }

            if (!this.state.topSheet.noPailWeights) {
                topSheetError = topSheetError || this.state.topSheet.pailweightOperator === ""
                returnVal = await this.handleChange(null, "topSheet", "", "pailweightOperatorError", this.state.topSheet.pailweightOperator === "" ? "Required Field" : "")

                topSheetError = topSheetError || this.state.topSheet.pailweightVerifier === ""
                returnVal = await this.handleChange(null, "topSheet", "", "pailweightVerifierError", this.state.topSheet.pailweightVerifier === "" ? "Required Field" : "")

                for (let arrayIndex = 0; arrayIndex < this.state.topSheet.pailWeights.length; arrayIndex++) {

                    // topSheetError = topSheetError || this.state.topSheet.pailWeights[arrayIndex].qc === ""
                    // returnVal = await this.handleArrayChange(null, "topSheet", "pailWeights", arrayIndex, "", "qcError", this.state.topSheet.pailWeights[arrayIndex].qc === "" ? "Required Field" : "")

                    topSheetError = topSheetError || this.state.topSheet.pailWeights[arrayIndex].tank === ""
                    returnVal = await this.handleArrayChange(null, "topSheet", "pailWeights", arrayIndex, "", "tankError", this.state.topSheet.pailWeights[arrayIndex].tank === "" ? "Required Field" : "")

                    topSheetError = topSheetError || this.state.topSheet.pailWeights[arrayIndex].weight === ""
                    returnVal = await this.handleArrayChange(null, "topSheet", "pailWeights", arrayIndex, "", "weightError", this.state.topSheet.pailWeights[arrayIndex].weight === "" ? "Required Field" : "")

                    topSheetError = topSheetError || this.state.topSheet.pailWeights[arrayIndex].adjustment === ""
                    returnVal = await this.handleArrayChange(null, "topSheet", "pailWeights", arrayIndex, "", "adjustmentError", this.state.topSheet.pailWeights[arrayIndex].adjustment === "" ? "Required Field" : "")

                    topSheetError = topSheetError || this.state.topSheet.pailWeights[arrayIndex].verifyWeight === ""
                    returnVal = await this.handleArrayChange(null, "topSheet", "pailWeights", arrayIndex, "", "verifyWeightError", this.state.topSheet.pailWeights[arrayIndex].verifyWeight === "" ? "Required Field" : "")

                }

                topSheetError = topSheetError || this.state.topSheet.totalPailWeightSignature.length === 0
                returnVal = await this.handleChange(null, "topSheet", "", "totalPailWeightSignatureError", this.state.topSheet.totalPailWeightSignature.length === 0 ? "Required Field" : "")

                topSheetError = topSheetError || this.state.topSheet.totalVerifiedWeightSignature.length === 0
                returnVal = await this.handleChange(null, "topSheet", "", "totalVerifiedWeightSignatureError", this.state.topSheet.totalVerifiedWeightSignature.length === 0 ? "Required Field" : "")
            }

            console.log("BatchHistoryForm -> validateForm -> Sorting validation -> topSheetError", topSheetError)
            this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)

        } else if (this.state.status === "Inspection") {

            // --------------------------------------------------------------------------------------------- //
            // ------------------------------------- INSPECTION VALIDATION --------------------------------- //
            // --------------------------------------------------------------------------------------------- //

            topSheetError = false

            topSheetError = topSheetError || this.state.topSheet.inspectionAccept === ""
            returnVal = await this.handleChange(null, "topSheet", "", "inspectionAcceptError", this.state.topSheet.inspectionAccept === "" ? "Required Field" : "")

            topSheetError = topSheetError || this.state.topSheet.inspectionSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "inspectionSignatureError", this.state.topSheet.inspectionSignature.length === 0 ? "Required Field" : "")

            if (!this.state.topSheet.noPailWeights) {
                for (let arrayIndex = 0; arrayIndex < this.state.topSheet.pailWeights.length; arrayIndex++) {

                    topSheetError = topSheetError || !this.state.topSheet.pailWeights[arrayIndex].pailCheck
                    returnVal = await this.handleArrayChange(null, "topSheet", "pailWeights", arrayIndex, "", "pailCheckError", !this.state.topSheet.pailWeights[arrayIndex].pailCheck ? "Required Field" : "")

                }
            }

            this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)

        } else if (this.state.status === "GP") {
            topSheetError = false

            topSheetError = topSheetError || this.state.topSheet.gpSignature.length === 0
            returnVal = await this.handleChange(null, "topSheet", "", "gpSignatureError", this.state.topSheet.gpSignature.length === 0 ? "Required Field" : "")

            this.handleChange(null, "topSheet", "", "topSheetError", topSheetError)


        } else if (this.state.status === "GrindRoom") {

        }

        let validForm = true
        if (topSheetError) {
            console.log("BatchHistoryForm -> validateForm -> topSheetError", topSheetError)
            validForm = false
        }

        if (processSheetError) {
            console.log("BatchHistoryForm -> validateForm -> processSheetError", processSheetError)
            validForm = false
        }

        if (qaSheetError) {
            console.log("BatchHistoryForm -> validateForm -> qaSheetError", qaSheetError)
            validForm = false
        }

        for (let i = 0; i < this.state.batchSheets.length; i++) {
            if (batchSheetsError[i]) {
                console.log("BatchHistoryForm -> validateForm -> batchSheetsError[i]", i, batchSheetsError[i])
                validForm = false
            }
        }

        this.setState({
            validForm
        }, () => {
            console.log("BatchHistoryForm -> validateForm -> this.state.validForm", this.state.validForm)
            return this.state.validForm
        })

    }

    workflowNext = () => {
        switch (this.state.status) {
            case "Setup":
                return ("Pre-Production")

            case "Pre-Production":
                return ("BatchRoom")

            case "BatchRoom":
                return ("Production")

            case "Production":
                return ("Sorting")

            case "Sorting":
                return ("Inspection")

            case "Inspection":
                return ("QA")

            case "QA":
                return ("GP")

            case "GP":
                return ("GrindRoom")

            default:
                return ("QA")
        }
    }

    saveForm = (submitForm) => {
        if (cookies.get("token")) {

            const batchHistory = {
                formType: this.state.formType,
                status: submitForm ? this.workflowNext() : this.state.status,
                submitForm,
                glassCode: this.state.glassCode,
                formNumber: this.state.formNumber,
                productCode: this.state.productCode,
                controlNumber: this.state.controlNumber,
                lotNumber: this.state.lotNumber,
                lotNumber2: this.state.lotNumber2,
                topSheet: this.state.topSheet,
                batchSheets: this.state.batchSheets,
                processSheet: this.state.processSheet,
                qaSheet: this.state.qaSheet,
            }
            console.log("BatchHistoryForm -> saveForm -> batchHistory", batchHistory)

            axios.post(SERVER + '/api/batchHistories/' + this.state.batchHistoryId, batchHistory, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                    if (response.data) {
                        // this.setState({
                        //     batchHistoryId: response.data.batchHistoryId
                        // })
                        this.props.toggleModal()
                    }

                })
                .catch(err => {
                    console.log("BatchHistory -> handleSubmit -> err.response.status", err)
                })
        } else {
            window.location.reload()
        }
    }

    changeStatus = (newStatus) => {
        this.setState({
            vSlider: false
        })

        if (cookies.get("token")) {
            const batchHistory = {
                status: newStatus
            }

            axios.post(SERVER + '/api/batchHistoriesStatus/' + this.state.batchHistoryId, batchHistory, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                    if (response.data) {
                        this.getFormData()
                    }

                })
                .catch(err => {
                    console.log("BatchHistory -> handleSubmit -> err.response.status", err)
                })
        }
    }

    displayStatus = (value) => {
        switch (value) {
            case "BatchRoom":
                return ("Batch Room")
            case "GrindRoom":
                return ("Grind Room")
            default:
                return (value)
        }
    }

    render() {
        return (
            <main>
                <div className="container-fluid">
                    <div className="row bg-secondary text-white">
                        <div className="col-lg-2 bg-secondary raised-shadow position-relative">
                            <div className="form-nav-end bg-secondary"></div>
                        </div>
                        <div className="col-lg-10 bg-diagonal-stripe">
                            <div className="row">
                                <div className="form-group col-lg-3">
                                    <label>Glass Code</label>
                                    <div className="form-control" disabled>{this.state.glassCode}</div>
                                </div>
                                <div className="form-group col-lg-3">
                                    <label>Form #</label>
                                    <div className="form-control" disabled>{this.state.formNumber}</div>
                                </div>
                                <div className="form-group col-lg-3">
                                    <label>Product Code</label>
                                    <div className="form-control" disabled>{this.state.productCode}</div>
                                </div>
                                <div className="form-group col-lg-3">
                                    <label>Control #</label>
                                    <div className="form-control" disabled>{this.state.controlNumber}</div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 bg-secondary px-0 pt-0 pb-5 raised-shadow position-relative">
                            <fieldset style={{ backgroundColor: "rgba(92,98,104,1)", padding: "15px 0", margin: "15px 0 1rem 1rem", borderRight: "none !important" }}>

                                {
                                    this.getCookie("access") === "Administrator" &&
                                    <div className={`btn position-relative text-left mb-3 ${this.state.status === "Setup" ? "btn-warning" : "btn-primary"}`} style={{ borderRadius: "0", width: "100%" }}>
                                        {
                                            // this.getCookie("access") === "Administrator" &&
                                            <button className="btn btn-clear p-0 text-white" onClick={() => { this.setState({ vSlider: !this.state.vSlider }) }}>
                                                {
                                                    this.state.vSlider &&
                                                    AccessibleIcon('fa-solid fa-caret-up ml-0 mr-3', 'Change Status')}
                                                {
                                                    !this.state.vSlider &&
                                                    AccessibleIcon('fa-solid fa-caret-down ml-0 mr-3', 'Change Status')}
                                                {this.displayStatus(this.state.status)}
                                            </button>
                                        }

                                        {
                                            this.getCookie("access") === "Administrator" &&
                                            <div className={`position-absolute bg-primary vSlider ${this.state.vSlider ? "" : "closed"}`} style={{ top: "40px", right: "0", minWidth: "150px", width: "100%", zIndex: "100" }}>
                                                <div className="m-2">
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("Pre-Production")}>Pre-Production</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("BatchRoom")}>Batch Room</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("Production")}>Production</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("Sorting")}>Sorting</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("Inspection")}>Inspection</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("QA")}>QA</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("GP")}>GP</button>
                                                    <button className="btn btn-sm btn-primary d-block" onClick={() => this.changeStatus("GrindRoom")}>Grind Room</button>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.getCookie("access") !== "Administrator" &&
                                    <div className={`btn position-relative text-left mb-3 pl-3 ${this.state.status === "Setup" ? "btn-warning" : "btn-primary"}`} style={{ borderRadius: "0", width: "100%" }}>
                                        {this.displayStatus(this.state.status)}
                                    </div>
                                }

                                <ul className="nav vNav flex-column">
                                    <li className="nav-item" onClick={() => this.showContent('topSheet')}>
                                        <span className={`nav-link ${this.state.topSheet.topSheetError ? "has-error" : ""} ${this.state.batchContent === "topSheet" ? "active" : ""}`}>Top Sheet</span>
                                    </li>
                                    {
                                        this.state.batchSheets.map((batchSheetRow, batchSheetIndex) => {
                                            return (
                                                <li key={batchSheetIndex} className="nav-item" onClick={() => this.showContent('batchSheet', batchSheetIndex)}>
                                                    <span className={`nav-link ${batchSheetRow.batchSheetError ? "has-error" : ""} ${this.state.batchContent === "batchSheet" && this.state.batchSheetIndex === batchSheetIndex ? "active" : ""}`}>Batch Sheet</span>
                                                </li>

                                            )
                                        })
                                    }
                                    <li className="nav-item" onClick={() => this.showContent('processSheet')}>
                                        <span className={`nav-link ${this.state.processSheet.processSheetError ? "has-error" : ""} ${this.state.batchContent === "processSheet" ? "active" : ""}`}>Process Sheet</span>
                                    </li>
                                    <li className="nav-item mb-5" onClick={() => this.showContent('qaSheet')}>
                                        <span className={`nav-link ${this.state.qaSheet.qaSheetError ? "has-error" : ""} ${this.state.batchContent === "qaSheet" ? "active" : ""}`}>Q/A Sheet</span>
                                    </li>

                                    {
                                        this.state.status !== "GP" && this.state.status !== "GrindRoom" && (((this.getCookie("deptCode")).indexOf(this.state.status) >= 0) || this.getCookie("access") === "Administrator") &&
                                        <li className="nav-item">
                                            <span className="nav-link">
                                                <button className="btn btn-success mr-2 fullWidth" disabled={!this.state.validForm} onClick={() => this.saveForm(true)}>Submit to {this.displayStatus(this.workflowNext())}</button>
                                            </span>
                                        </li>
                                    }

                                    {
                                        (this.state.status === "GP" && this.getCookie("access") === "Administrator") &&
                                        <>
                                            <li className="nav-item">
                                                <span className="nav-link">
                                                    <button className="btn btn-success mr-2 fullWidth" disabled={!this.state.validForm} onClick={() => this.saveForm(true)}>Submit to Grind Room</button>
                                                </span>
                                            </li>
                                        </>
                                    }

                                </ul>
                            </fieldset>

                            <ul className="nav vNav flex-column">
                                {
                                    this.state.status !== "GP" && this.state.status !== "GrindRoom" && (((this.getCookie("deptCode")).indexOf(this.state.status) >= 0) || this.getCookie("access") === "Administrator") &&
                                    <li className="nav-item">
                                        <span className="nav-link">
                                            <button className="btn btn-warning fullWidth" onClick={() => this.saveForm(false)}>Save for Later</button>

                                        </span>
                                    </li>
                                }
                                {
                                    (this.state.status === "GrindRoom" && this.getCookie("access") === "Administrator") &&
                                    <>
                                        <li className="nav-item">
                                            <span className="nav-link">
                                                <button className="btn btn-success mr-2 fullWidth" disabled={!this.state.validForm} onClick={() => this.saveForm(false)}>Update</button>
                                            </span>
                                        </li>
                                    </>
                                }

                                {
                                    (this.state.status === "GP" || this.state.status === "GrindRoom" || (((this.getCookie("deptCode")).indexOf(this.state.status) < 0) && this.getCookie("access") !== "Administrator")) &&
                                    <li className="nav-item">
                                        <span className="nav-link">
                                            <button className="btn btn-info fullWidth" onClick={() => this.props.toggleModal()}>Close</button>

                                        </span>
                                    </li>
                                }


                            </ul>

                            <div style={{ position: "absolute", bottom: "100px", padding: "15px" }}>
                                <button className="btn btn-sm btn-clear text-debug mr-3" onClick={() => console.log(this.state)}>Debug: Show State</button>
                                <button className="btn btn-sm btn-clear text-debug mr-3" onClick={() => this.validateForm()}>Debug: Validate</button>
                            </div>

                            <div className="form-nav-end bg-secondary">
                            </div>
                        </div>
                        <div className="col pb-5">

                            {
                                this.state.batchContent === 'topSheet' &&
                                <TopSheet
                                    status={this.state.status}
                                    verifyBatchPaperwork={this.state.verifyBatchPaperwork}
                                    disabledFields={this.state.disabledFields}
                                    topSheet={this.state.topSheet}
                                    lotNumber={this.state.lotNumber}
                                    handleChange={this.handleChange}
                                    handleArrayChange={this.handleArrayChange}
                                    sign={this.sign}
                                    signArray={this.signArray}
                                    addRow={this.addRow}
                                    deleteRow={this.deleteRow}
                                    sumArray={this.sumArray}
                                />
                            }
                            {
                                this.state.batchContent === 'batchSheet' &&
                                <BatchSheet
                                    status={this.state.status}
                                    disabledFields={this.state.disabledFields}
                                    batchSheet={this.state.batchSheets[this.state.batchSheetIndex]}
                                    batchSheetIndex={this.state.batchSheetIndex}
                                    handleChange={this.handleChange}
                                    handleArrayChange={this.handleArrayChange}
                                    updateArrayInArrayOfObjects={this.updateArrayInArrayOfObjects}
                                    updateFieldinArrayOfObjects={this.updateFieldinArrayOfObjects}
                                    signArrayInArrayOfObjects={this.signArrayInArrayOfObjects}
                                    signFieldinArrayOfObjects={this.signFieldinArrayOfObjects}
                                    sign={this.sign}
                                    signArray={this.signArray}
                                    addRow={this.addRow}
                                    deleteRow={this.deleteRow}
                                    splitRawMaterial={this.splitRawMaterial}
                                    deleteRawMaterial={this.deleteRawMaterial}
                                    sumArray={this.sumArray}
                                />
                            }
                            {
                                this.state.batchContent === 'processSheet' &&
                                <ProcessSheet
                                    status={this.state.status}
                                    disabledFields={this.state.disabledFields}
                                    processSheet={this.state.processSheet}
                                    handleChange={this.handleChange}
                                    handleArrayChange={this.handleArrayChange}
                                    sign={this.sign}
                                    signArray={this.signArray}
                                    addRow={this.addRow}
                                    deleteRow={this.deleteRow}
                                    sumArray={this.sumArray}
                                />
                            }
                            {
                                this.state.batchContent === 'qaSheet' &&
                                <QASheet
                                    status={this.state.status}
                                    disabledFields={this.state.disabledFields}
                                    qaSheet={this.state.qaSheet}
                                    topSheet={this.state.topSheet}
                                    lotNumber={this.state.lotNumber}
                                    handleChange={this.handleChange}
                                    handleArrayChange={this.handleArrayChange}
                                    sign={this.sign}
                                    signArray={this.signArray}
                                    addRow={this.addRow}
                                    deleteRow={this.deleteRow}
                                    sumArray={this.sumArray}
                                />
                            }

                        </div>
                    </div>
                </div>
            </main>
        )
    }

}

