import React from 'react'
import axios from 'axios'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { SERVER } from '../shared/Environment'
import Cookies from 'universal-cookie'

import BatchHistoryConfig from '../batchHistory/batchHistoryConfig'
import BatchHistoryForm from '../batchHistory/BatchHistoryForm'

import CBatchHistoryConfig from '../crucibleBatchHistory/CBatchHistoryConfig'
import CBatchHistoryForm from '../crucibleBatchHistory/CBatchHistoryForm'

const cookies = new Cookies()

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            departments: [],

            userId: 0,
            token: "",

            batchHistoryId: "0",
            showBatchHistoryConfig: false,
            showBatchHistoryForm: false,

            showCBatchHistoryConfig: false,
            showCBatchHistoryForm: false,

            batchHistoryList: [],
            activeForms: [],

            confirmDelete: false,

            glassCodeSearch: "",
            formNumberSearch: "",
            productCodeSearch: "",
            controlNumberSearch: "",
            lotNumberSearch: "",
        }
    }

    componentDidMount() {
        console.log("Dashboard -> getDepartments -> SERVER", SERVER)
        this.getToken()
        this.getDepartments()


        // console.log("Dashboard -> getToken -> this.getCookie(deptId)", this.getCookie("deptId"))

        this.getDashboardData()
    }

    getToken = () => {
        this.setState({
            userId: this.getCookie("userId"),
            token: this.getCookie("token"),
            deptId: this.getCookie("deptId"),
            deptCode: this.getCookie("deptCode")
        })
    }

    getCookie = (cookieName) => {
        return cookies.get(cookieName)
    }

    getDepartments = () => {
        if (cookies.get("token")) {
            axios.get(SERVER + '/api/departments/', {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistoryConfig -> getDepartments -> response", response)
                    this.setState({
                        departments: response.data,
                        // qcDeptId: (response.data.find(dept => dept.name === "Quality Control")).id
                    })
                })
        }
    }

    getDashboardData = () => {
        this.getActiveForms()
        this.getBatchHistoryList()
    }

    getBatchHistoryList = () => {
        const searchParam = {
            glassCode: this.state.glassCodeSearch,
            formNumber: this.state.formNumberSearch,
            productCode: this.state.productCodeSearch,
            controlNumber: this.state.controlNumberSearch,
            lotNumber: this.state.lotNumberSearch,
            limit: 10,
            pageNumber: 0
        }

        axios.post(SERVER + '/api/batchHistories/', searchParam, {
            headers: {
                userId: cookies.get("userId"),
                token: cookies.get("token")
            }
        })
            .then(response => {
                console.log("Dashboard -> getBatchHistoryList -> response.data", response.data)
                if (response.data) {
                    this.setState({
                        maxRows: response.data.maxRows,
                        batchHistoryList: response.data.batchHistories
                    })
                }

            })
            .catch(err => {
                console.log("BatchHistory -> handleSubmit -> err.response.status", err)
            })
    }

    getActiveForms = () => {
        console.log("Dashboard -> getActiveForms -> this.state.deptId", this.state.deptId)

        if (cookies.get("token")) {
            axios.post(SERVER + '/api/activeBatchHistories/', { deptCode: cookies.get("deptCode") }, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("Dashboard -> getActiveForms -> response.data", response.data)
                    if (response.data) {
                        this.setState({
                            activeForms: response.data
                        })
                    }

                })
                .catch(err => {
                    console.log("Dashboard -> getActiveForms -> err", err)
                })
        } else {
            window.location.reload()
        }
    }

    cardStatus = (status) => {
        switch (status) {
            case "Pending":
                return (
                    <span className="text-success small">
                        <i className="fa-solid fa-square-check me-2"></i>
                        Submitted
                    </span>
                )
            default:
                break
        }
    }

    toggleBatchHitory = () => {
        this.setState({
            showBatchHistoryConfig: !this.state.showBatchHistoryConfig
        })
    }

    batchHitoryConfig = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId,
            showBatchHistoryConfig: !this.state.showBatchHistoryConfig
        })
        if (event) {
            event.stopPropagation()
        }
    }

    toggleCBatchHitory = () => {
        this.setState({
            showCBatchHistoryConfig: !this.state.showCBatchHistoryConfig
        })
    }

    cBatchHitoryConfig = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId,
            showCBatchHistoryConfig: !this.state.showCBatchHistoryConfig
        })
        if (event) {
            event.stopPropagation()
        }
    }

    showBatchHistory = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId: batchHistoryId,
            showBatchHistoryForm: !this.state.showBatchHistoryForm
        })
        event.stopPropagation()
    }

    toggleBatchHistoryModal = () => {
        this.setState({
            showBatchHistoryForm: !this.state.showBatchHistoryForm
        }, () => this.getDashboardData())
    }

    showCBatchHistory = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId: batchHistoryId,
            showCBatchHistoryForm: !this.state.showCBatchHistoryForm
        })
        event.stopPropagation()
    }

    toggleCBatchHistoryModal = () => {
        this.setState({
            showCBatchHistoryForm: !this.state.showCBatchHistoryForm
        }, () => this.getDashboardData())
    }

    deleteBatchHitory = () => {
        if (cookies.get("token")) {
            axios.delete(SERVER + '/api/batchHistories/' + this.state.batchHistoryId, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("Dashboard -> deleteBatchHitory -> response.data", response.data)
                    if (response.data) {
                        this.setState({
                            batchHistoryId: 0,
                            confirmDelete: false
                        })
                        this.getDashboardData()
                    }

                })
                .catch(err => {
                    console.log("Dashboard -> deleteBatchHitory -> err", err)
                })


        } else {
            window.location.reload()
        }

    }

    toggleConfirmDelete = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId,
            confirmDelete: !this.state.confirmDelete
        })
        if (event) {
            event.stopPropagation()
        }
    }

    showDepartment = (deptId) => {
        if (deptId && deptId !== "") {
            return (this.state.departments.find(dept => dept.id === deptId)).name
        }
    }

    handleChange = (event) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value

        this.setState({
            [name]: value
        })
    }

    clearSearch = () => {
        this.setState({
            glassCodeSearch: "",
            formNumberSearch: "",
            productCodeSearch: "",
            controlNumberSearch: "",
            lotNumberSearch: "",
        }, () => {
            this.getBatchHistoryList()
        })
    }

    displayStatus = (value) => {
        switch (value) {
            case "BatchRoom":
                return ("Batch Room")
            case "GrindRoom":
                return ("Grind Room")
            default:
                return (value)
        }
    }

    displayDepartments = (dept) => {
        let deptList = dept.split(" ")
        let displayDept = deptList[0]
        for (let i = 1; i < deptList.length; i++) {
            displayDept += " + " + deptList[i]
        }
        return (displayDept)
    }

    displayFormType = (formType) => {
        if (formType === "BH") {
            return ("Batch History")
        } else if (formType === "CBH") {
            return ("Crucible Batch History")
        } else {
            return (formType)
        }
    }

    render() {
        return (
            <main>
                <header>
                    <div className="container-fluid mt-3">
                        <div className="row">
                            <div className="col-lg-4">
                                <h1 className='pgHeader'>Dashboard</h1>

                            </div>
                            <div className="col-lg-8 text-right">
                                {
                                    this.getCookie("access") === "Administrator" &&
                                    <>
                                        <button className="btn btn-sm btn-success mr-2" onClick={(e) => this.batchHitoryConfig(e, 0)}>{AccessibleIcon('fa-solid fa-plus', 'New')} Batch History</button>
                                        <button className="btn btn-sm btn-secondary mr-2" onClick={(e) => this.cBatchHitoryConfig(e, 0)}>{AccessibleIcon('fa-solid fa-plus', 'New')} Crucible Batch History</button>
                                        <button className="btn btn-sm btn-outline-primary mr-2 disabled">{AccessibleIcon('fa-solid fa-plus', 'New')} Web Grid Log</button>
                                        <button className="btn btn-sm btn-outline-info mr-2 disabled">{AccessibleIcon('fa-solid fa-plus', 'New')} Batch History</button>
                                    </>
                                }
                                <br /><button className="btn btn-sm btn-clear text-debug m-2" onClick={() => console.log(this.state)}>Debug: Show State</button>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid bg-info-light py-3 border border-top-secondary">
                    <h5 className="text-info">Active Forms in {this.displayDepartments(cookies.get("deptCode"))}</h5>

                    {/* <div className="row mb-2">
                        <div className="col-2">
                            <label htmlFor="showCompletedItems">
                                <input type="checkbox" className="m-2" name="showCompletedItems" id="showCompletedItems" />
                                Show Completed Items
                            </label>
                        </div>
                        <div className="col">
                            Sort By:
                            <label htmlFor="sortStatus">
                                <input type="radio" className="m-2" name="sortStatus" id="sortStatus" value="sortStatus" />
                                Status
                            </label>
                            <label htmlFor="sortSubmitBy">
                                <input type="radio" className="m-2" name="sortSubmitBy" id="sortSubmitBy" value="sortSubmitBy" />
                                Submit By
                            </label>
                            <label htmlFor="sortCreateDate">
                                <input type="radio" className="m-2" name="sortCreateDate" id="sortCreateDate" value="sortCreateDate" />
                                Create Date
                            </label>
                            <label htmlFor="sortUpdateDate">
                                <input type="radio" className="m-2" name="sortUpdateDate" id="sortUpdateDate" value="sortUpdateDate" />
                                Update Date
                            </label>
                        </div>
                        <div className="col">
                            Include:
                            <label htmlFor="periodToday">
                                <input type="radio" className="m-2" name="periodToday" id="periodToday" value="periodToday" />
                                Today
                            </label>
                            <label htmlFor="period2Days">
                                <input type="radio" className="m-2" name="period2Days" id="period2Days" value="period2Days" />
                                Today + Yesterday
                            </label>
                            <label htmlFor="period3Days">
                                <input type="radio" className="m-2" name="period3Days" id="period3Days" value="period3Days" />
                                3 Days
                            </label>
                            <label htmlFor="periodWeek">
                                <input type="radio" className="m-2" name="periodWeek" id="periodWeek" value="periodWeek" />
                                This Week
                            </label>
                        </div>
                    </div>
                    <hr className="m-1" />
                    <div className="form-row mt-0 mb-2 g-1">
                        <div className="col-lg-2 form-group">
                            <label htmlFor="filterGlassCode">Glass Code</label>
                            <input type="text" className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode} />
                        </div>
                        <div className="col-lg-2 form-group">
                            <label htmlFor="filterGlassCode">Form</label>
                            <input type="text" className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode} />
                        </div>
                        <div className="col-lg-1 form-group">
                            <label htmlFor="filterGlassCode">Product</label>
                            <input type="text" className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode} />
                        </div>
                        <div className="col-lg-2 form-group">
                            <label htmlFor="filterGlassCode">Control #</label>
                            <input type="text" className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode} />
                        </div>
                        <div className="col-lg-1 form-group">
                            <label htmlFor="filterGlassCode">Status</label>
                            <select className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode}>
                                <option value="">Select...</option>
                            </select>
                        </div>
                        <div className="col-lg-2 form-group">
                            <label htmlFor="filterGlassCode">Stage</label>
                            <select className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode}>
                                <option value="">Select...</option>
                            </select>
                        </div>
                        <div className="col-lg-1 form-group">
                            <label htmlFor="filterGlassCode">Type</label>
                            <select className="form-control form-control-sm nohighlight" id="filterGlassCode" name="filterGlassCode" value={this.state.filterGlassCode}>
                                <option value="">Select...</option>
                            </select>
                        </div>
                        <div className="col-lg-1 form-group bottomAlign text-end">
                            <button className="btn btn-sm btn-secondary">Apply</button>
                            <button className="btn btn-sm btn-clear text-danger">Clear</button>
                        </div>
                    </div> */}

                    <div className="row my-4">
                        {
                            this.state.activeForms.map((row, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-6 col-12">
                                        <div className="border bg-white mb-4">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div className="row">
                                                        <div className="col-9 cursor-pointer" onClick={row.status !== "Setup" ? ((e) => this.showBatchHistory(e, row.batchHistoryId)) : (e) => this.batchHitoryConfig(e, row.batchHistoryId)}>
                                                            <h6 className='text-success'>{this.displayFormType(row.formType)}</h6>
                                                        </div>

                                                        <div className="col-3 text-right">
                                                            {
                                                                row.status !== "Setup" &&
                                                                <button className='btn btn-clear m-0 p-0 text-secondary' onClick={(e) => this.batchHitoryConfig(e, row.batchHistoryId)}>{AccessibleIcon('fa-solid fa-gear', 'Setup')}</button>
                                                            }
                                                            <button className='btn btn-clear my-0 ml-2 p-0 text-secondary' onClick={(e) => this.toggleConfirmDelete(e, row.batchHistoryId)}>{AccessibleIcon('fa-regular fa-trash-can', 'Delete')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="row cursor-pointer" onClick={row.status !== "Setup" ? ((e) => this.showBatchHistory(e, row.batchHistoryId)) : (e) => this.batchHitoryConfig(e, row.batchHistoryId)}>
                                                    <div className="col-6">
                                                        <div className="small text-muted">Glass Code</div>
                                                        <h6>{row.glassCode}</h6>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="small text-muted">Form</div>
                                                        <h6>{row.formNumber}</h6>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="small text-muted">Product Code</div>
                                                        <h6>{row.productCode}</h6>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="small text-muted">Control No.</div>
                                                        <h6>{row.controlNumber}</h6>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="small text-muted">Lot #</div>
                                                        {row.lotNumber && row.lotNumber} {row.lotNumber2 && row.formType === "CBH" && " / " + row.lotNumber2}
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="small text-muted">Status</div>
                                                        <h6>{this.displayStatus(row.status)}</h6>
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <div className="small text-muted">Created/Updated</div>
                                                        {row.createBy} @ {row.lastUpdate ? moment(row.lastUpdate).format('MM/DD/YYYY h:mm a') : ""}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col mt-4">
                            <h5 className="text-primary">Recent Forms</h5>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="align-top">ID</th>
                                            <th className="align-top">Type</th>
                                            <th className="align-top">Batch 1 Status</th>
                                            <th className="align-top">Batch 2 Status</th>
                                            <th className="align-top">Created/Updated</th>
                                            <th className="align-top">Updated By</th>
                                            <th className="align-top">Glass Code
                                                <input type="text" className="form-control form-control-sm" id="glassCodeSearch" name="glassCodeSearch" value={this.state.glassCodeSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Lot #
                                                <input type="text" className="form-control form-control-sm" id="lotNumberSearch" name="lotNumberSearch" value={this.state.lotNumberSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Form #
                                                <input type="text" className="form-control form-control-sm" id="formNumberSearch" name="formNumberSearch" value={this.state.formNumberSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Product Code
                                                <input type="text" className="form-control form-control-sm" id="productCodeSearch" name="productCodeSearch" value={this.state.productCodeSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Control #
                                                <input type="text" className="form-control form-control-sm" id="controlNumberSearch" name="controlNumberSearch" value={this.state.controlNumberSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-bottom text-nowrap">
                                                <button className="btn btn-sm btn-success" style={{ marginBottom: "1px" }} onClick={this.getBatchHistoryList}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                <button className="btn btn-sm btn-clear text-danger" style={{ marginBottom: "1px" }} onClick={this.clearSearch}><i className="fa-solid fa-x"></i></button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.batchHistoryList.map((row, index) => {
                                                return (
                                                    <tr className="cursor-pointer" key={index} onClick={row.status !== "Setup" ? (row.formType === "BH" ? (e) => this.showBatchHistory(e, row.batchHistoryId) : (e) => this.showCBatchHistory(e, row.batchHistoryId)) : (row.formType === "BH" ? (e) => this.batchHitoryConfig(e, row.batchHistoryId) : (e) => this.cBatchHitoryConfig(e, row.batchHistoryId))}>
                                                        <td>{row.batchHistoryId}</td>
                                                        <td>{this.displayFormType(row.formType)}</td>
                                                        <td>{this.displayStatus(row.status)}</td>
                                                        <td>{row.formType === "CBH" && !row.batchSheets[0].halfBatch && this.displayStatus(row.status2)}</td>
                                                        <td>{row.lastUpdate ? moment(row.lastUpdate).format('MM/DD/YY h:mm a') : ""}</td>
                                                        <td>{row.createBy}</td>
                                                        <td>{row.glassCode}</td>
                                                        <td>{row.lotNumber && row.lotNumber} {row.lotNumber2 && row.formType === "CBH" && " / " + row.lotNumber2}</td>
                                                        <td>{row.formNumber}</td>
                                                        <td>{row.productCode}</td>
                                                        <td>{row.controlNumber}</td>
                                                        <td className="nowrap">
                                                            {
                                                                this.getCookie("access") === "Administrator" &&
                                                                <>
                                                                    <button className="btn btn-clear p-0 m-0" onClick={row.formType === "BH" ? (e) => this.batchHitoryConfig(e, row.batchHistoryId) : (e) => this.cBatchHitoryConfig(e, row.batchHistoryId)}>{AccessibleIcon('fa-solid fa-gear text-secondary', 'Setup')}
                                                                    </button>
                                                                    <button className="btn btn-clear p-0 mx-3 my-0" onClick={(e) => this.toggleConfirmDelete(e, row.batchHistoryId)}>{AccessibleIcon('fa-regular fa-trash-can text-secondary', 'Delete')}
                                                                    </button>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal show={this.state.showBatchHistoryConfig} onHide={(e) => this.batchHitoryConfig(e, this.state.batchHistoryId)}>
                    <Modal.Header className='bg-secondary'>
                        <Modal.Title>
                            <span className="text-white">Batch History Definition</span>
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={(e) => this.batchHitoryConfig(e, this.state.batchHistoryId)}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <BatchHistoryConfig
                            batchHistoryId={this.state.batchHistoryId}
                            toggleModal={this.toggleBatchHitory}
                            getDashboardData={this.getDashboardData}
                        />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showBatchHistoryForm} onHide={this.toggleBatchHistoryModal}>
                    <Modal.Header className='bg-secondary'>
                        <Modal.Title>

                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={this.toggleBatchHistoryModal}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <BatchHistoryForm
                            batchHistoryId={this.state.batchHistoryId}
                            toggleModal={this.toggleBatchHistoryModal}
                        />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showCBatchHistoryConfig} onHide={(e) => this.cBatchHitoryConfig(e, this.state.batchHistoryId)}>
                    <Modal.Header className='bg-secondary'>
                        <Modal.Title>
                            <span className="text-white">Crucible Batch History Definition</span>
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={(e) => this.cBatchHitoryConfig(e, this.state.batchHistoryId)}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <CBatchHistoryConfig
                            batchHistoryId={this.state.batchHistoryId}
                            toggleModal={this.toggleCBatchHitory}
                            getDashboardData={this.getDashboardData}
                        />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showCBatchHistoryForm} onHide={this.toggleCBatchHistoryModal}>
                    <Modal.Header className='bg-secondary'>
                        <Modal.Title>
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={this.toggleCBatchHistoryModal}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <CBatchHistoryForm
                            batchHistoryId={this.state.batchHistoryId}
                            toggleModal={this.toggleCBatchHistoryModal}
                        />
                    </Modal.Body>
                </Modal>

                <Modal className='modal-medium' show={this.state.confirmDelete} onHide={this.toggleConfirmDelete}>
                    <Modal.Header className='bg-secondary text-white'>
                        <Modal.Title>
                            Delete Confirmation
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={(e) => this.toggleConfirmDelete(e, 0)}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You're about to permanently delete this item, please confirm.</p>
                        <p>
                            <button className="btn btn-sm btn-danger mr-2" onClick={this.deleteBatchHitory}>Proceed</button>
                            <button className="btn btn-sm btn-clear mr-2" onClick={(e) => this.toggleConfirmDelete(e, 0)}>Cancel</button>
                        </p>
                    </Modal.Body>
                </Modal>
            </main>
        )
    }

}

export default Dashboard