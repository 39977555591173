import React from 'react'
import axios from 'axios'
import moment from 'moment'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { SERVER } from '../shared/Environment'
import CBatchHistoryForm from './CBatchHistoryForm'
import CBatchHistoryConfig from './CBatchHistoryConfig'
import { Modal } from 'react-bootstrap'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class BatchHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: 0,
            token: "",
            userIninitals: "",

            formType: "CBH",
            glassCodeSearch: "",
            formNumberSearch: "",
            productCodeSearch: "",
            controlNumberSearch: "",
            lotNumberSearch: "",

            batchHistoryList: [],
            batchHistoryId: "0",

            show: false,
            showModal: false,
            confirmDelete: false,

            pageLimit: 10,
            pageNumber: 0,
            pagingButtons: []

        }
    }

    componentDidMount() {
        this.getBatchHistoryList()
    }

    getCookie = (cookieName) => {
        return cookies.get(cookieName)
    }

    getBatchHistoryList = () => {
        const searchParam = {
            formType: this.state.formType,
            glassCode: this.state.glassCodeSearch,
            formNumber: this.state.formNumberSearch,
            productCode: this.state.productCodeSearch,
            controlNumber: this.state.controlNumberSearch,
            lotNumber: this.state.lotNumberSearch,
            limit: this.state.pageLimit,
            pageNumber: this.state.pageNumber
        }

        axios.post(SERVER + '/api/batchHistories/', searchParam, {
            headers: {
                userId: cookies.get("userId"),
                token: cookies.get("token")
            }
        })
            .then(response => {
                console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                if (response.data) {
                    this.setState({
                        maxRows: response.data.maxRows,
                        batchHistoryList: response.data.batchHistories
                    }, () => {
                        this.makePaging()
                    })
                }

            })
            .catch(err => {
                console.log("BatchHistory -> handleSubmit -> err.response.status", err)
            })
    }

    showBatchHistory = (batchHistoryId) => {
        this.setState({
            batchHistoryId: batchHistoryId,
            show: !this.state.show
        })

    }

    handleChange = (event) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value

        this.setState({
            [name]: value
        })
    }

    clearSearch = () => {
        this.setState({
            glassCodeSearch: "",
            formNumberSearch: "",
            productCodeSearch: "",
            controlNumberSearch: "",
            lotNumberSearch: "",
        }, () => {
            this.getBatchHistoryList()
        })
    }

    toggleModal = () => {
        this.setState({
            show: !this.state.show
        }, () => this.getBatchHistoryList())
    }

    batchHitoryConfig = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId,
            showModal: !this.state.showModal
        })
        if (event) {
            event.stopPropagation()
        }
    }

    toggleConfirmDelete = (event, batchHistoryId) => {
        this.setState({
            batchHistoryId,
            confirmDelete: !this.state.confirmDelete
        })
        if (event) {
            event.stopPropagation()
        }
    }

    deleteBatchHitory = () => {
        if (cookies.get("token")) {
            axios.delete(SERVER + '/api/batchHistories/' + this.state.batchHistoryId, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("Dashboard -> deleteBatchHitory -> response.data", response.data)
                    if (response.data) {
                        this.setState({
                            batchHistoryId: 0,
                            confirmDelete: false
                        })
                        this.getBatchHistoryList()
                    }

                })
                .catch(err => {
                    console.log("Dashboard -> deleteBatchHitory -> err", err)
                })


        } else {
            window.location.reload()
        }

    }

    setPage = (pageNumber) => {
        this.setState({
            pageNumber
        }, () => {
            this.getBatchHistoryList()
        })
    }

    makePaging = () => {
        let pagingButtons = []
        for (let i = 0; i < this.state.maxRows; i += this.state.pageLimit) {
            pagingButtons.push(
                <button key={i} className={`btn btn-sm mr-2 ${i / this.state.pageLimit === this.state.pageNumber ? "btn-primary" : "btn-light"} `} onClick={() => this.setPage(i / this.state.pageLimit)}>
                    {(i / this.state.pageLimit) + 1}
                </button>
            )
        }
        console.log("BatchHistory -> makePaging -> pagingButtons", pagingButtons)
        this.setState({
            pagingButtons
        })
    }

    // fixFormData = (event, batchHistoryId) => {
    //     event.stopPropagation()
    //     if (cookies.get("token")) {
    //         axios.get(SERVER + '/api/batchHistories/' + batchHistoryId, {
    //             headers: {
    //                 userId: cookies.get("userId"),
    //                 token: cookies.get("token")
    //             }
    //         })
    //             .then(response => {
    //                 console.log("BatchHistory -> fixFormData -> pre-fix -> response.data", response.data)
    //                 if (response.data) {
    //                     let status = response.data.status
    //                     let glassCode = response.data.glassCode
    //                     let formNumber = response.data.formNumber
    //                     let productCode = response.data.productCode
    //                     let controlNumber = response.data.controlNumber
    //                     let topSheet = response.data.topSheet
    //                     let batchSheets = response.data.batchSheets
    //                     let processSheet = response.data.processSheet
    //                     let qaSheet = response.data.qaSheet

    //                     let newBatchSheets = []
    //                     for (let batchSheetIndex = 0; batchSheetIndex < batchSheets.length; batchSheetIndex++) {
    //                         let batchSheet = batchSheets[batchSheetIndex]

    //                         console.log("BatchHistory -> fixFormData -> batchSheet.rawMaterial1", batchSheet.rawMaterial1)
    //                         console.log("BatchHistory -> fixFormData -> batchSheet.rawMaterial2", batchSheet.rawMaterial2)

    //                         if (batchSheet.rawMaterial1) {
    //                             batchSheet.hopperMaterial = batchSheet.rawMaterial1
    //                             batchSheet.containerMaterial = batchSheet.rawMaterial2
    //                             delete batchSheet.rawMaterial1
    //                             delete batchSheet.rawMaterial2
    //                             delete batchSheet.scaleWeight1
    //                             delete batchSheet.scaleWeight1Total
    //                             delete batchSheet.scaleWeight1Min
    //                             delete batchSheet.scaleWeight1Max
    //                             delete batchSheet.scaleWeight1Signature
    //                             delete batchSheet.scaleWeight2
    //                             delete batchSheet.scaleWeight2Total
    //                             delete batchSheet.scaleWeight2Min
    //                             delete batchSheet.scaleWeight2Max
    //                             delete batchSheet.scaleWeight2Signature
    //                             newBatchSheets.push(batchSheet)
    //                         }
    //                     }
    //                     console.log("BatchHistory -> fixFormData -> newBatchSheets", newBatchSheets)

    //                     const batchHistory = {
    //                         status: status,
    //                         submitForm: false,
    //                         glassCode: glassCode,
    //                         formNumber: formNumber,
    //                         productCode: productCode,
    //                         controlNumber: controlNumber,
    //                         topSheet: topSheet,
    //                         batchSheets: newBatchSheets,
    //                         processSheet: processSheet,
    //                         qaSheet: qaSheet,
    //                     }
    //                     console.log("BatchHistory -> fixFormData -> batchHistory", batchHistory)

    //                     axios.post(SERVER + '/api/batchHistories/' + batchHistoryId, batchHistory, {
    //                         headers: {
    //                             userId: cookies.get("userId"),
    //                             token: cookies.get("token")
    //                         }
    //                     })
    //                         .then(response => {
    //                             console.log("BatchHistory -> fixFormData -> response.data", response.data)
    //                             if (response.data) {
    //                                 // this.setState({
    //                                 //     batchHistoryId: response.data.batchHistoryId
    //                                 // })
    //                                 // this.props.toggleModal()
    //                             }

    //                         })
    //                         .catch(err => {
    //                             console.log("BatchHistory -> fixFormData -> err.response.status", err)
    //                         })

    //                 }

    //             })
    //             .catch(err => {
    //                 console.log("BatchHistory -> handleSubmit -> err.response.status", err)
    //             })
    //     } else {
    //         window.location.reload()
    //     }
    // }

    displayStatus = (value) => {
        switch (value) {
            case "BatchRoom":
                return ("Batch Room")
            case "GrindRoom":
                return ("Grind Room")
            default:
                return (value)
        }
    }

    render() {
        return (
            <main>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col">
                            <h1 className='pgHeader'>Crucible Batch History</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="align-top">ID</th>
                                            <th className="align-top">Created</th>
                                            <th className="align-top">Updated</th>
                                            <th className="align-top">Updated By</th>
                                            <th className="align-top">Batch 1 Status</th>
                                            <th className="align-top">Batch 2 Status</th>
                                            <th className="align-top">Glass Code
                                                <input type="text" className="form-control form-control-sm" id="glassCodeSearch" name="glassCodeSearch" value={this.state.glassCodeSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Lot #
                                                <input type="text" className="form-control form-control-sm" id="lotNumberSearch" name="lotNumberSearch" value={this.state.lotNumberSearch} onChange={this.handleChange} /></th>
                                            <th className="align-top">Form
                                                <input type="text" className="form-control form-control-sm" id="formNumberSearch" name="formNumberSearch" value={this.state.formNumberSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Product Code
                                                <input type="text" className="form-control form-control-sm" id="productCodeSearch" name="productCodeSearch" value={this.state.productCodeSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-top">Control #
                                                <input type="text" className="form-control form-control-sm" id="controlNumberSearch" name="controlNumberSearch" value={this.state.controlNumberSearch} onChange={this.handleChange} />
                                            </th>
                                            <th className="align-bottom text-nowrap">
                                                <button className="btn btn-sm btn-success" style={{ marginBottom: "1px" }} onClick={this.getBatchHistoryList}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                <button className="btn btn-sm btn-clear text-danger" style={{ marginBottom: "1px" }} onClick={this.clearSearch}><i className="fa-solid fa-x"></i></button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.batchHistoryList.map((row, index) => {
                                                return (
                                                    <tr key={index} onClick={row.status !== "Setup" ? (() => this.showBatchHistory(row.batchHistoryId)) : (e) => this.batchHitoryConfig(e, row.batchHistoryId)} className='cursor-pointer'>
                                                        <td>{row.batchHistoryId}</td>
                                                        <td>{row.createDate ? moment(row.createDate).format('MM/DD/YY h:mm a') : ""}</td>
                                                        <td>{row.lastUpdate ? moment(row.lastUpdate).format('MM/DD/YY h:mm a') : ""}</td>
                                                        <td>{row.createBy}</td>
                                                        <td>{this.displayStatus(row.status)}</td>
                                                        <td>{!row.batchSheets[0].halfBatch && this.displayStatus(row.status2)}</td>
                                                        <td>{row.glassCode}</td>
                                                        <td>{row.lotNumber && row.lotNumber} {row.lotNumber2 && row.formType === "CBH" && " / " + row.lotNumber2}</td>
                                                        <td>{row.formNumber}</td>
                                                        <td>{row.productCode}</td>
                                                        <td>{row.controlNumber}</td>
                                                        <td className="nowrap">
                                                            {
                                                                this.getCookie("access") === "Administrator" &&
                                                                <>
                                                                    <button className="btn btn-clear p-0 m-0" onClick={(e) => this.batchHitoryConfig(e, row.batchHistoryId)}>{AccessibleIcon('fa-solid fa-gear text-secondary', 'Setup')}
                                                                    </button>
                                                                    <button className="btn btn-clear p-0 mx-3 my-0" onClick={(e) => this.toggleConfirmDelete(e, row.batchHistoryId)}>{AccessibleIcon('fa-regular fa-trash-can text-secondary', 'Delete')}
                                                                    </button>
                                                                    {/* <button className="btn btn-clear p-0 mx-3 my-0" onClick={(e) => this.fixFormData(e, row.batchHistoryId)}>{AccessibleIcon('fa-solid fa-screwdriver-wrench text-danger', 'Fix JSON')}
                                                                    </button> */}
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 text-right">
                            {this.state.pagingButtons}
                        </div>
                    </div>

                </div>

                <Modal show={this.state.show} onHide={this.toggleModal}>
                    <Modal.Header className='bg-secondary'>
                        <Modal.Title>{
                            // this.state.batchHistoryId !== "0" ? "Batch History ID " + this.state.batchHistoryId : "New Batch History"
                        }
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={this.toggleModal}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <CBatchHistoryForm
                            batchHistoryId={this.state.batchHistoryId}
                            toggleModal={this.toggleModal}
                        />
                    </Modal.Body>
                </Modal>

                <Modal className='modal-medium' show={this.state.confirmDelete} onHide={this.toggleConfirmDelete}>
                    <Modal.Header className='bg-secondary text-white'>
                        <Modal.Title>
                            Delete Confirmation
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={(e) => this.toggleConfirmDelete(e, 0)}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You're about to permanently delete this item, please confirm.</p>
                        <p>
                            <button className="btn btn-sm btn-danger mr-2" onClick={this.deleteBatchHitory}>Proceed</button>
                            <button className="btn btn-sm btn-clear mr-2" onClick={(e) => this.toggleConfirmDelete(e, 0)}>Cancel</button>
                        </p>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showModal} onHide={(e) => this.batchHitoryConfig(e, this.state.batchHistoryId)}>
                    <Modal.Header className='bg-secondary'>
                        <Modal.Title>
                            <span className="text-white">Crucible Batch History Definition</span>
                        </Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={(e) => this.batchHitoryConfig(e, this.state.batchHistoryId)}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <CBatchHistoryConfig
                            batchHistoryId={this.state.batchHistoryId}
                            toggleModal={this.batchHitoryConfig}
                            getDashboardData={this.getBatchHistoryList}
                        />
                    </Modal.Body>
                </Modal>

            </main>
        )
    }

}

export default BatchHistory