import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/SGIform.css'
import '../node_modules/text-security/text-security.css'
import Login from './login/Login'
import Dashboard from './dashboard/Dashboard'
import BatchHistory from './batchHistory/BatchHistory'
import CBatchHistory from './crucibleBatchHistory/CBatchHistory'
import Users from './users/Users'
import { SERVER } from './shared/Environment'

import { AccessibleIcon } from './shared/AccessibleIcon'
import Cookies from 'universal-cookie'
import RawMaterial from './listAdmin/RawMaterial'
const cookies = new Cookies()

// import BatchHistory from './batchHistory/BatchHistory'

export class App extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            userId: 0,
            initials: "",
            token: "",
            route: "dashboard",

            topNav: false,
        }
    }

    componentDidMount() {
        this.getToken()
        console.log("App -> componentDidMount -> document.location.href", document.location.href)
        console.log("App -> componentDidMount -> document.location.pathname", document.location.pathname)
    }

    logout = () => {
        console.log("App -> logout -> logout")
        cookies.remove("userId")
        cookies.remove("token")
        cookies.remove("initials")
        cookies.remove("deptId")
        cookies.remove("deptCode")
        cookies.remove("access")
        this.setState({
            userId: null,
            token: null,
            initials: null,
            deptId: null,
            deptCode: null,
            access: null,
        }, () => document.location.pathname = '')
    }

    getToken = () => {
        this.setState({
            userId: cookies.get("userId"),
            initials: cookies.get("initials"),
            token: cookies.get("token"),
            deptId: cookies.get("deptId"),
            deptCode: cookies.get("deptCode"),
            access: cookies.get("access"),
        })
    }

    getCookie = (cookieName) => {
        return cookies.get(cookieName)
    }

    redirect = (route) => {
        this.setState({
            route,
        })
        // document.location.pathname = '/' + route
    }

    render() {
        return (
            <div className='pb-5'>
                {
                    cookies.get("token") === undefined &&
                    <Login
                        getToken={this.getToken}
                        redirect={this.redirect}
                    />
                }
                {
                    cookies.get("token") !== undefined &&
                    <>
                        <nav className="navbar navbar-expand-lg navbar-dark">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="/dasbhoard"><img alt="Specialty Glass" src="/SG-logo.png" /></a>
                                <button className="navbar-toggler" type="button" aria-controls="topNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { this.setState({ topNav: !this.state.topNav }) }}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#topNav" aria-controls="topNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button> */}

                                <div className={`collapse navbar-collapse ${this.state.topNav ? "show" : ""}`} id="topNav">
                                    <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <button className="btn btn-clear nav-link" onClick={() => this.redirect('dashboard')}>Dashboard</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="btn btn-clear nav-link" onClick={() => this.redirect('batchHistory')}>Batch History</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="btn btn-clear nav-link" onClick={() => this.redirect('crucibleBatchHistory')}>Crucible Batch History</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="btn btn-clear nav-link" onClick={() => this.redirect('grindingLog')} disabled>Grinding Log</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="btn btn-clear nav-link" onClick={() => this.redirect('wetGrindLog')} disabled>Wet Grind Log</button>
                                        </li>
                                        {
                                            this.getCookie("access") === "Administrator" &&
                                            <>
                                                <li className="nav-item">
                                                    <button className="btn btn-clear nav-link" onClick={() => this.redirect('users')}>Users</button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className="btn btn-clear nav-link" onClick={() => this.redirect('rawMaterial')}>Raw Material</button>
                                                </li>
                                            </>
                                        }
                                        {/* <li className="nav-item dropdown">
                                            <button className="btn btn-clear nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Admin
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><a className="dropdown-item" href="/users">Users</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="/departments">Departments</a></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                    <div className="ml-auto mb-2 mb-lg-0">
                                        <span className="navUserInfo">
                                            <span className="mr-3" style={{ fontSize: "130%" }}>
                                                <i className={`${this.getCookie("access") === "Administrator" ? "fa-solid fa-user-shield" : "fa-regular fa-circle-user"}`}></i>
                                            </span>
                                            <span className="mr-2">
                                                {cookies.get("userName")}
                                                {/* ({this.getCookie("access")}) */}
                                            </span>
                                            {/* {cookies.get("deptCode")} */}
                                        </span>

                                        <button className="btn btn-clear text-white m-0 p-0" onClick={this.logout}>
                                            {AccessibleIcon('fa-solid fa-arrow-right-from-bracket', 'Logout')}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </nav>
                    </>
                }
                {
                    cookies.get("token") !== undefined && (this.state.route === 'dashboard') &&
                    <Dashboard />
                }
                {
                    cookies.get("token") !== undefined && this.state.route === 'batchHistory' &&
                    <BatchHistory />
                }
                {
                    cookies.get("token") !== undefined && this.state.route === 'crucibleBatchHistory' &&
                    <CBatchHistory />
                }
                {
                    cookies.get("token") !== undefined && this.state.route === 'users' &&
                    <Users />
                }
                {
                    cookies.get("token") !== undefined && this.state.route === 'rawMaterial' &&
                    <RawMaterial />
                }

                <div className="container-fluid bg-light fixed-bottom">
                    <div className="row">
                        <div className="col-lg-12 text-right text-debug p-3">
                            {SERVER}
                        </div>
                    </div>
                </div>
            </div>


        )
    }

}

export default App;
