import React from 'react'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { showSignature } from '../shared/SharedFunctions'

export default class BatchSheet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    errorClass(objName, fieldName) {
        if (objName !== "") {
            if (this.props[objName][fieldName + "Error"] !== "") {
                return (this.props[objName][fieldName + "Error"] === "" ? '' : 'has-error');
            }
        } else {
            if (this.props[fieldName + "Error"] !== undefined) {
                return (this.props[fieldName + "Error"] === 0 ? '' : 'has-error');
            }
        }
    }

    errorClass2(value) {
        return (value === "" || value === undefined ? '' : 'has-error')
    }

    render() {
        return (
            <div className="container-fluid mt-3 pb-2">
                <div className="row">
                    <div className="col-lg-10">
                        <h3 className='pgHeader'>Batch Sheet</h3>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12 text-center">
                        <h6 className='mb-0'>Batch Making # WIBM-002</h6>
                        *** Target Weight is Bolded ***
                    </div>
                </div>

                <div className="row mt-5 mb-3">
                    <div className={`col-4`}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className={`input-group-text ${this.errorClass2(this.props.batchSheet.hopperWeightError)}`}>Hopper Weight</span>
                            </div>
                            <input type="number" className="form-control batchField" name="hopperWeight" id="hopperWeight" value={this.props.batchSheet.hopperWeight} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                        </div>
                    </div>
                </div>

                {
                    this.props.batchSheet.hopperMaterial.map((row, index) => {
                        return (
                            <div key={index} className="row mt-2">
                                <div className={`col-lg-2 ${this.errorClass2(row.materialError)}`}>
                                    <label>Raw Material</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="material" id="material" value={row.material} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "hopperMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-lg-3 ${this.errorClass2(row.lbsError)}`}>
                                    <label>#2 Lbs</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="lbs" id="lbs" value={row.lbs} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "hopperMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-lg-2 ${this.errorClass2(row.actualLbsError)}`}>
                                    <label>#2 Actual</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control batchField" name="actualLbs" id="actualLbs" value={row.actualLbs} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "hopperMaterial", index)} />
                                        <div className="input-group-append">
                                            {
                                                row.lbs !== "Split" &&
                                                <button className="input-group-text" disabled={this.props.disabledFields.batchField} onClick={() => this.props.splitRawMaterial("batchSheets", this.props.batchSheetIndex, "hopperMaterial", index)}>
                                                    {AccessibleIcon('fa-regular fa-clone', 'Split Raw Material Entry')}
                                                </button>
                                            }
                                            {
                                                row.lbs === "Split" &&
                                                <button className="input-group-text" disabled={this.props.disabledFields.batchField} onClick={() => this.props.deleteRawMaterial("batchSheets", this.props.batchSheetIndex, "hopperMaterial", index)}>
                                                    {AccessibleIcon("fa-solid fa-trash-can", "Delete Raw Material Entry")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-lg-2 ${this.errorClass2(row.RNError)}`}>
                                    <label>RN #</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control batchField" name="RN" id="RN" value={row.RN} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "hopperMaterial", index)} />
                                    </div>
                                </div>
                                <div className="col-lg-3 bottomAlign">
                                    <div className="input-group">
                                        <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                            {showSignature(row.signature)}
                                        </div>
                                        <div className="input-group-append">
                                            <button className={`input-group-text ${this.errorClass2(row.signatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "hopperMaterial", index, "signature")}>
                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    !this.props.batchSheet.noContainer &&
                    <>
                        <hr className='my-5' />
                        <div className="row mb-2">
                            <div className={`col-4`}>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.batchSheet.containerWeightError)}`}>
                                            Container Weight</span>
                                    </div>
                                    <input type="number" className="form-control batchField" name="containerWeight" id="containerWeight" value={this.props.batchSheet.containerWeight} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                                </div>
                            </div>
                        </div>
                    </>
                }

                {
                    !this.props.batchSheet.noContainer &&
                    this.props.batchSheet.containerMaterial.map((row, index) => {
                        return (
                            <div key={index} className="row mt-2">
                                <div className={`col-2 ${this.errorClass2(row.materialError)}`}>
                                    <label>Raw Material</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="material" id="material" value={row.material} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "containerMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-3 ${this.errorClass2(row.lbsError)}`}>
                                    <label>#1 Lbs</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="lbs" id="lbs" value={row.lbs} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "containerMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-2 ${this.errorClass2(row.actualLbsError)}`}>
                                    <label>#1 Actual</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control batchField" name="actualLbs" id="actualLbs" value={row.actualLbs} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "containerMaterial", index)} />
                                        <div className="input-group-append">
                                            {
                                                row.lbs !== "Split" &&
                                                <button className="input-group-text" disabled={this.props.disabledFields.batchField} onClick={() => this.props.splitRawMaterial("batchSheets", this.props.batchSheetIndex, "containerMaterial", index)}>
                                                    {AccessibleIcon('fa-regular fa-clone', 'Split Raw Material Entry')}
                                                </button>
                                            }
                                            {
                                                row.lbs === "Split" &&
                                                <button className="input-group-text" disabled={this.props.disabledFields.batchField} onClick={() => this.props.deleteRawMaterial("batchSheets", this.props.batchSheetIndex, "containerMaterial", index)}>
                                                    {AccessibleIcon("fa-solid fa-trash-can", "Delete Raw Material Entry")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-2 ${this.errorClass2(row.RNError)}`}>
                                    <label>RN #</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control batchField" name="RN" id="RN" value={row.RN} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "containerMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-3 bottomAlign`}>
                                    <div className="input-group">
                                        <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                            {showSignature(row.signature)}
                                        </div>
                                        <div className="input-group-append">
                                            <button className={`input-group-text ${this.errorClass2(row.signatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "containerMaterial", index, "signature")}>
                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    !this.props.batchSheet.noSmallMelt &&
                    <>
                        <hr className='my-5' />
                        <div className="row mb-2">
                            <div className={`col-4`}>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.batchSheet.smallMeltWeightError)}`}>
                                            Small Melt Weight</span>
                                    </div>
                                    <input type="number" className="form-control batchField" name="smallMeltWeight" id="smallMeltWeight" value={this.props.batchSheet.smallMeltWeight} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                                </div>
                            </div>
                        </div>
                    </>
                }

                {
                    !this.props.batchSheet.noSmallMelt &&
                    this.props.batchSheet.smallMeltMaterial.map((row, index) => {
                        return (
                            <div key={index} className="row mt-2">
                                <div className={`col-2 ${this.errorClass2(row.materialError)}`}>
                                    <label>Raw Material</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="material" id="material" value={row.material} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-3 ${this.errorClass2(row.lbsError)}`}>
                                    <label>#1 Lbs</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="lbs" id="lbs" value={row.lbs} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-2 ${this.errorClass2(row.actualLbsError)}`}>
                                    <label>#1 Actual</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control batchField" name="actualLbs" id="actualLbs" value={row.actualLbs} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index)} />
                                        <div className="input-group-append">
                                            {
                                                row.lbs !== "Split" &&
                                                <button className="input-group-text" disabled={this.props.disabledFields.batchField} onClick={() => this.props.splitRawMaterial("batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index)}>
                                                    {AccessibleIcon('fa-regular fa-clone', 'Split Raw Material Entry')}
                                                </button>
                                            }
                                            {
                                                row.lbs === "Split" &&
                                                <button className="input-group-text" disabled={this.props.disabledFields.batchField} onClick={() => this.props.deleteRawMaterial("batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index)}>
                                                    {AccessibleIcon("fa-solid fa-trash-can", "Delete Raw Material Entry")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-2 ${this.errorClass2(row.RNError)}`}>
                                    <label>RN #</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control batchField" name="RN" id="RN" value={row.RN} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index)} />
                                    </div>
                                </div>
                                <div className={`col-3 bottomAlign`}>
                                    <div className="input-group">
                                        <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                            {showSignature(row.signature)}
                                        </div>
                                        <div className="input-group-append">
                                            <button className={`input-group-text ${this.errorClass2(row.signatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signArrayInArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "smallMeltMaterial", index, "signature")}>
                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    !this.props.batchSheet.noSmallMelt &&
                    <div className="form-row mt-3">
                        <div className="col-lg-9">
                            <label>Instructions</label>
                            <div className="form-control qaField">{this.props.batchSheet.smallMeltNotes}</div>
                        </div>
                    </div>
                }

                {
                    !this.props.batchSheet.noSmallMelt &&
                    <div className="row mt-4">
                        <div className="col">
                            <div className="input-group">
                                <span className={`input-group-text no-right-border`}>Blend Time Start</span>
                                <input type="text" className="form-control batchField" name="blendTimeStart" id="blendTimeStart" aria-label="Verify Total Batch Weight" value={this.props.batchSheet.blendTimeStart} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                                <span className={`input-group-text no-left-border no-right-border ${this.errorClass2(this.props.batchSheet.blendTimeEndError)}`}>Blend Time End</span>
                                <input type="text" className="form-control batchField" name="blendTimeEnd" id="blendTimeEnd" aria-label="Verify Total Batch Weight" value={this.props.batchSheet.blendTimeEnd} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="input-group">
                                <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                    {showSignature(this.props.batchSheet.blendTimeSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.blendTimeSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "blendTimeSignature")}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                <hr className='my-5' />
                <div className="row">
                    <div className={`col`}>
                        <div className="input-group">
                            <span className={`input-group-text no-right-border`}>
                                Scale Weight #2
                            </span>
                            <input type="number" className="form-control batchField" aria-label="Scale Weight #2" value={(parseFloat(this.props.batchSheet.hopperWeight) + parseFloat(this.props.batchSheet.hopperWeightTotal)).toFixed(2)} disabled />
                            <span className="input-group-text no-left-border no-right-border">&ndash; {this.props.batchSheet.hopperWeight} (Hopper Weight) = </span>
                            <input type="number" className="form-control batchField" value={(parseFloat(this.props.batchSheet.hopperWeightTotal)).toFixed(2)} disabled />
                            <span className={`input-group-text no-right-border ${this.errorClass2(this.props.batchSheet.hopperWeightTotalError)}`}>(Total)</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="input-group">
                            <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                {showSignature(this.props.batchSheet.hopperWeightSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.hopperWeightSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "hopperWeightSignature")}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !this.props.batchSheet.noContainer &&
                    <div className="row mt-3">
                        <div className={`col`}>
                            <div className="input-group">
                                <span className={`input-group-text no-right-border`}>
                                    Scale Weight #1
                                </span>
                                <input type="number" className="form-control batchField" aria-label="Scale Weight #2" value={(parseFloat(this.props.batchSheet.containerWeight) + parseFloat(this.props.batchSheet.containerWeightTotal)).toFixed(2)} disabled />
                                <span className="input-group-text no-left-border no-right-border">&ndash; {this.props.batchSheet.containerWeight} (Container Weight) = </span>
                                <input type="number" className="form-control batchField" value={(parseFloat(this.props.batchSheet.containerWeightTotal)).toFixed(2)} disabled />
                                <span className={`input-group-text no-right-border`}>(Total)</span>

                            </div>
                        </div>
                        <div className="col-3">
                            <div className="input-group">
                                <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                    {showSignature(this.props.batchSheet.containerWeightSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.containerWeightSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "containerWeightSignature")}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    !this.props.batchSheet.noSmallMelt &&
                    <div className="row mt-3">
                        <div className={`col`}>
                            <div className="input-group">
                                <span className={`input-group-text no-right-border`}>
                                    Small Melt Scale
                                </span>
                                <input type="number" className="form-control batchField" aria-label="Scale Weight #2" value={(parseFloat(this.props.batchSheet.smallMeltWeight) + parseFloat(this.props.batchSheet.smallMeltWeightTotal)).toFixed(2)} disabled />
                                <span className="input-group-text no-left-border no-right-border">&ndash; {this.props.batchSheet.smallMeltWeight} (Small Melt Weight) = </span>
                                <input type="number" className="form-control batchField" value={(parseFloat(this.props.batchSheet.smallMeltWeightTotal)).toFixed(2)} disabled />
                                <span className={`input-group-text no-right-border`}>(Total)</span>

                            </div>
                        </div>
                        <div className="col-3">
                            <div className="input-group">
                                <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                    {showSignature(this.props.batchSheet.smallMeltWeightSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.smallMeltWeightSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "smallMeltWeightSignature")}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="row my-4">
                    <div className="col">
                        <div className="input-group">
                            <span className="input-group-text no-right-border">Verify Total Batch Weight</span>
                            <input type="text" className="form-control qaField" style={{ minWidth: "250px" }} name="verifyTotalBatchWeight" id="verifyTotalBatchWeight" aria-label="Verify Total Batch Weight" value={this.props.batchSheet.verifyTotalBatchWeight} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                            <span className={`input-group-text no-left-border no-right-border`}>(</span>
                            <input type="text" className="form-control batchField" value={(parseFloat(this.props.batchSheet.hopperWeightTotal) + parseFloat(this.props.batchSheet.containerWeightTotal) + parseFloat(this.props.batchSheet.smallMeltWeightTotal)).toFixed(2)} disabled />
                            <span className="input-group-text no-left-border"># )</span>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="input-group">
                            <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                {showSignature(this.props.batchSheet.verifyTotalBatchWeightSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.verifyTotalBatchWeightSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "verifyTotalBatchWeightSignature")}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='my-5' />
                <div className="row">
                    <div className="col">
                        <label htmlFor="vBlenderSignature">All Raw Materials Added to V-blender and Blended </label>
                    </div>
                    <div className="col-3">
                        <div className="input-group">
                            <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                {showSignature(this.props.batchSheet.vBlenderSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.vBlenderSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "vBlenderSignature")}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='my-5' />
                <div className="row">
                    <div className="col">
                        <label htmlFor="batchBucketSignature">Divide and Place in Labeled Batch Buckets</label>
                    </div>
                    <div className="col-3">
                        <div className="input-group">
                            <div className="form-control batchField" disabled={this.props.disabledFields.batchField}>
                                {showSignature(this.props.batchSheet.batchBucketSignature)}
                            </div>
                            <div className="input-group-append">
                                <button className={`input-group-text ${this.errorClass2(this.props.batchSheet.batchBucketSignatureError)}`} disabled={this.props.disabledFields.batchField} onClick={(e) => this.props.signFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex, "", "", "batchBucketSignature")}>
                                    {AccessibleIcon('fas fa-signature', 'Intial')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='my-5' />
                <div className="row">
                    <div className={`col-3 ${this.errorClass("batchSheet", "pailWeight1")}`}>
                        <label htmlFor="pailWeight1">Pail 1 Weight</label>
                        <input type="text" className="form-control batchField" name="pailWeight1" id="pailWeight1" value={this.props.batchSheet.pailWeight1} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                        <div><span className="h5 mr-2">{this.props.batchSheet.targetPailWeight1}</span> +/- 1#</div>
                    </div>
                    {
                        this.props.batchSheet.targetPailWeight2 !== "" &&
                        <div className={`col-3 ${this.errorClass("batchSheet", "pailWeight2")}`}>
                            <label htmlFor="pailWeight2">Pail 2 Weight</label>
                            <input type="text" className="form-control batchField" name="pailWeight2" id="pailWeight2" value={this.props.batchSheet.pailWeight2} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                            <div><span className="h5 mr-2">{this.props.batchSheet.targetPailWeight2}</span> +/- 1#</div>
                        </div>
                    }
                    {
                        this.props.batchSheet.targetPailWeight3 !== "" &&
                        <div className={`col-3 ${this.errorClass("batchSheet", "pailWeight3")}`}>
                            <label htmlFor="pailWeight3">Pail 3 Weight</label>
                            <input type="text" className="form-control batchField" name="pailWeight3" id="pailWeight3" value={this.props.batchSheet.pailWeight3} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                            <div><span className="h5 mr-2">{this.props.batchSheet.targetPailWeight3}</span> +/- 1#</div>
                        </div>
                    }
                    {
                        this.props.batchSheet.targetPailWeight4 !== "" &&
                        <div className={`col-3 ${this.errorClass("batchSheet", "pailWeight4")}`}>
                            <label htmlFor="pailWeight4">Pail 4 Weight</label>
                            <input type="text" className="form-control batchField" name="pailWeight4" id="pailWeight4" value={this.props.batchSheet.pailWeight4} disabled={this.props.disabledFields.batchField} onChange={(e) => this.props.updateFieldinArrayOfObjects(e, "batchSheets", this.props.batchSheetIndex)} />
                            <div><span className="h5 mr-2">{this.props.batchSheet.targetPailWeight4}</span> +/- 1#</div>
                        </div>
                    }
                </div>

                <div className="fullBleed bg-light border mt-5">
                    <div className="btn border mr-3 batchField">Batch Room</div>
                    <div className="btn border mr-3 productionField">Production</div>
                    <div className="btn border mr-3 qaField">QA</div>
                    <div className="btn border mr-3 sortingField">Sorting</div>
                    <div className="btn border mr-3 inspectionField">Inspection</div>
                    <div className="btn border mr-3 gpField">GP</div>
                    <div className="btn border mr-3 grindingField">Grind Room</div>
                </div>

            </div> // container-fluid
        )
    }
}