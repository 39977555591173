export const TOPSHEET = {
    verifyBatchPaperworkSignature: [],
    fritWeight: "",
    thermalTestingAccept: "",
    thermalTestingSignature: [],
    indexRefractionAccept: "",
    indexRefractionSignature: [],
    fiberSofteningAccept: "",
    fiberSofteningSignature: [],
    fiberSofteningNotes: "",

    retentionSampleTakenSignature: [],

    visualCheck: "",
    visualCheckSignature: [],

    fritWeightBeforeSortingSignature: [],
    sortingWipr029Signature: [],

    fritWeights: [
        {
            fritWeight: 0,
            fritWeightSignature: [],
            verifyWeight: 0,
            verifyWeightSignature: [],
        },
    ],

    fritWeightTotalSignature: [],

    verifyWeightTotalSignature: [],

    fritScreeningNA: false,
    fritScreeningWipr026Signature: [],

    sampleInspection: "",
    sampleInspectionSignature: [],

    noPailWeights: false,
    pailweightOperator: "",
    pailweightVerifier: "",
    pailWeights: [
        {
            tank: "",
            weight: 0,
            adjustment: 0,
            verifyWeight: 0,
            pailCheck: false
        }
    ],

    totalPailWeightSignature: [],
    totalVerifiedWeightSignature: [],

    inspectionAccept: "",
    inspectionSignature: [],

    distributionHistory: [
        {
            pounds: "",
            lot: "",
            control: "",
            grindDate: "",
            mill: "",
        }
    ],

    gpSignature: [],
    grindRoomSignature: [],
}

export const BATCHSHEET = {
    hopperWeight: 0,
    hopperMaterial: [
        {
            material: "",
            lbs: "",
            actualLbs: 0,
            RN: "",
            signature: []
        },
    ],

    noContainer: false,
    containerWeight: 0,
    containerMaterial: [
        {
            material: "",
            lbs: "",
            actualLbs: 0,
            RN: "",
            signature: []
        }
    ],

    noSmallMelt: true,
    smallMeltWeight: 0,
    smallMeltMaterial: [
        {
            material: "",
            lbs: "",
            actualLbs: 0,
            RN: "",
            signature: []
        }
    ],
    smallMeltNotes: "",
    blendTimeStart: "",
    blendTimeEnd: "",
    blendTimeSignature: [],

    hopperWeightTotal: 0,
    hopperWeightSignature: [],

    containerWeightTotal: 0,
    containerWeightSignature: [],

    smallMeltWeightTotal: 0,
    smallMeltWeightSignature: [],

    verifyTotalBatchWeight: "",
    verifyTotalBatchWeightSignature: [],

    vBlenderSignature: [],
    batchBucketSignature: [],

    halfBatch: false,

    targetPailWeight1: "",
    targetPailWeight2: "",
    targetPailWeight3: "",
    targetPailWeight4: "",

    pailWeight1: "",
    pailWeight2: "",
    pailWeight3: "",
    pailWeight4: "",
}

export const PROCESSSHEET = {
    batching: "",
    batchingGrid: [
        {
            batchingTemp: "",
            batchingTime: "",
            batchingScooped: "",
            batchingSignature: [],
        },
    ],
    batchingIn: "",
    batchingLeft: "",

    melting: "",
    meltingCycle: "",
    meltingCycleSignature: [],
    meltingCycleConfirmSignature: [],

    draining: "",
    drainingTemp: "",
    drainingTime: "",
    drainingSignature: [],
    drainingFrit: "",
    drainingFritSignature: [],

    inspectionBeforeBatching: {
        visible: false,
        controllerCheck: "",
        welderAmperage: "",
        properBatchArea: "",
        crucibleConditions: "",
        readingTime: "",
        readingTimeSignature: [],
        tc: false,
        tc2: false,
        burners: [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
        ],
    },
    inspectionAfterBatching: {
        visible: false,
        controllerCheck: "",
        welderAmperage: "",
        properBatchArea: "",
        crucibleConditions: "",
        readingTime: "",
        readingTimeSignature: [],
        tc: false,
        tc2: false,
        burners: [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
        ],
    },
    inspectionAfterMelting: {
        visible: false,
        controllerCheck: "",
        welderAmperage: "",
        properBatchArea: "",
        crucibleConditions: "",
        readingTime: "",
        readingTimeSignature: [],
        tc: false,
        tc2: false,
        burners: [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
        ],
    },

    notes: "",
}

export const QASHEET = {
    thermalExpansion1: "",
    thermalExpansion2: "",
    thermalExpansion3: "",
    thermalExpansionAvg: "",
    thermalExpansionSignature: [],
    indexRefraction1: "",
    indexRefraction2: "",
    indexRefractionSignature: [],
    fiberSoftening1: "",
    fiberSoftening2: "",
    fiberSofteningSignature: [],
    notes: "",
}