import React from 'react'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { showSignature } from '../shared/SharedFunctions'

export default class ProcessSheet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        console.log("ProcessSheet -> componentDidMount -> this.props.processSheet", this.props.processSheet)
    }

    errorClass(objName, fieldName) {
        if (objName !== "") {
            if (this.props[objName][fieldName + "Error"] !== "") {
                return (this.props[objName][fieldName + "Error"] === "" ? '' : 'has-error');
            }
        } else {
            if (this.props[fieldName + "Error"] !== "") {
                return (this.props[fieldName + "Error"] === "" ? '' : 'has-error');
            }
        }
    }

    errorClass2(value) {
        return (value === "" || value === undefined ? '' : 'has-error')
    }

    render() {
        return (
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col">
                        <h3 className='pgHeader'>Process Sheet</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <h6 className='mb-0'>Process</h6>
                        Batching, Melting, Draining
                    </div>
                </div>

                {
                    this.props.processSheet.inspectionBeforeBatching.visible &&
                    <fieldset className={`border mt-4 ${this.props.disabledFields.productionField ? "bgSilver" : "fieldset-enabled"}`}>
                        <legend className="h5 font-weight-normal text-danger">Furnace Inspection</legend>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="controllerCheck" id="controllerCheck" value={this.props.processSheet.inspectionBeforeBatching.controllerCheck} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />
                                        Controller Check (error code)</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionBeforeBatching.welderAmperageError)}`}>
                                            Welder Amperage
                                        </span>
                                    </div>
                                    <input type="text" className="form-control nohighlight productionField" name="welderAmperage" id="welderAmperage" value={this.props.processSheet.inspectionBeforeBatching.welderAmperage} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />

                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="properBatchArea" id="properBatchArea" value={this.props.processSheet.inspectionBeforeBatching.properBatchArea} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />
                                        Cables, Bus Bars and Bolts, Straps and Clamps, Bars (nuclear), Burn Outs (rat holes), Proper Lid Brick Placement, Insulation around Batch Area</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="crucibleConditions" id="crucibleConditions" value={this.props.processSheet.inspectionBeforeBatching.crucibleConditions} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />
                                        Crucible Conditions and Drain Hole Area</label>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionBeforeBatching.readingTimeError)}`}>
                                            Element Reading Time
                                        </span>
                                    </div>
                                    <input type="text" className="form-control nohighlight productionField" name="readingTime" id="readingTime" value={this.props.processSheet.inspectionBeforeBatching.readingTime} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />

                                </div>
                            </div>
                            <div className={`col-3 bottomAlign`}>
                                <div className="input-group">
                                    <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                        {showSignature(this.props.processSheet.inspectionBeforeBatching.readingTimeSignature)}
                                    </div>
                                    <div className="input-group-append">
                                        <button className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionBeforeBatching.readingTimeSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'readingTimeSignature', 'inspectionBeforeBatching')}>
                                            {AccessibleIcon('fas fa-signature', 'Intial')}
                                        </button>
                                    </div>
                                </div>
                                <div className='text-danger'>{this.props.processSheet.readingTimeSignatureErr}</div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12 mt-2">
                                <fieldset className={`bg-white border`}>
                                    <legend className={`h6 font-weight-normal ${this.errorClass2(this.props.processSheet.inspectionBeforeBatching.burnersError)}`}>
                                        Select the side with the T/C and record reading</legend>
                                    <div className="row">
                                        <div className="col-lg-2 text-center">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        T/C
                                                    </span>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text productionField">
                                                        <input type="checkbox" name="tc" id="tc" checked={this.props.processSheet.inspectionBeforeBatching.tc} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-8">
                                            <div className="row">
                                                {
                                                    this.props.processSheet.inspectionBeforeBatching.burners.map((burner, index) => {
                                                        return (
                                                            <div key={index} className={`col-lg-2 mb-2`}>
                                                                <div className="furnace-entry">
                                                                    <input type="text" className="form-control" id={"value" + index} name="value" value={burner.value} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleArrayChange(e, this.props.formContent, 'burners', index, 'inspectionBeforeBatching')} />
                                                                    <div className="background-circle productionField border"></div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-center">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        T/C
                                                    </span>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text productionField">
                                                        <input type="checkbox" name="tc2" id="tc2" checked={this.props.processSheet.inspectionBeforeBatching.tc2} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionBeforeBatching')} />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </fieldset>

                            </div>

                        </div>
                    </fieldset>
                }

                <fieldset className='border mt-4'>
                    <legend className='h5 font-weight-normal text-danger'>Batching</legend>


                    <div className="row mb-2">
                        <div className="col-6">
                            <div className="row my-3">
                                <div className="col-auto">
                                    Follow
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control qaField" name="batching" id="batching" value={this.props.processSheet.batching} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    <div className='text-danger'>{this.props.processSheet.batchingErr}</div>
                                </div>
                                <div className="col-auto">
                                    for Batching Instructions
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.processSheet.batchingGrid.map((row, index) => {
                            return (
                                <div key={index} className="row mt-2">
                                    <div className={`col-3 ${this.errorClass2(row.batchingTempError)}`}>
                                        <label>Batching Temp</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control productionField" name="batchingTemp" id="batchingTemp" value={row.batchingTemp} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleArrayChange(e, this.props.formContent, 'batchingGrid', index)} />
                                        </div>
                                    </div>
                                    <div className={`col-3 ${this.errorClass2(row.batchingTimeError)}`}>
                                        <label>Time</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control productionField" name="batchingTime" id="batchingTime" value={row.batchingTime} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleArrayChange(e, this.props.formContent, 'batchingGrid', index)} />
                                        </div>
                                    </div>
                                    <div className={`col-3 ${this.errorClass2(row.batchingScoopedError)}`}>
                                        <label>Amount Scooped</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control productionField" name="batchingScooped" id="batchingScooped" value={row.batchingScooped} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleArrayChange(e, this.props.formContent, 'batchingGrid', index)} />
                                        </div>
                                    </div>
                                    <div className="col-3 bottomAlign">
                                        {
                                            index === 0 &&
                                            <div className="input-group">
                                                <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                                    {showSignature(this.props.processSheet.batchingGrid[index].batchingSignature)}
                                                </div>
                                                <div className="input-group-append">
                                                    <button className={`input-group-text ${this.errorClass2(this.props.processSheet.batchingGrid[index].batchingSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={(e) => this.props.signArray(e, this.props.formContent, 'batchingGrid', 'batchingSignature', index)}>
                                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {
                                            index > 0 &&
                                            <button className="btn btn-sm text-secondary" onClick={() => this.props.deleteRow(this.props.formContent, 'batchingGrid', index)} disabled={row.batchingTemp !== "" || row.batchingTime !== "" || row.batchingScooped !== ""}>{AccessibleIcon("fa-solid fa-trash-can", "Delete Batching Item")}</button>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        !this.props.disabledFields.productionField &&
                        <button className="btn btn-sm btn-clear mt-2" onClick={() => this.props.addRow(this.props.formContent, 'batchingGrid')}>
                            <i className="fas fa-plus-circle"></i> Add Row
                        </button>
                    }


                    <div className="row mt-4">
                        <div className="col-lg-6">
                            Did you get all the batch in?
                            <div className="form-check-inline">
                                <input type="radio" className="form-check-input mx-2" name="indexRefractionAccepted" id="indexRefractionAccepted" value={true} checked={!this.props.indexRefractionAccept} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                <label className="form-check-label" htmlFor="indexRefractionAccepted"> Yes</label>
                            </div>
                            <div className="form-check-inline">
                                <input type="radio" className="form-check-input mx-2" name="indexRefractionAccepted" id="indexRefractionAccepted" value={false} checked={!this.props.indexRefractionAccept} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                <label className="form-check-label" htmlFor="indexRefractionAccepted">No</label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className={`col-auto ${this.errorClass2(this.props.processSheet.batchingLeftError)}`}>
                                    <label htmlFor="">How many pounds are left?</label>
                                </div>
                                <div className={`col`}>
                                    <input type="text" className="form-control productionField" name="batchingLeft" id="batchingLeft" value={this.props.processSheet.batchingLeft} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                {
                    this.props.processSheet.inspectionAfterBatching.visible &&
                    <fieldset className={`border mt-4 ${this.props.disabledFields.productionField ? "bgSilver" : "fieldset-enabled"}`}>
                        <legend className="h5 font-weight-normal text-danger">Furnace Inspection</legend>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="controllerCheck" id="controllerCheck" value={this.props.processSheet.inspectionAfterBatching.controllerCheck} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />
                                        Controller Check (error code)</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionAfterBatching.welderAmperageError)}`}>
                                            Welder Amperage
                                        </span>
                                    </div>
                                    <input type="text" className="form-control nohighlight productionField" name="welderAmperage" id="welderAmperage" value={this.props.processSheet.inspectionAfterBatching.welderAmperage} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />

                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="properBatchArea" id="properBatchArea" value={this.props.processSheet.inspectionAfterBatching.properBatchArea} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />
                                        Cables, Bus Bars and Bolts, Straps and Clamps, Bars (nuclear), Burn Outs (rat holes), Proper Lid Brick Placement, Insulation around Batch Area</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="crucibleConditions" id="crucibleConditions" value={this.props.processSheet.inspectionAfterBatching.crucibleConditions} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />
                                        Crucible Conditions and Drain Hole Area</label>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionAfterBatching.readingTimeError)}`}>
                                            Element Reading Time
                                        </span>
                                    </div>
                                    <input type="text" className="form-control nohighlight productionField" name="readingTime" id="readingTime" value={this.props.processSheet.inspectionAfterBatching.readingTime} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />

                                </div>
                            </div>
                            <div className={`col-3 bottomAlign`}>
                                <div className="input-group">
                                    <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                        {showSignature(this.props.processSheet.inspectionAfterBatching.readingTimeSignature)}
                                    </div>
                                    <div className="input-group-append">
                                        <button className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionAfterBatching.readingTimeSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'readingTimeSignature', 'inspectionAfterBatching')}>
                                            {AccessibleIcon('fas fa-signature', 'Intial')}
                                        </button>
                                    </div>
                                </div>
                                <div className='text-danger'>{this.props.processSheet.readingTimeSignatureErr}</div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12 mt-2">
                                <fieldset className={`bg-white border`}>
                                    <legend className={`h6 font-weight-normal ${this.errorClass2(this.props.processSheet.inspectionAfterBatching.burnersError)}`}>
                                        Select the side with the T/C and record reading</legend>
                                    <div className="row">
                                        <div className="col-lg-2 text-center">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        T/C
                                                    </span>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text productionField">
                                                        <input type="checkbox" name="tc" id="tc" checked={this.props.processSheet.inspectionAfterBatching.tc} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-8">
                                            <div className="row">
                                                {
                                                    this.props.processSheet.inspectionAfterBatching.burners.map((burner, index) => {
                                                        return (
                                                            <div key={index} className={`col-lg-2 mb-2`}>
                                                                <div className="furnace-entry">
                                                                    <input type="text" className="form-control" id={"value" + index} name="value" value={burner.value} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleArrayChange(e, this.props.formContent, 'burners', index, 'inspectionAfterBatching')} />
                                                                    <div className="background-circle productionField border"></div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>

                                        <div className="col-lg-2 text-center">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        T/C
                                                    </span>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text productionField">
                                                        <input type="checkbox" name="tc2" id="tc2" checked={this.props.processSheet.inspectionAfterBatching.tc2} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterBatching')} />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </fieldset>

                            </div>

                        </div>
                    </fieldset>
                }

                <fieldset className='border mt-4'>
                    <legend className='h5 font-weight-normal text-danger'>Melting</legend>
                    <div className="row">
                        <div className="col-auto">
                            Follow
                        </div>
                        <div className={`col-3 ${this.errorClass2(this.props.processSheet.meltingError)}`}>
                            <input type="text" className="form-control qaField" name="melting" id="melting" value={this.props.processSheet.melting} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                        </div>
                        <div className="col-auto">for Melting</div>
                    </div>
                    <div className="row mt-4">
                        <div className={`col ${this.errorClass2(this.props.processSheet.meltingCycleError)}`}>
                            <label htmlFor="">Input/Record melt cycle:</label>
                            <input type="text" className="form-control productionField" name="meltingCycle" id="meltingCycle" value={this.props.processSheet.meltingCycle} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                        </div>
                        <div className={`col-3 bottomAlign`}>
                            <div className="input-group">
                                <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                    {showSignature(this.props.processSheet.meltingCycleSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.processSheet.meltingCycleSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'meltingCycleSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col">
                            Confirm melt cycle has been set.
                        </div>
                        <div className={`col-3 bottomAlign`}>
                            <div className="input-group">
                                <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                    {showSignature(this.props.processSheet.meltingCycleConfirmSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.processSheet.meltingCycleConfirmSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'meltingCycleConfirmSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                {
                    this.props.processSheet.inspectionAfterMelting.visible &&
                    <fieldset className={`border mt-4 ${this.props.disabledFields.productionField ? "bgSilver" : "fieldset-enabled"}`}>
                        <legend className="h5 font-weight-normal text-danger">Furnace Inspection</legend>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="controllerCheck" id="controllerCheck" value={this.props.processSheet.inspectionAfterMelting.controllerCheck} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />
                                        Controller Check (error code)</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionAfterMelting.welderAmperageError)}`}>
                                            Welder Amperage
                                        </span>
                                    </div>
                                    <input type="text" className="form-control nohighlight productionField" name="welderAmperage" id="welderAmperage" value={this.props.processSheet.inspectionAfterMelting.welderAmperage} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />

                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="properBatchArea" id="properBatchArea" value={this.props.processSheet.inspectionAfterMelting.properBatchArea} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />
                                        Cables, Bus Bars and Bolts, Straps and Clamps, Bars (nuclear), Burn Outs (rat holes), Proper Lid Brick Placement, Insulation around Batch Area</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <label className="form-check-label ml-2">
                                        <input type="checkbox" className="form-check-input productionField" name="crucibleConditions" id="crucibleConditions" value={this.props.processSheet.inspectionAfterMelting.crucibleConditions} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />
                                        Crucible Conditions and Drain Hole Area</label>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionAfterMelting.readingTimeError)}`}>
                                            Element Reading Time
                                        </span>
                                    </div>
                                    <input type="text" className="form-control nohighlight productionField" name="readingTime" id="readingTime" value={this.props.processSheet.inspectionAfterMelting.readingTime} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />

                                </div>
                            </div>
                            <div className={`col-3 bottomAlign`}>
                                <div className="input-group">
                                    <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                        {showSignature(this.props.processSheet.inspectionAfterMelting.readingTimeSignature)}
                                    </div>
                                    <div className="input-group-append">
                                        <button className={`input-group-text ${this.errorClass2(this.props.processSheet.inspectionAfterMelting.readingTimeSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'readingTimeSignature', 'inspectionAfterMelting')}>
                                            {AccessibleIcon('fas fa-signature', 'Intial')}
                                        </button>
                                    </div>
                                </div>
                                <div className='text-danger'>{this.props.processSheet.readingTimeSignatureErr}</div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12 mt-2">
                                <fieldset className={`bg-white border`}>
                                    <legend className={`h6 font-weight-normal ${this.errorClass2(this.props.processSheet.inspectionAfterMelting.burnersError)}`}>
                                        Select the side with the T/C and record reading</legend>
                                    <div className="row">
                                        <div className="col-lg-2 text-center">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        T/C
                                                    </span>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text productionField">
                                                        <input type="checkbox" name="tc" id="tc" checked={this.props.processSheet.inspectionAfterMelting.tc} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-8">
                                            <div className="row">
                                                {
                                                    this.props.processSheet.inspectionAfterMelting.burners.map((burner, index) => {
                                                        return (
                                                            <div key={index} className={`col-lg-2 mb-2`}>
                                                                <div className="furnace-entry">
                                                                    <input type="text" className="form-control" id={"value" + index} name="value" value={burner.value} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleArrayChange(e, this.props.formContent, 'burners', index, 'inspectionAfterMelting')} />
                                                                    <div className="background-circle productionField border"></div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>

                                        <div className="col-lg-2 text-center">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        T/C
                                                    </span>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text productionField">
                                                        <input type="checkbox" name="tc2" id="tc2" checked={this.props.processSheet.inspectionAfterMelting.tc2} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent, 'inspectionAfterMelting')} />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                            </div>

                        </div>
                    </fieldset>
                }

                <fieldset className='border mt-4'>
                    <legend className='h5 font-weight-normal text-danger'>Draining</legend>
                    <div className="row mb-4">
                        <div className="col-auto">Follow</div>
                        <div className={`col-3 ${this.errorClass2(this.props.processSheet.drainingError)}`}>
                            <input type="text" className="form-control qaField" name="draining" id="draining" value={this.props.processSheet.draining} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                        </div>
                        <div className="col-auto">
                            for Draining
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className={`col-5 ${this.errorClass2(this.props.processSheet.drainingTempError)}`}>
                            <label htmlFor="">Record Drain Temp</label>
                            <input type="text" className="form-control productionField" name="drainingTemp" id="drainingTemp" value={this.props.processSheet.drainingTemp} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                        </div>
                        <div className={`col-4 ${this.errorClass2(this.props.processSheet.drainingTimeError)}`}>
                            <label htmlFor="">Record Drain Time</label>
                            <input type="text" className="form-control productionField" name="drainingTime" id="drainingTime" value={this.props.processSheet.drainingTime} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                        </div>
                        <div className={`col-3 bottomAlign`}>
                            <div className="input-group">
                                <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                    {showSignature(this.props.processSheet.drainingSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.processSheet.drainingSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'drainingSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className={`col ${this.errorClass2(this.props.processSheet.drainingFritError)}`}>
                            <label htmlFor="">Record Frit Thickness</label>
                            <input type="text" className="form-control productionField" name="drainingFrit" id="drainingFrit" value={this.props.processSheet.drainingFrit} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                        </div>
                        <div className={`col-3 bottomAlign`}>
                            <div className="input-group">
                                <div className="form-control productionField" disabled={this.props.disabledFields.productionField}>
                                    {showSignature(this.props.processSheet.drainingFritSignature)}
                                </div>
                                <div className="input-group-append">
                                    <button className={`input-group-text ${this.errorClass2(this.props.processSheet.drainingFritSignatureError)}`} disabled={this.props.disabledFields.productionField} onClick={() => this.props.sign(this.props.formContent, 'drainingFritSignature')}>
                                        {AccessibleIcon('fas fa-signature', 'Intial')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="row mt-4 mb-4">
                    <div className={`col ${this.errorClass2(this.props.processSheet.notesError)}`}>
                        <label htmlFor="">Notes</label>
                        <textarea name="notes" id="notes" className="form-control productionField" value={this.props.processSheet.notes} disabled={this.props.disabledFields.productionField} onChange={(e) => this.props.handleChange(e, this.props.formContent)}></textarea>
                    </div>
                </div>

                <div className="fullBleed bg-light border mt-5">
                    <div className="btn border mr-3 batchField">Batch Room</div>
                    <div className="btn border mr-3 productionField">Production</div>
                    <div className="btn border mr-3 qaField">QA</div>
                    <div className="btn border mr-3 sortingField">Sorting</div>
                    <div className="btn border mr-3 inspectionField">Inspection</div>
                    <div className="btn border mr-3 gpField">GP</div>
                    <div className="btn border mr-3 grindingField">Grind Room</div>
                </div>

            </div> // container-fluid
        )
    }
}