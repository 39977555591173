import React from 'react'
import axios from 'axios'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { SERVER } from '../shared/Environment'
import { Modal } from 'react-bootstrap'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default class RawMaterial extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rawMaterialList: [],
            editItemIndex: null,
            productCode: "",
            productName: "",

            showModal: false,
        }
    }

    componentDidMount() {
        this.getRawMaterialList()
    }

    getRawMaterialList = () => {
        if (cookies.get("token")) {
            axios.post(SERVER + '/api/rawMaterialList/', { productCode: "" }, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistoryConfig -> getRawMaterialList -> response", response)
                    this.setState({
                        rawMaterialList: response.data
                    })
                })
        }
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        }, () => this.getUserList())
    }

    handleChange = (event) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value

        this.setState({
            [name]: value
        })
    }

    newItem = () => {
        this.setState({
            editItemIndex: -1
        })
    }

    editItem = (editItemIndex) => {
        this.setState({
            editItemIndex,
            productCode: this.state.rawMaterialList[editItemIndex].productCode,
            productName: this.state.rawMaterialList[editItemIndex].productName,
        }, () => {
            console.log("editItem -> editItemIndex", editItemIndex)
        })
    }

    saveEdit = () => {
        if (cookies.get("token")) {
            const productInfo = {
                productCode: this.state.productCode,
                productName: this.state.productName
            }
            // console.log("saveEdit -> productInfo", productInfo)

            let rawMaterialId = this.state.editItemIndex >= 0 ? this.state.rawMaterialList[this.state.editItemIndex].id : "0"
            // console.log("saveEdit -> rawMaterialId", rawMaterialId)

            axios.post(SERVER + '/api/rawMaterial/' + rawMaterialId, productInfo, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("saveEdit -> response", response)
                    this.setState({
                        editItemIndex: null,
                        productCode: "",
                        productName: ""
                    }, () => {
                        this.getRawMaterialList()
                    })
                })
        }
    }

    deleteItem = (rawMaterialId) => {
        if (cookies.get("token")) {
            axios.delete(SERVER + '/api/rawMaterial/' + rawMaterialId, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("deleteItem -> response", response)
                    this.getRawMaterialList()
                })
        }
    }

    cancelEdit = () => {
        this.setState({
            editItemIndex: null,
            productCode: "",
            productName: ""
        })
    }

    render() {
        return (
            <main>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col">
                            <h3 className='pgHeader'>Raw Material</h3>
                        </div>
                        <div className="col-lg-8 text-right">
                            <button className="btn btn-sm btn-success mr-2" onClick={this.newItem}>{AccessibleIcon('fa-solid fa-plus', 'New')} Add Raw Material</button>

                            {/* <button className="btn btn-sm btn-clear text-danger ml-5" onClick={() => console.log(this.state)}>Debug: Show State</button> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr className="bg-secondary text-white">
                                            <th className='py-2'>Product Code</th>
                                            <th className='py-2'>Product Name</th>
                                            <th className='py-2'><span className="sr-only">Action</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.editItemIndex < 0 &&
                                            <tr>
                                                <td>
                                                    <input type="text" className="form-control form-control-sm" id="productCode" name="productCode" value={this.state.productCode} onChange={this.handleChange} />
                                                </td>
                                                <td>
                                                    <input type="text" className="form-control form-control-sm" id="productName" name="productName" value={this.state.productName} onChange={this.handleChange} />
                                                </td>
                                                <td>
                                                    <button className="btn btn-sm btn-clear" onClick={this.saveEdit}>
                                                        {AccessibleIcon('fa-solid fa-check', 'Save')}</button>
                                                    <button className="btn btn-sm btn-clear ml-2" onClick={this.cancelEdit}>
                                                        {AccessibleIcon('fa-solid fa-xmark', 'Cancel')}</button>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            this.state.rawMaterialList.map((row, index) => {
                                                return (
                                                    <tr key={index} className='cursor-pointer'>
                                                        <td>
                                                            {
                                                                this.state.editItemIndex !== index &&
                                                                row.productCode
                                                            }
                                                            {
                                                                (this.state.editItemIndex === index) &&
                                                                <input type="text" className="form-control form-control-sm" id="productCode" name="productCode" value={this.state.productCode} onChange={this.handleChange} />
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                this.state.editItemIndex !== index &&
                                                                row.productName
                                                            }
                                                            {
                                                                (this.state.editItemIndex === index) &&
                                                                <input type="text" className="form-control form-control-sm" id="productName" name="productName" value={this.state.productName} onChange={this.handleChange} />
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                this.state.editItemIndex !== index &&
                                                                <>
                                                                    <button className="btn btn-sm btn-clear" onClick={() => this.editItem(index)}>
                                                                        {AccessibleIcon('fa-regular fa-pen-to-square', 'Edit')}</button>
                                                                    <button className="btn btn-sm btn-clear ml-2" onClick={() => this.deleteItem(row.id)}>
                                                                        {AccessibleIcon('far fa-trash-alt', 'Delete')}</button>
                                                                </>
                                                            }
                                                            {
                                                                (this.state.editItemIndex === index) &&
                                                                <>
                                                                    <button className="btn btn-sm btn-clear" onClick={this.saveEdit}>
                                                                        {AccessibleIcon('fa-solid fa-check', 'Save')}</button>
                                                                    <button className="btn btn-sm btn-clear text-danger ml-2" onClick={this.cancelEdit}>
                                                                        {AccessibleIcon('fa-solid fa-xmark', 'Cancel')}</button>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>

                <Modal show={this.state.showModal} onHide={this.toggleModal}>
                    <Modal.Header className='bg-secondary text-white'>
                        <Modal.Title>{this.state.staffId !== "0" ? "User Id " + this.state.staffId : "New User"}</Modal.Title>
                        <button className="btn btn-clear float-right text-white" onClick={this.toggleModal}>{AccessibleIcon('fa-solid fa-x large', 'Close')}</button>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                    </Modal.Body>
                </Modal>

            </main>
        )
    }

}

