import moment from 'moment'

export function showSignature(signature) {
    if (signature) {
        // const signatureDesc = signature.sort((a, b) => new Date(b.signDate) - new Date(a.signDate))
        // console.log("TopSheet -> showSignature -> signatureDesc", signature)
        if (signature.length > 0) {
            return (
                <div className={`signatureBlock ${signature.length > 1 ? "cursor-pointer" : ""}`}>
                    {signature[0].initial} {moment(signature[0].signDate).format('MM/DD/YYYY')}
                    {signature.length > 1 ? <span className="more float-right"></span> : ""}
                    {
                        signature.length > 1 &&
                        <div className="signatureBlockList softShadow bordered">
                            {signature.map((row, index) => {
                                return (
                                    index > 0 &&
                                    <div key={index}>{row.initial} {moment(row.signDate).format('MM/DD/YYYY')}</div>
                                )
                            })}
                        </div>
                    }
                </div>
            )
        } else {
            return (<></>)
        }
    }
}