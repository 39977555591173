import React from 'react'
import axios from 'axios'
import { SERVER } from '../shared/Environment'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default class UserForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: 0,
            token: "",
            userIninitals: "",

            usersList: [],
            staffId: 0,

            show: false,

            firstName: "",
            lastName: "",
            initials: "",
            email: "",
            phone: "",
            sms: false,
            userName: "",
            pw: "",
            access: "",
            deptCode: "",

            pw2: "",
            changePw: "",

            checkinginitials: false,
            checkinguserName: false,

            accessOptions: [
                { name: "User" },
                { name: "Manager" },
                { name: "Administrator" },
            ],

            departments: [],
        }
    }

    componentDidMount() {
        this.getDepartments()
        console.log("UserForm -> componentDidMount -> this.props.staffId", this.props.staffId)
        if (this.props.staffId !== 0) {
            this.getUser()
        }
    }

    getUser = () => {
        axios.get(SERVER + '/api/users/' + this.props.staffId, {
            headers: {
                userId: cookies.get("userId"),
                token: cookies.get("token")
            }
        })
            .then(response => {
                console.log("UserForm -> getUser -> response.data", response.data)
                if (response.data) {
                    this.setState({
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        initials: response.data.initials,
                        email: response.data.email,
                        phone: response.data.phone,
                        sms: response.data.sms,
                        userName: response.data.userName,
                        pw: "",
                        access: response.data.access,
                        deptCode: response.data.deptCode,
                    })
                }

            })
            .catch(err => {
                console.log("UserForm -> getUser -> err", err)
            })
    }

    getDepartments = () => {
        axios.get(SERVER + '/api/departments/', {
            headers: {
                userId: cookies.get("userId"),
                token: cookies.get("token")
            }
        })
            .then(response => {
                console.log("UserForm -> getDepartments -> response.data", response.data)
                if (response.data) {
                    this.setState({
                        departments: response.data
                    })
                }

            })
            .catch(err => {
                console.log("UserForm -> getDepartments -> err", err)
            })
    }

    handleChange = (event) => {
        const target = event.target
        let value = target.value
        const name = target.name
        // console.log("UserForm -> handleChange -> name", name)
        // console.log("UserForm -> handleChange -> target.value", target.value)

        if (name === "deptCode") {
            if (target.checked) {
                value = this.state.deptCode + " " + target.value
            } else {
                value = (this.state.deptCode).replace(target.value, "")
            }

            this.setState({
                deptCode: value.trim().replaceAll("  ", " ")
            })
        } else {
            this.setState({
                [name]: target.value
            }, () => {
                if (name === "email") {
                    this.validField(name, "email", "")
                } else if (name === "pw2") {
                    this.validField("pw", "pw", "pw2")
                } else {
                    this.validField(name, "string", "")
                }
            })
        }

    }

    validField = (fieldName, validateType, fieldName2) => {
        // console.log("UserForm -> validField -> fieldName", fieldName)
        let errMsg = ""
        if (validateType === "numeric") {
            if (this.state[fieldName].trim().length === 0) {
                errMsg = "Required field"
            } else if (isNaN(this.state[fieldName])) {
                errMsg = "Invalid value"
            }
        } else if (validateType === "email") {
            const regex = RegExp(
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
            if (this.state[fieldName].trim().length === 0) {
                errMsg = "Required field"
            } else if (!regex.test(this.state[fieldName])) {
                errMsg = "Invalid email"
            }
        } else if (validateType === "phone") {
            const regex = RegExp(
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
            )
            if (this.state[fieldName].trim().length === 0) {
                errMsg = "Required field"
            } else if (!regex.test(this.state[fieldName])) {
                errMsg = "Invalid phone number"
            }
        } else if (validateType === "unique") {
            if (this.state[fieldName].trim().length === 0) {
                errMsg = "Required field"
            } else {
                this.setState({
                    ["checking" + fieldName]: true
                })
                axios.post(SERVER + '/api/uniqueValue/', { fieldName: fieldName, value: this.state[fieldName], staffId: this.props.staffId }, {
                    headers: {
                        userId: cookies.get("userId"),
                        token: cookies.get("token")
                    }
                })
                    .then(response => {
                        console.log("UserForm -> validField -> response.data", response.data)
                        this.setState({
                            [fieldName + "Error"]: response.data ? "" : "Value already in use",
                            ["checking" + fieldName]: false
                        })

                    })
                    .catch(err => {
                        console.log("UserForm -> validField -> err", err)
                    })
            }
        } else if (validateType === "pw") {
            if (this.props.staffId === 0) {
                if (this.state[fieldName] === "" || this.state[fieldName2] === "") {
                    errMsg = "Required field"
                } else if (this.state[fieldName] !== this.state[fieldName2]) {
                    errMsg = "Password entries do not match"
                }

            } else if (this.props.staffId !== 0 && this.state.changePw === "Yes") {
                if (this.state[fieldName] === "" || this.state[fieldName2] === "") {
                    errMsg = "Required field"
                } else if (this.state[fieldName] !== this.state[fieldName2]) {
                    errMsg = "Password entries do not match"
                }
            }
        } else {
            if ((this.state[fieldName].trim()).length === 0) {
                console.log("line 146 UserForm -> validField -> this.state[fieldName]", this.state[fieldName])
                console.log("line 146 UserForm -> validField -> this.state[fieldName].trim()).length", (this.state[fieldName].trim()).length)
                errMsg = "Required field"
            }
        }

        // console.log("171 UserForm -> errMsg", errMsg)
        this.setState({
            [fieldName + "Error"]: errMsg
        })
        return errMsg !== "" ? false : true
    }

    validateUserName = () => {
        const valid = this.validField("userName", "unique", "")
        return valid
    }

    validateInitials = () => {
        const valid = this.validField("initials", "unique", "")
        return valid
    }

    validateForm = () => {
        let validFields = true
        let requiredFields = [
            { name: "firstName", type: "string", name2: "" },
            { name: "lastName", type: "string", name2: "" },
            { name: "initials", type: "unique", name2: "" },
            { name: "email", type: "email", name2: "" },
            { name: "phone", type: "phone", name2: "" },
            { name: "userName", type: "unique", name2: "" },
            { name: "pw", type: "pw", name2: "pw2" },
            { name: "access", type: "string", name2: "" },
            { name: "deptCode", type: "string", name2: "" },
        ]

        for (let i = 0; i < requiredFields.length; i++) {
            const valid = this.validField(requiredFields[i].name, requiredFields[i].type, requiredFields[i].name2)
            validFields = validFields && valid
        }
        console.log("UserForm -> handleSave -> validFields", validFields)
        return validFields
    }

    handleSave = () => {
        if (cookies.get("token")) {

            const validForm = this.validateForm()
            // const validUserName = this.validateUserName()

            if (validForm) {
                console.log("handleSave -> this.state.departments", this.state.departments)

                const userinfo = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    initials: this.state.initials,
                    email: this.state.email,
                    phone: this.state.phone,
                    sms: this.state.sms,
                    userName: this.state.userName,
                    pw: (this.state.changePw === "Yes" || this.props.staffId === 0) ? this.state.pw : "",
                    access: this.state.access,
                    deptCode: this.state.deptCode
                }

                axios.post(SERVER + '/api/users/' + this.props.staffId, userinfo, {
                    headers: {
                        userId: cookies.get("userId"),
                        token: cookies.get("token")
                    }
                })
                    .then(response => {
                        console.log("UserForm -> handleSave -> response.data", response.data)
                        if (response.data) {
                            this.props.toggleModal()
                        }

                    })
                    .catch(err => {
                        console.log("BatchHistory -> handleSubmit -> err.response.status", err)
                    })
            }

        } else {
            // window.location.reload()
        }
    }

    errorClass(error) {
        if (error !== undefined) {
            return (error.length === 0 ? '' : 'has-error');
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid mt-3 mb-4">
                    <div className="row">
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.firstNameErr)}`}>
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" className="form-control form-control-sm" id="firstName" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                            <span className="text-danger">{this.state.firstNameErr}</span>
                        </div>
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.lastNameErr)}`}>
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" className="form-control form-control-sm" id="lastName" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                            <span className="text-danger">{this.state.lastNameErr}</span>
                        </div>
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.initialsErr)}`}>
                            <label htmlFor="initials">Initials</label>
                            <div className="input-group input-group-sm">
                                <input type="text" className="form-control form-control-sm" id="initials" name="initials" value={this.state.initials} onChange={this.handleChange} onBlur={this.validateInitials} />
                                {
                                    this.state.checkinginitials &&
                                    <span className="input-group-text bg-white">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </span>
                                }
                            </div>
                            <span className="text-danger">{this.state.initialsErr}</span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.emailErr)}`}>
                            <label htmlFor="email">Email</label>
                            <input type="text" className="form-control form-control-sm" id="email" name="email" value={this.state.email} onChange={this.handleChange} />
                            <span className="text-danger">{this.state.emailErr}</span>
                        </div>
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.phoneErr)}`}>
                            <label htmlFor="phone">Phone</label>
                            <input type="text" className="form-control form-control-sm" id="phone" name="phone" value={this.state.phone} onChange={this.handleChange} />
                            <span className="text-danger">{this.state.phoneErr}</span>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.userNameErr)}`}>
                            <label htmlFor="userName">User Name</label>
                            <div className="input-group input-group-sm">
                                <input type="text" className="form-control form-control-sm" id="userName" name="userName" value={this.state.userName} onChange={this.handleChange} onBlur={this.validateUserName} autoComplete='off' />
                                {
                                    this.state.checkinguserName &&
                                    <span className="input-group-text bg-white">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </span>
                                }
                            </div>
                            <span className="text-danger">{this.state.userNameErr}</span>
                        </div>

                        {
                            this.props.staffId !== 0 &&
                            <div className={`form-group col-lg-4 ${this.errorClass(this.state.pwErr)}`}>
                                <label>Password</label>
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <input className="mr-2" name="changePw" id="changePw" type="checkbox" value="Yes" aria-label="Change Password" onChange={this.handleChange} /> Change
                                        </div>

                                    </div>
                                    <input type="search" className="form-control bg-white pw-mask" name="pw" id="pw" value={this.state.pw} onChange={this.handleChange} autoComplete='current-password' />
                                </div>
                                <span className="text-danger">{this.state.pwErr}</span>
                            </div>
                        }

                        {
                            this.props.staffId === 0 &&
                            <div className={`form-group col-lg-4 ${this.errorClass(this.state.pwErr)}`}>
                                <label htmlFor="pw">Password</label>
                                <input type="search" className="form-control form-control-sm pw-mask" id="pw" name="pw" value={this.state.pw} onChange={this.handleChange} autoComplete='off' />
                                <span className="text-danger">{this.state.pwErr}</span>
                            </div>
                        }

                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.pwErr)}`}>
                            <label htmlFor="pw2">Confirm Password</label>
                            <input type="search" className="form-control form-control-sm pw-mask" id="pw2" name="pw2" value={this.state.pw2} onChange={this.handleChange} autoComplete='off' />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className={`form-group col-lg-4 ${this.errorClass(this.state.accessErr)}`}>
                            <label htmlFor="access">Access</label>
                            <select className="form-control form-control-sm" name="access" id="access"
                                value={this.state.access}
                                onChange={this.handleChange}>
                                <option value="">Select...</option>
                                {
                                    this.state.accessOptions.map((row, index) => {
                                        return (
                                            <option key={index} value={row.name}>{row.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="text-danger">{this.state.accessErr}</span>
                        </div>
                        <div className={`form-group col-lg-8 ${this.errorClass(this.state.deptIdErr)}`}>
                            <label htmlFor="deptCode">Department</label><br />
                            {
                                this.state.departments.map((row, index) => {
                                    return (
                                        <label key={index} className="mr-4" htmlFor={"deptCode" + index}>
                                            <input key={index} className="mr-2" name="deptCode" id={"deptCode" + index} type="checkbox" value={row.deptCode} checked={(this.state.deptCode).indexOf(row.deptCode) >= 0} onChange={this.handleChange} />
                                            {row.name}
                                        </label>
                                    )
                                })
                            }
                            <span className="text-danger">{this.state.deptIdErr}</span>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <button className="btn btn-success" onClick={this.handleSave}>Save</button>
                            <button className="btn btn-clear text-danger" onClick={this.props.toggleModal}>Cancel</button>
                        </div>
                        <div className="col text-right">
                            <button className="btn btn-clear text-debug ml-5" onClick={() => console.log(this.state)}>Show State</button>

                        </div>
                    </div>
                </div>

            </ >
        )
    }

}
