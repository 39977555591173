import React from 'react'
import axios from 'axios'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { SERVER } from '../shared/Environment'
import { BATCHSHEET, PROCESSSHEET, QASHEET, TOPSHEET } from '../shared/FormStructures'
import { showSignature } from '../shared/SharedFunctions'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default class CBatchHistoryConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rawMaterialList: [],
            departments: [],
            qcDeptId: 0,

            batchHistoryId: 0,
            formType: "CBH",
            status: "Setup",
            status2: "Setup",
            glassCode: "",
            formNumber: "",
            productCode: "",
            controlNumber: "",
            lotNumber: "",
            lotNumber2: "",

            topSheet: JSON.parse(JSON.stringify(TOPSHEET)),
            topSheet2: JSON.parse(JSON.stringify(TOPSHEET)),
            batchSheets: [
                JSON.parse(JSON.stringify(BATCHSHEET))
            ],
            processSheet: JSON.parse(JSON.stringify(PROCESSSHEET)),
            processSheet2: JSON.parse(JSON.stringify(PROCESSSHEET)),
            qaSheet: JSON.parse(JSON.stringify(QASHEET)),
            qaSheet2: JSON.parse(JSON.stringify(QASHEET)),

            validForm: false,
        }
    }

    componentDidMount() {
        this.getToken()
        this.getRawMaterialList()
        this.getDepartments()
        if (this.props.batchHistoryId !== "" && this.props.batchHistoryId !== 0) {
            this.setState({
                batchHistoryId: this.props.batchHistoryId
            }, () => {
                this.getForm()
            })
        }

        console.log("BatchHistoryConfig -> componentDidMount -> BATCHSHEET", BATCHSHEET)
        // console.log("ProcessSheet -> componentDidMount -> this.props.processSheet", this.props.processSheet)
    }

    getToken = () => {
        this.setState({
            userId: this.getCookie("userId"),
            initials: this.getCookie("initials"),
            token: this.getCookie("token")
        })
    }

    getCookie = (cookieName) => {
        return cookies.get(cookieName)
    }

    getRawMaterialList = () => {
        if (cookies.get("token")) {
            axios.post(SERVER + '/api/rawMaterialList/', { productCode: "" }, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("Dashboard -> getRawMaterialList -> response", response)
                    this.setState({
                        rawMaterialList: response.data
                    })
                })
        }
    }

    getDepartments = () => {
        if (cookies.get("token")) {
            axios.get(SERVER + '/api/departments/', {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    // console.log("BatchHistoryConfig -> getDepartments -> response", response)
                    this.setState({
                        departments: response.data,
                        qcDeptId: (response.data.find(dept => dept.name === "Q/A")).id
                    })
                })
        }
    }

    getForm = () => {
        if (cookies.get("token")) {
            axios.get(SERVER + '/api/batchHistories/' + this.props.batchHistoryId, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                    if (response.data) {
                        this.setState({
                            formType: response.data.formType,
                            status: response.data.status,
                            status2: response.data.status2,
                            glassCode: response.data.glassCode,
                            formNumber: response.data.formNumber,
                            productCode: response.data.productCode,
                            controlNumber: response.data.controlNumber,
                            lotNumber: response.data.lotNumber,
                            lotNumber2: response.data.lotNumber2,
                            topSheet: response.data.topSheet,
                            topSheet2: response.data.topSheet2,
                            batchSheets: response.data.batchSheets,
                            processSheet: response.data.processSheet,
                            processSheet2: response.data.processSheet2,
                            qaSheet: response.data.qaSheet,
                            qaSheet2: response.data.qaSheet2,
                        }, () => this.validForm(true))
                    }

                })
                .catch(err => {
                    console.log("BatchHistory -> handleSubmit -> err.response.status", err)
                })
        } else {
            window.location.reload()
        }
    }

    handleChange = (event, objName, subObjName, fieldName, fieldValue) => {
        // const target = event.target
        // let value = target.type === 'checkbox' ? target.checked : target.value
        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }

        if (subObjName) {
            let subObj = this.state[objName][subObjName]
            subObj[name] = value
            console.log("BatchHistoryConfig -> handleChange -> subObj[name]", subObj[name])
            // this.state[objName][subObjName]
            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [subObjName]: subObj
                }
            }), () => {
                this.validForm()
                // console.log("BatchHistoryConfig -> handleChange -> this.state[objName][subObjName]", objName, subObjName, this.state[objName][subObjName])
            })

        } else if (objName) {
            this.setState(prevState => ({
                [objName]: {
                    ...prevState[objName],
                    [name]: value
                }
            }), () => {
                this.validForm()
            })
        } else {
            this.setState({
                [name]: value
            }, () => {
                if ((name === "productCode" && value !== "" && this.state.controlNumber !== "") || (name === "controlNumber" && value !== "" && this.state.productCode !== "")) {
                    console.log("handleChange -> checkControl# -> name", name)
                    this.validForm(true)
                } else {
                    console.log("handleChange -> not checkControl# -> name", name)
                    this.validForm(false)
                }
            })

        }
    }

    validForm = (checkControlNumber) => {
        let reqFields = [
            "glassCode",
            "formNumber",
            "productCode",
            "controlNumber",
        ]
        let validForm = true
        for (let i = 0; i < reqFields.length; i++) {
            validForm = validForm && (this.state[reqFields[i]] !== "")
            this.setState({
                [reqFields[i] + "Error"]: this.state[reqFields[i]] === "" ? "Required Field" : ""
            })
        }

        for (let i = 0; i < this.state.batchSheets.length; i++) {
            for (let j = 0; j < this.state.batchSheets[i].hopperMaterial.length; j++) {
                validForm = validForm && this.state.batchSheets[i].hopperMaterial[j].material !== ""
                this.updateArrayInArrayOfObjects(null, "batchSheets", i, "hopperMaterial", j, "materialError", this.state.batchSheets[i].hopperMaterial[j].material !== "" ? "" : "Required Field")

                validForm = validForm && this.state.batchSheets[i].hopperMaterial[j].lbs !== ""
                this.updateArrayInArrayOfObjects(null, "batchSheets", i, "hopperMaterial", j, "lbsError", this.state.batchSheets[i].hopperMaterial[j].lbs !== "" ? "" : "Required Field")
            }
            if (!this.state.batchSheets[i].noContainer) {
                for (let j = 0; j < this.state.batchSheets[i].containerMaterial.length; j++) {
                    validForm = validForm && this.state.batchSheets[i].containerMaterial[j].material !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "containerMaterial", j, "materialError", this.state.batchSheets[i].containerMaterial[j].material !== "" ? "" : "Required Field")

                    validForm = validForm && this.state.batchSheets[i].containerMaterial[j].lbs !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "containerMaterial", j, "lbsError", this.state.batchSheets[i].containerMaterial[j].lbs !== "" ? "" : "Required Field")
                }
            } else {
                for (let j = 0; j < this.state.batchSheets[i].containerMaterial.length; j++) {
                    // validForm = validForm && this.state.batchSheets[i].containerMaterial[j].material !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "containerMaterial", j, "materialError", "")

                    // validForm = validForm && this.state.batchSheets[i].containerMaterial[j].lbs !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "containerMaterial", j, "lbsError", "")
                }
            }
            if (!this.state.batchSheets[i].noSmallMelt) {
                for (let j = 0; j < this.state.batchSheets[i].smallMeltMaterial.length; j++) {
                    validForm = validForm && this.state.batchSheets[i].smallMeltMaterial[j].material !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "smallMeltMaterial", j, "materialError", this.state.batchSheets[i].smallMeltMaterial[j].material !== "" ? "" : "Required Field")

                    validForm = validForm && this.state.batchSheets[i].smallMeltMaterial[j].lbs !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "smallMeltMaterial", j, "lbsError", this.state.batchSheets[i].smallMeltMaterial[j].lbs !== "" ? "" : "Required Field")
                }
                validForm = validForm && this.state.batchSheets[i].smallMeltNotes !== ""
                this.updateFieldinArrayOfObjects(null, "batchSheets", i, "smallMeltNotesError", this.state.batchSheets[i].smallMeltNotes !== "" ? "" : "Required Field")

            } else {
                for (let j = 0; j < this.state.batchSheets[i].smallMeltMaterial.length; j++) {
                    // validForm = validForm && this.state.batchSheets[i].smallMeltMaterial[j].material !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "smallMeltMaterial", j, "materialError", "")

                    // validForm = validForm && this.state.batchSheets[i].smallMeltMaterial[j].lbs !== ""
                    this.updateArrayInArrayOfObjects(null, "batchSheets", i, "smallMeltMaterial", j, "lbsError", "")
                }
                this.updateFieldinArrayOfObjects(null, "batchSheets", i, "smallMeltNotesError", "")
            }


            validForm = validForm && this.state.batchSheets[i].verifyTotalBatchWeight !== ""
            this.updateFieldinArrayOfObjects(null, "batchSheets", i, "verifyTotalBatchWeightError", this.state.batchSheets[i].verifyTotalBatchWeight !== "" ? "" : "Required Field")

            validForm = validForm && this.state.batchSheets[i].verifyTotalBatchWeightSignature !== ""
            this.updateFieldinArrayOfObjects(null, "batchSheets", i, "verifyTotalBatchWeightSignatureError", this.state.batchSheets[i].verifyTotalBatchWeightSignature !== "" ? "" : "Required Field")

            validForm = validForm && this.state.batchSheets[i].verifyTotalBatchWeightSignature.length > 0
            this.updateFieldinArrayOfObjects(null, "batchSheets", i, "verifyTotalBatchWeightSignatureError", this.state.batchSheets[i].verifyTotalBatchWeightSignature.length > 0 ? "" : "Required Field")

            validForm = validForm && this.state.batchSheets[i].targetPailWeight1 !== ""
            this.updateFieldinArrayOfObjects(null, "batchSheets", i, "targetPailWeight1Error", this.state.batchSheets[i].targetPailWeight1 !== "" ? "" : "Required Field")

            // validForm = validForm && this.state.batchSheets[i].targetPailWeight2 !== ""
            // this.updateFieldinArrayOfObjects(null, "batchSheets", i, "targetPailWeight2Error", this.state.batchSheets[i].targetPailWeight2 !== "" ? "" : "Required Field")
        }

        validForm = validForm && this.state.processSheet.batching !== ""
        validForm = validForm && this.state.processSheet.melting !== ""
        validForm = validForm && this.state.processSheet.draining !== ""

        this.setState(prevState => ({
            processSheet: {
                ...prevState.processSheet,
                batchingError: this.state.processSheet.batching !== "" ? "" : "Required Field",
                meltingError: this.state.processSheet.melting !== "" ? "" : "Required Field",
                drainingError: this.state.processSheet.draining !== "" ? "" : "Required Field"
            },
            processSheet2: {
                ...prevState.processSheet2,
                batchingError: this.state.processSheet2.batching !== "" ? "" : "Required Field",
                meltingError: this.state.processSheet2.melting !== "" ? "" : "Required Field",
                drainingError: this.state.processSheet2.draining !== "" ? "" : "Required Field"
            }
        }), () => {
            // console.log("BatchHistoryConfig -> validForm -> this.state.processSheet", this.state.processSheet)
        })



        console.log("BatchHistoryConfig -> validForm -> validForm", validForm)
        this.setState({
            validForm
        }, () => {
            if (checkControlNumber) {
                axios.post(SERVER + '/api/uniqueControlNumber/', {
                    batchHistoryId: this.state.batchHistoryId,
                    productCode: this.state.productCode,
                    controlNumber: this.state.controlNumber
                }, {
                    headers: {
                        userId: cookies.get("userId"),
                        token: cookies.get("token")
                    }
                })
                    .then(response => {
                        console.log("BatchHistoryConfig -> validForm -> response.data", response.data)
                        this.setState({
                            controlNumberError: response.data ? "" : "Product Code and Control Number already in use",
                            uniqueControlError: response.data ? "" : "Product Code and Control Number already in use"
                        })

                    })
                    .catch(err => {
                        console.log("UserForm -> validField -> err", err)
                    })
            }
        })

        // return (validForm)
    }

    saveForm = (submitForm) => {
        console.log("saveForm -> submitForm", submitForm)
        if (cookies.get("token")) {

            // let validForm = true
            // if (formStatus === "Submitted") {
            //     validForm = this.validateForm()
            // }

            const batchHistory = {
                formType: this.state.formType,
                status: submitForm ? "Pre-Production" : this.state.status,
                status2: submitForm ? "Pre-Production" : this.state.status2,
                submitForm,
                glassCode: this.state.glassCode,
                formNumber: this.state.formNumber,
                productCode: this.state.productCode,
                controlNumber: this.state.controlNumber,
                lotNumber: this.state.lotNumber,
                lotNumber2: this.state.lotNumber2,
                deptId: this.state.qcDeptId,
                topSheet: this.state.topSheet,
                topSheet2: this.state.topSheet2,
                batchSheets: this.state.batchSheets,
                processSheet: this.state.processSheet,
                processSheet2: this.state.processSheet2,
                qaSheet: this.state.qaSheet,
                qaSheet2: this.state.qaSheet2,
                notes: this.state.notes,
            }
            console.log("BatchHistoryConfig -> saveForm -> batchHistory", batchHistory)

            axios.post(SERVER + '/api/batchHistories/' + this.state.batchHistoryId, batchHistory, {
                headers: {
                    userId: cookies.get("userId"),
                    token: cookies.get("token")
                }
            })
                .then(response => {
                    console.log("BatchHistory -> componentDidMount -> response.data", response.data)
                    if (response.data) {
                        this.props.getDashboardData()
                        this.props.toggleModal()
                    }

                })
                .catch(err => {
                    console.log("BatchHistory -> handleSubmit -> err.response.status", err)
                })
        } else {
            window.location.reload()
        }
    }

    duplicateForm = () => {
        let batchSheets = []

        for (let i = 0; i < this.state.batchSheets.length; i++) {
            batchSheets.push(JSON.parse(JSON.stringify(BATCHSHEET)))

            let hopperMaterial = []
            for (let j = 0; j < this.state.batchSheets[i].hopperMaterial.length; j++) {
                hopperMaterial.push({
                    material: this.state.batchSheets[i].hopperMaterial[j].material,
                    lbs: this.state.batchSheets[i].hopperMaterial[j].lbs,
                    actualLbs: 0,
                    RN: "",
                    signature: []
                })
            }
            batchSheets[i].hopperMaterial = hopperMaterial

            let containerMaterial = []
            for (let j = 0; j < this.state.batchSheets[i].containerMaterial.length; j++) {
                containerMaterial.push({
                    material: this.state.batchSheets[i].containerMaterial[j].material,
                    lbs: this.state.batchSheets[i].containerMaterial[j].lbs,
                    actualLbs: 0,
                    RN: "",
                    signature: []
                })
            }
            batchSheets[i].containerMaterial = containerMaterial

            batchSheets[i].verifyTotalBatchWeight = this.state.batchSheets[i].verifyTotalBatchWeight
        }
        console.log("BatchHistoryConfig -> duplicateForm -> batchSheets", batchSheets)

        this.setState({
            batchHistoryId: 0,
            status: "Setup",
            status2: "Setup",
            controlNumber: this.state.controlNumber + " (Copy)",
            lotNumber: "",
            lotNumber2: "",

            topSheet: JSON.parse(JSON.stringify(TOPSHEET)),
            topSheet2: JSON.parse(JSON.stringify(TOPSHEET)),
            batchSheets: batchSheets,
            processSheet: JSON.parse(JSON.stringify(PROCESSSHEET)),
            processSheet2: JSON.parse(JSON.stringify(PROCESSSHEET)),
            qaSheet: JSON.parse(JSON.stringify(QASHEET)),
            qaSheet2: JSON.parse(JSON.stringify(QASHEET)),
        }, () => {
            this.validForm()
        })
    }

    addBatchSheet = () => {
        let batchSheets = this.state.batchSheets
        batchSheets.push(
            JSON.parse(JSON.stringify(BATCHSHEET))
        )

        this.setState({
            batchSheets
        })
    }

    deleteBatchSheet = (index) => {
        let batchSheets = this.state.batchSheets
        batchSheets.splice(index, 1)
        this.setState({
            batchSheets
        })
    }

    addMaterial = (materialType, batchSheetIndex) => {
        let batchSheets = this.state.batchSheets[batchSheetIndex]
        console.log("BatchHistoryConfig -> addHopper -> batchSheets", batchSheets)

        let material = batchSheets[materialType]
        console.log("BatchHistoryConfig -> addMaterial -> material", material)

        material.push(
            {
                material: "",
                lbs: "",
                actualLbs: 0,
                RN: "",
                signature: []
            }
        )

        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === batchSheetIndex ? Object.assign(obj, {
                    [materialType]: material
                }) : obj)
            )
        }))
    }

    deleteMaterial = (materialType, batchSheetIndex, materialIndex) => {
        console.log("BatchHistoryConfig -> deleteMaterial -> materialIndex", materialIndex)
        let batchSheets = this.state.batchSheets[batchSheetIndex]
        let material = batchSheets[materialType]

        material.splice(materialIndex, 1)
        console.log("BatchHistoryConfig -> deleteMaterial -> material", material)

        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === batchSheetIndex ? Object.assign(obj, {
                    [materialType]: material
                }) : obj)
            )
        }))
    }

    updateArrayInArrayOfObjects = (event, arrayOfObjectName, arrayOfObjectIndex, arrayName, arrayIndex, fieldName, fieldValue) => {
        // Object Format:
        // arrayOfObjectName: {
        //     fieldName1: "",
        //     fieldName2: "",
        //     arrayName: [
        //         fieldName3: "",
        //         fieldName4: "",
        //     ]
        // }

        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }

        let arrayOfObject = this.state[arrayOfObjectName][arrayOfObjectIndex]
        let newArray = arrayOfObject[arrayName]
        newArray[arrayIndex][name] = value

        this.setState(prevState => ({
            batchSheets: prevState.batchSheets.map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [arrayName]: newArray
                }) : obj)
            )
        }), () => {
            if (event) {
                this.validForm()
            }
        })
    }

    updateFieldinArrayOfObjects = (event, arrayOfObjectName, arrayOfObjectIndex, fieldName, fieldValue) => {
        // Object Format:
        // arrayOfObjectName: {
        //     fieldName1: "",
        //     fieldName2: "",
        //     arrayName: [
        //         fieldName3: "",
        //         fieldName4: "",
        //     ]
        // }

        let name = fieldName
        let value = fieldValue
        if (event) {
            name = event.target.name
            value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }
        // console.log("updateFieldinArrayOfObjects -> arrayOfObjectName, arrayOfObjectIndex, name, value", arrayOfObjectName, arrayOfObjectIndex, name, value)

        if (name === "verifyTotalBatchWeightSignature") {
            value = this.state[arrayOfObjectName][arrayOfObjectIndex][fieldName]
            value.unshift({
                initial: this.state.initials,
                signDate: new Date()
            })
        }

        this.setState(prevState => ({
            [arrayOfObjectName]: prevState[arrayOfObjectName].map(
                (obj, index) => (index === arrayOfObjectIndex ? Object.assign(obj, {
                    [name]: value
                }) : obj)
            )
        }), () => {
            if (event) {
                this.validForm()
            }
        })
    }

    errorClass2(value) {
        // console.log("BatchHistoryConfig -> value", value)
        return (value === "" || value === undefined ? '' : 'has-error')
    }

    render() {
        return (
            <main>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className={`form-group col ${this.errorClass2(this.state.glassCodeError)}`}>
                            <label htmlFor="glassCode">Glass Code</label>
                            <input type="text" className="form-control" name="glassCode" id="glassCode" value={this.state.glassCode} onChange={this.handleChange} />
                        </div>
                        <div className={`form-group col ${this.errorClass2(this.state.formNumberError)}`}>
                            <label htmlFor="formNumber">Form #</label>
                            <input type="text" className="form-control" name="formNumber" id="formNumber" value={this.state.formNumber} onChange={this.handleChange} />
                        </div>
                        <div className={`form-group col ${this.errorClass2(this.state.productCodeError)}`}>
                            <label htmlFor="productCode">Product Code</label>
                            <input type="text" className="form-control" name="productCode" id="productCode" value={this.state.productCode} onChange={this.handleChange} />
                        </div>
                        <div className={`form-group col ${this.errorClass2(this.state.controlNumberError)}`}>
                            <label htmlFor="controlNumber">Control #</label>
                            <input type="text" className="form-control" name="controlNumber" id="controlNumber" value={this.state.controlNumber} onChange={this.handleChange} />
                        </div>

                        {
                            this.state.batchHistoryId !== 0 &&
                            <div className="form-group col text-right bottomAlign">
                                <button className="btn btn-outline-info" onClick={() => this.duplicateForm()}>Copy Form</button>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col text-right text-danger">
                            {this.state.uniqueControlError}
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className="col-lg-12">
                            {
                                this.state.batchSheets.map((batchSheetrow, batchSheetIndex) => {
                                    return (
                                        <fieldset key={batchSheetIndex} className='border mt-4 mb-2'>
                                            <div className='h6 bg-secondary p-2 text-white' style={{ margin: "-15px -15px 15px -15px" }}>
                                                Batch Sheet
                                                {
                                                    batchSheetIndex > 0 &&
                                                    <button className="btn btn-sm float-right text-white" style={{ marginTop: "-4px" }} onClick={() => this.deleteBatchSheet(batchSheetIndex)}>{AccessibleIcon("fa-solid fa-trash-can", "")} Delete Batch Sheet</button>
                                                }
                                            </div>

                                            <fieldset className="border bgGreen">
                                                <legend className="h6">Section 1 – Hopper Weight</legend>
                                                {
                                                    batchSheetrow.hopperMaterial.map((row, index) => {
                                                        return (
                                                            <div key={index} className="form-row">
                                                                <div className={`form-group col-lg-5 ${this.errorClass2(row.materialError)}`}>
                                                                    <label>Raw Material</label>
                                                                    <select className="form-control" name="material" value={row.material} onChange={(e) => this.updateArrayInArrayOfObjects(e, "batchSheets", batchSheetIndex, "hopperMaterial", index)}>
                                                                        <option value="">Select...</option>
                                                                        {
                                                                            this.state.rawMaterialList.map((row, index) => {
                                                                                return (
                                                                                    <option key={index} value={row.productCode}>{row.productCode} ({row.productName})</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className={`form-group col-lg-6 ${this.errorClass2(row.lbsError)}`}>
                                                                    <label>#2 lbs</label>
                                                                    <input type="text" name="lbs" className="form-control"
                                                                        value={row.lbs}
                                                                        onChange={(e) => this.updateArrayInArrayOfObjects(e, "batchSheets", batchSheetIndex, "hopperMaterial", index)} />
                                                                </div>
                                                                <div className="form-group col-lg-1 bottomAlign">
                                                                    {
                                                                        index > 0 &&
                                                                        <button className="btn btn-sm btn-clear text-secondary" onClick={() => this.deleteMaterial("hopperMaterial", batchSheetIndex, index)}>{AccessibleIcon("fa-solid fa-trash-can", "Delete Raw Material Entry")}</button>
                                                                    }
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="form-row">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-clear p-0" onClick={() => this.addMaterial("hopperMaterial", batchSheetIndex)}>{AccessibleIcon('fa-solid fa-circle-plus mr-1', 'New')} More Row</button>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset className="border mt-4 bgPurple">
                                                <legend className="h6">Section 2 – Container Weight</legend>
                                                <div className="form-row mb-3">
                                                    <div className="col-lg-12">
                                                        <label htmlFor="noContainer">
                                                            <input type="checkbox" className="mr-2" name="noContainer" id="noContainer" value="Yes" checked={batchSheetrow.noContainer} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                            No Container
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    batchSheetrow.containerMaterial.map((row, index) => {
                                                        return (
                                                            <div key={index} className="form-row">
                                                                <div className={`form-group col-lg-5 ${this.errorClass2(row.materialError)}`}>
                                                                    <label>Raw Material</label>
                                                                    <select className="form-control" name="material" value={row.material} onChange={(e) => this.updateArrayInArrayOfObjects(e, "batchSheets", batchSheetIndex, "containerMaterial", index)} disabled={batchSheetrow.noContainer}>
                                                                        <option value="">Select...</option>
                                                                        {
                                                                            this.state.rawMaterialList.map((row, index) => {
                                                                                return (
                                                                                    <option key={index} value={row.productCode}>{row.productCode} ({row.productName})</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className={`form-group col-lg-6 ${this.errorClass2(row.lbsError)}`}>
                                                                    <label>#1 lbs</label>
                                                                    <input type="text" name="lbs" className="form-control" disabled={batchSheetrow.noContainer}
                                                                        value={row.lbs}
                                                                        onChange={(e) => this.updateArrayInArrayOfObjects(e, "batchSheets", batchSheetIndex, "containerMaterial", index)} />
                                                                </div>
                                                                <div className="form-group col-lg-1 bottomAlign">
                                                                    {
                                                                        index > 0 &&
                                                                        <button className="btn btn-sm btn-clear text-secondary" onClick={() => this.deleteMaterial("containerMaterial", batchSheetIndex, index)}>{AccessibleIcon("fa-solid fa-trash-can", "Delete Raw Material Entry")}</button>
                                                                    }
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="form-row">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-clear p-0" disabled={batchSheetrow.noContainer} onClick={() => this.addMaterial("containerMaterial", batchSheetIndex)}>{AccessibleIcon('fa-solid fa-circle-plus mr-1', 'New')} More Row</button>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset className="border mt-4 bgPurple">
                                                <legend className="h6">Section 3 – Small Melt Weight</legend>
                                                <div className="form-row mb-3">
                                                    <div className="col-lg-12">
                                                        <label htmlFor="noSmallMelt">
                                                            <input type="checkbox" className="mr-2" name="noSmallMelt" id="noSmallMelt" value="Yes" checked={batchSheetrow.noSmallMelt} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                            No Small Melt Weight
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    batchSheetrow.smallMeltMaterial.map((row, index) => {
                                                        return (
                                                            <div key={index} className="form-row">
                                                                <div className={`form-group col-lg-5 ${this.errorClass2(row.materialError)}`}>
                                                                    <label>Raw Material</label>
                                                                    <select className="form-control" name="material" value={row.material} onChange={(e) => this.updateArrayInArrayOfObjects(e, "batchSheets", batchSheetIndex, "smallMeltMaterial", index)} disabled={batchSheetrow.noSmallMelt}>
                                                                        <option value="">Select...</option>
                                                                        {
                                                                            this.state.rawMaterialList.map((row, index) => {
                                                                                return (
                                                                                    <option key={index} value={row.productCode}>{row.productCode} ({row.productName})</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className={`form-group col-lg-6 ${this.errorClass2(row.lbsError)}`}>
                                                                    <label>#1 lbs</label>
                                                                    <input type="text" name="lbs" className="form-control" disabled={batchSheetrow.noSmallMelt}
                                                                        value={row.lbs}
                                                                        onChange={(e) => this.updateArrayInArrayOfObjects(e, "batchSheets", batchSheetIndex, "smallMeltMaterial", index)} />
                                                                </div>
                                                                <div className="form-group col-lg-1 bottomAlign">
                                                                    {
                                                                        index > 0 &&
                                                                        <button className="btn btn-sm btn-clear text-secondary" onClick={() => this.deleteMaterial("smallMeltMaterial", batchSheetIndex, index)}>{AccessibleIcon("fa-solid fa-trash-can", "Delete Raw Material Entry")}</button>
                                                                    }
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="form-row">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-clear p-0" disabled={batchSheetrow.noSmallMelt} onClick={() => this.addMaterial("smallMeltMaterial", batchSheetIndex)}>{AccessibleIcon('fa-solid fa-circle-plus mr-1', 'New')} More Row</button>
                                                    </div>
                                                </div>

                                                <div className="form-row mt-3">
                                                    <div className={`form-group col ${this.errorClass2(batchSheetrow.smallMeltNotesError)}`}>
                                                        <label htmlFor="smallMeltNotes">Instructions</label>
                                                        <textarea name="smallMeltNotes" id="smallMeltNotes" className="form-control" disabled={batchSheetrow.noSmallMelt} value={batchSheetrow.smallMeltNotes} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)}></textarea>
                                                    </div>
                                                </div>


                                            </fieldset>

                                            <div className="row mt-3">
                                                <div className={`col-lg-3 ${this.errorClass2(batchSheetrow.verifyTotalBatchWeightError)}`}>
                                                    <label>Verify Total Batch Weight</label>
                                                    <input type="text" className="form-control" name="verifyTotalBatchWeight" value={batchSheetrow.verifyTotalBatchWeight} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                </div>
                                                <div className={`col-lg-3 offset-lg-6 bottomAlign`}>
                                                    <div className="input-group">
                                                        <div className="form-control qaField">
                                                            {showSignature(batchSheetrow.verifyTotalBatchWeightSignature)}
                                                        </div>
                                                        <div className="input-group-append">
                                                            <button className={`input-group-text ${this.errorClass2(batchSheetrow.verifyTotalBatchWeightSignatureError)}`} onClick={(e) => {
                                                                this.updateFieldinArrayOfObjects(null, "batchSheets", batchSheetIndex, "verifyTotalBatchWeightSignature", this.state.initials)
                                                                this.validForm(false)
                                                            }}>
                                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='text-danger'>{ }</div>
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                <div className="col">
                                                    <label htmlFor="halfBatch">
                                                        <input type="checkbox" className="mr-2" name="halfBatch" id="halfBatch" value="Yes" checked={batchSheetrow.halfBatch} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                        Check if this is a Half Batch
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className={`col-lg-3 ${this.errorClass2(batchSheetrow.targetPailWeight1Error)}`}>
                                                    <label>Target Pail Weight 1</label>
                                                    <input type="text" className="form-control" name="targetPailWeight1" value={batchSheetrow.targetPailWeight1} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                </div>
                                                <div className={`col-lg-3 ${this.errorClass2(batchSheetrow.targetPailWeight2Error)}`}>
                                                    <label>Target Pail Weight 2</label>
                                                    <input type="text" className="form-control" name="targetPailWeight2" value={batchSheetrow.targetPailWeight2} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                </div>
                                                <div className={`col-lg-3 ${this.errorClass2(batchSheetrow.targetPailWeight3Error)}`}>
                                                    <label>Target Pail Weight 3</label>
                                                    <input type="text" className="form-control" name="targetPailWeight3" value={batchSheetrow.targetPailWeight3} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                </div>
                                                <div className={`col-lg-3 ${this.errorClass2(batchSheetrow.targetPailWeight4Error)}`}>
                                                    <label>Target Pail Weight 4</label>
                                                    <input type="text" className="form-control" name="targetPailWeight4" value={batchSheetrow.targetPailWeight4} onChange={(e) => this.updateFieldinArrayOfObjects(e, "batchSheets", batchSheetIndex)} />
                                                </div>
                                            </div>

                                        </fieldset>
                                    )
                                })
                            }



                            {/* <div className="text-right mt-3">
                                <button className="btn btn-sm btn-secondary" onClick={this.addBatchSheet}>{AccessibleIcon('fa-solid fa-folder-plus mr-2', 'Add Batch Sheet')} Add Batch Sheet</button>
                            </div> */}

                            <fieldset className='border mt-5'>
                                <div className='h6 bgYellow p-2' style={{ margin: "-15px -15px 15px -15px" }}>Process Sheet 1</div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Furnace Inspection Sections</label>
                                        <fieldset className='border fieldset-enabled'>
                                            <div className="form-row">
                                                <div className="col-lg-4">
                                                    <label htmlFor="visible1">
                                                        <input type="checkbox" className="mr-2" name="visible" id="visible1" value="Yes" checked={this.state.processSheet.inspectionBeforeBatching.visible} onChange={(e) => this.handleChange(e, "processSheet", "inspectionBeforeBatching")} />
                                                        Before Batching
                                                    </label>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label htmlFor="visible2">
                                                        <input type="checkbox" className="mr-2" name="visible" id="visible2" value="Yes" checked={this.state.processSheet.inspectionAfterBatching.visible} onChange={(e) => this.handleChange(e, "processSheet", "inspectionAfterBatching")} />
                                                        After Batching
                                                    </label>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label htmlFor="visible3">
                                                        <input type="checkbox" className="mr-2" name="visible" id="visible3" value="Yes" checked={this.state.processSheet.inspectionAfterMelting.visible} onChange={(e) => this.handleChange(e, "processSheet", "inspectionAfterMelting")} />
                                                        After Melting
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <label className='mt-4'>Instructions</label>
                                        <fieldset className="border">
                                            <div className="form-row">
                                                <div className={`col-lg-4 ${this.errorClass2(this.state.processSheet.batchingError)}`}>
                                                    <label className="mt-0" htmlFor="batching">Batching</label>
                                                    <input type="text" className="form-control" name="batching" id="batching" value={this.state.processSheet.batching} onChange={(e) => this.handleChange(e, 'processSheet')} />
                                                </div>
                                                <div className={`col-lg-4 ${this.errorClass2(this.state.processSheet.meltingError)}`}>
                                                    <label className="mt-0" htmlFor="melting">Melting</label>
                                                    <input type="text" className="form-control" name="melting" id="melting" value={this.state.processSheet.melting} onChange={(e) => this.handleChange(e, 'processSheet')} />
                                                </div>
                                                <div className={`col-lg-4 ${this.errorClass2(this.state.processSheet.drainingError)}`}>
                                                    <label className="mt-0" htmlFor="draining">Draining</label>
                                                    <input type="text" className="form-control" name="draining" id="draining" value={this.state.processSheet.draining} onChange={(e) => this.handleChange(e, 'processSheet')} />
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="notes">Notes</label>
                                        <textarea name="notes" id="notes" rows={3} className="form-control" value={this.state.processSheet.notes} onChange={(e) => this.handleChange(e, "processSheet")}></textarea>
                                    </div>
                                </div>

                            </fieldset>

                            <fieldset className='border mt-5'>
                                <div className='h6 bgYellow p-2' style={{ margin: "-15px -15px 15px -15px" }}>Process Sheet 2</div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Furnace Inspection Sections</label>
                                        <fieldset className='border fieldset-enabled'>
                                            <div className="form-row">
                                                <div className="col-lg-4">
                                                    <label htmlFor="visible12">
                                                        <input type="checkbox" className="mr-2" name="visible" id="visible12" value="Yes" checked={this.state.processSheet2.inspectionBeforeBatching.visible} onChange={(e) => this.handleChange(e, "processSheet2", "inspectionBeforeBatching")} />
                                                        Before Batching
                                                    </label>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label htmlFor="visible22">
                                                        <input type="checkbox" className="mr-2" name="visible" id="visible22" value="Yes" checked={this.state.processSheet2.inspectionAfterBatching.visible} onChange={(e) => this.handleChange(e, "processSheet2", "inspectionAfterBatching")} />
                                                        After Batching
                                                    </label>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label htmlFor="visible32">
                                                        <input type="checkbox" className="mr-2" name="visible" id="visible32" value="Yes" checked={this.state.processSheet2.inspectionAfterMelting.visible} onChange={(e) => this.handleChange(e, "processSheet2", "inspectionAfterMelting")} />
                                                        After Melting
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <label className='mt-4'>Instructions</label>
                                        <fieldset className="border">
                                            <div className="form-row">
                                                <div className={`col-lg-4 ${this.errorClass2(this.state.processSheet2.batchingError)}`}>
                                                    <label className="mt-0" htmlFor="batching">Batching</label>
                                                    <input type="text" className="form-control" name="batching" id="batching" value={this.state.processSheet2.batching} onChange={(e) => this.handleChange(e, 'processSheet2')} />
                                                </div>
                                                <div className={`col-lg-4 ${this.errorClass2(this.state.processSheet2.meltingError)}`}>
                                                    <label className="mt-0" htmlFor="melting">Melting</label>
                                                    <input type="text" className="form-control" name="melting" id="melting" value={this.state.processSheet2.melting} onChange={(e) => this.handleChange(e, 'processSheet2')} />
                                                </div>
                                                <div className={`col-lg-4 ${this.errorClass2(this.state.processSheet2.drainingError)}`}>
                                                    <label className="mt-0" htmlFor="draining">Draining</label>
                                                    <input type="text" className="form-control" name="draining" id="draining" value={this.state.processSheet2.draining} onChange={(e) => this.handleChange(e, 'processSheet2')} />
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="notes">Notes</label>
                                        <textarea name="notes" id="notes" rows={3} className="form-control" value={this.state.processSheet2.notes} onChange={(e) => this.handleChange(e, "processSheet2")}></textarea>
                                    </div>
                                </div>

                            </fieldset>

                            <div className="mt-4">
                                {
                                    this.state.status === "Setup" &&
                                    <button className="btn btn-success mr-2" disabled={!this.state.validForm} onClick={() => this.saveForm(true)}>Submit to Pre-Production</button>
                                }
                                {
                                    this.state.status === "Setup" &&
                                    <button className="btn btn-warning mr-2" onClick={() => this.saveForm(false)}>Save for Later</button>
                                }
                                {
                                    this.state.status !== "Setup" &&
                                    <button className="btn btn-success mr-2" onClick={() => this.saveForm(false)}>Update</button>
                                }
                                <button className="btn btn-clear text-danger" onClick={this.props.toggleModal}>{this.state.batchHistoryId !== 0 ? "Close" : "Cancel"}</button>
                            </div>

                            <div className="row mt-5 text-right">
                                <div className="col-lg-12">
                                    <button className="btn btn-sm btn-clear text-debug mr-2" onClick={() => console.log(this.state)}>Debug: Show State</button>
                                    <button className="btn btn-sm btn-clear text-debug mr-2" onClick={() => console.log(this.state.batchSheets)}>Debug: Show State batchSheets</button>
                                    <button className="btn btn-sm btn-clear text-debug mr-2" onClick={() => console.log(BATCHSHEET)}>Debug: Show BATCHSHEET</button>
                                    <button className="btn btn-sm btn-clear text-debug mr-2" onClick={() => this.validForm()}>Debug: Validate Form</button>
                                    <button className="btn btn-sm btn-clear text-debug mr-2" onClick={() => this.getForm()}>Debug: GetForm</button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main >
        )
    }
}