import React from 'react'
import { AccessibleIcon } from '../shared/AccessibleIcon'
import { showSignature } from '../shared/SharedFunctions'

export default class QASheet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    errorClass(objName, fieldName) {
        if (objName !== "") {
            if (this.props[objName][fieldName + "Error"] !== undefined) {
                return (this.props[objName][fieldName + "Error"] === 0 ? '' : 'has-error');
            }
        } else {
            if (this.props[fieldName + "Error"] !== undefined) {
                return (this.props[fieldName + "Error"] === 0 ? '' : 'has-error');
            }
        }
    }

    errorClass2(value) {
        return (value === "" || value === undefined ? '' : 'has-error')
    }

    render() {
        return (
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-lg-10">
                        <h3 className='pgHeader'>Q/A Sheet</h3>
                    </div>
                </div>
                <div className="row">
                    <div className={`col-lg-4 text-center`} style={{ margin: "0 auto" }}>
                        Lot # <span className="form-control productionField">{this.props.lotNumber}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        *** To verify property requirements refer to Product Code Specifications ***
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col text-center">
                        <h6 className='mb-0'>Process</h6>
                        Thermal Expansion, Index of Refraction, Fiber Softening Point
                    </div>
                </div>

                <div className="row mt-4 mb-4">
                    <div className="col">
                        <fieldset className="border">
                            <legend className='h5'>Thermal Expansion</legend>
                            <div className="row my-3">
                                <div className="col-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.thermalExpansion1Error)}`}>1</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="thermalExpansion1" id="thermalExpansion1" value={this.props.qaSheet.thermalExpansion1} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.thermalExpansion2Error)}`}>2</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="thermalExpansion2" id="thermalExpansion2" value={this.props.qaSheet.thermalExpansion2} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.thermalExpansion3Error)}`}>3</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="thermalExpansion3" id="thermalExpansion3" value={this.props.qaSheet.thermalExpansion3} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.thermalExpansionAvgError)}`}>Average</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="thermalExpansionAvg" id="thermalExpansionAvg" value={this.props.qaSheet.thermalExpansionAvg} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group">
                                        <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                            {showSignature(this.props.qaSheet.thermalExpansionSignature)}
                                        </div>
                                        <div className="input-group-append">
                                            <button className={`input-group-text ${this.errorClass2(this.props.qaSheet.thermalExpansionSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign(this.props.formContent, 'thermalExpansionSignature')}>
                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>

                    </div>
                </div>


                <div className="row mt-4 mb-4">
                    <div className="col">
                        <fieldset className="border">
                            <legend className='h5'>Index of Refraction</legend>

                            <div className="row">
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.indexRefraction1Error)}`}>1</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="indexRefraction1" id="indexRefraction1" value={this.props.qaSheet.indexRefraction1} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.indexRefraction2Error)}`}>2</span>
                                        </div>
                                        <input type="text" className="form-control qaField" name="indexRefraction2" id="indexRefraction2" value={this.props.qaSheet.indexRefraction2} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group">
                                        <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                            {showSignature(this.props.qaSheet.indexRefractionSignature)}
                                        </div>
                                        <div className="input-group-append">
                                            <button className={`input-group-text ${this.errorClass2(this.props.qaSheet.indexRefractionSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign(this.props.formContent, 'indexRefractionSignature')}>
                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-danger'>{this.props.qaSheet.indexRefractionSignatureErr}</div>
                                </div>
                            </div>
                        </fieldset>

                    </div>
                </div>



                <div className="row mt-4 mb-4">
                    <div className="col">
                        <fieldset className="border">
                            <legend className='h5'>Fiber Softening Point (QUA-001)</legend>
                            <div className="row">
                                <div className="col">
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="fiberSoftening1" id="fiberSoftening1" value={this.props.qaSheet.fiberSoftening1} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                        <div className="input-group-append">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.fiberSoftening1Error)}`}>Average</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col ${this.errorClass("qaSheet", "fiberSoftening2")}`}>
                                    <div className="input-group">
                                        <input type="text" className="form-control qaField" name="fiberSoftening2" id="fiberSoftening2" value={this.props.qaSheet.fiberSoftening2} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)} />
                                        <div className="input-group-append">
                                            <span className={`input-group-text ${this.errorClass2(this.props.qaSheet.fiberSoftening2Error)}`}>Average</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group">
                                        <div className="form-control qaField" disabled={this.props.disabledFields.qaField}>
                                            {showSignature(this.props.qaSheet.fiberSofteningSignature)}
                                        </div>
                                        <div className="input-group-append">
                                            <button className={`input-group-text ${this.errorClass2(this.props.qaSheet.fiberSofteningSignatureError)}`} disabled={this.props.disabledFields.qaField} onClick={() => this.props.sign(this.props.formContent, 'fiberSofteningSignature')}>
                                                {AccessibleIcon('fas fa-signature', 'Intial')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div className="row mt-4 mb-4">
                    <div className="col">
                        <label htmlFor="">Notes</label>
                        <textarea name="notes" id="notes" rows={7} className="form-control qaField" value={this.props.qaSheet.notes} disabled={this.props.disabledFields.qaField} onChange={(e) => this.props.handleChange(e, this.props.formContent)}></textarea>
                    </div>
                </div>

                <div className="fullBleed bg-light border mt-5">
                    <div className="btn border mr-3 batchField">Batch Room</div>
                    <div className="btn border mr-3 productionField">Production</div>
                    <div className="btn border mr-3 qaField">QA</div>
                    <div className="btn border mr-3 sortingField">Sorting</div>
                    <div className="btn border mr-3 inspectionField">Inspection</div>
                    <div className="btn border mr-3 gpField">GP</div>
                    <div className="btn border mr-3 grindingField">Grind Room</div>
                </div>

            </div> // container-fluid
        )
    }
}